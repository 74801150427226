import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonPage,
  IonPopover,
  IonRow,
  IonText,
} from "@ionic/react";
import Header from "../header";
import "./style.css";
import { chevronBack, chevronBackCircleSharp } from "ionicons/icons";
import { icons } from "../../assets";
import { useState } from "react";
import { brotliDecompress } from "zlib";
import { emmaVideos } from "../../assets/emmaVideos/emma";
import ReactPlayer from "react-player";

interface globaGridProps {
  currentPageTitle: string;
  closeCurrentPage?: () => void;
  activateVoice?: () => void;
  startListeningFilterSearch?: () => void;
  closeVideo?: () => void;
  videoSource?: string;
}

const GlobalGrid: React.FC<globaGridProps> = ({
  children,
  currentPageTitle,
  closeCurrentPage,
  activateVoice,
  closeVideo,
  startListeningFilterSearch,
  videoSource,
}) => {
  /*START OF VARIABLES */
  const [showEmma, setShowEmma] = useState(false);
  const [videoUrl, setVideoUrl] = useState(emmaVideos.service);

  /*START OF FUNCTIONS */
  const handleSpeakerPress = (nameOfActivePage: String) => {
    setVideoUrl(emmaVideos.service);
    setShowEmma(true);
  };
  const handleBackPress = () => {
    closeCurrentPage?.();
  };

  return (
    <>
      <Header></Header>
      <div className="globalContentContainer">
        <IonGrid id="mainGrid">
          <IonRow className="globalContentCard">
            {currentPageTitle === "MODEL SEARCH" ? (
              <>
                <IonCol
                  sizeXs="11"
                  sizeMd="11"
                  sizeLg="11"
                  sizeXl="11"
                  className=" gridPageTitle"
                >
                  {currentPageTitle}
                </IonCol>
                <IonCol
                  id="emmaSpeakerContainer"
                  size="1"
                  onClick={() => handleSpeakerPress(currentPageTitle)}
                >
                  <IonImg className="gridEmmaSpeaker" src={icons.blueSpeaker} />

                  {showEmma ? (
                    <div
                      className="emmaVideoContainer"
                      onClick={() => setShowEmma(false)}
                    >
                      <ReactPlayer
                        url={
                          "https://youtube.com/shorts/smZOTHbkEXs?si=t8j-s3YkxWeLevJr"
                        }
                        playing // Autoplay equivalent
                        onEnded={() => setShowEmma(false)}
                        onClick={() => setShowEmma(false)}
                        className="VideoFrame"
                        width="100%" // For responsiveness
                        height="100%"
                        controls={false}
                      />
                    </div>
                  ) : null}
                </IonCol>
              </>
            ) : (
              <>
                <IonCol
                  size="1"
                  className="gridGoBackSection "
                  onClick={() => {
                    handleBackPress();
                  }}
                >
                  <IonIcon icon={chevronBack}></IonIcon>
                </IonCol>
                <IonCol
                  sizeXs="10"
                  sizeMd="10"
                  sizeLg="10"
                  sizeXl="10"
                  className=" gridPageTitle"
                >
                  {currentPageTitle}
                </IonCol>
                <IonCol
                  size="1"
                  onClick={() => handleSpeakerPress(currentPageTitle)}
                >
                  {currentPageTitle !== "VISITORS PARKING" &&
                    currentPageTitle !== "CONTACT US" &&
                    currentPageTitle !== "APP TUTORIAL" && (
                      <IonImg
                        className="gridEmmaSpeaker"
                        src={icons.blueSpeaker}
                      />
                    )}
                  {showEmma ? (
                    <div
                      className="emmaVideoContainer"
                      onClick={() => setShowEmma(false)}
                    >
                      <ReactPlayer
                        url={
                          currentPageTitle === "MY GARAGE"
                            ? "https://youtube.com/shorts/wwkL7kGUehI?si=o_FNfysB3PyXvE1r&autoplay=1"
                            : currentPageTitle === "SERVICE COST"
                            ? "https://youtu.be/GQUZ6cO1re0?si=S68nTKkltap9SLjs&autoplay=1"
                            : currentPageTitle === "FILTERED SEARCH"
                            ? "https://youtube.com/shorts/X15XA2jq7tY?si=rGZ9hyjaflGz3Lby"
                            : currentPageTitle === "SEARCH RESULTS"
                            ? "https://youtube.com/shorts/w8VgfZyb0RM?si=0aDPDLp7T98hzxFI"
                            : currentPageTitle === "MODEL SEARCH"
                            ? "https://youtube.com/shorts/smZOTHbkEXs?si=t8j-s3YkxWeLevJr&autoplay=1"
                            : currentPageTitle === "10K RATES TABLE"
                            ? "https://youtube.com/shorts/RwowbBYj5qI?si=Lle7A38zYZ-jGQmg"
                            : currentPageTitle === "VIN SCAN"
                            ? "https://youtube.com/shorts/mT7QVdc8d80?si=7l2A0uGGb5tGleys"
                            : currentPageTitle === "MAINTENANCE COST"
                            ? "https://youtu.be/5XJ2r68ebwQ?si=cAyG_vd2yXWXU7Sn"
                            : currentPageTitle === "LOGIN"
                            ? "https://youtu.be/96TPEg0CACE?si=dVoZmv7Gg0SuEeDt"
                            : ""
                        }
                        playing
                        onEnded={() => setShowEmma(false)}
                        onClick={() => setShowEmma(false)}
                        className="VideoFrame"
                        width="100%"
                        height="100%"
                        controls={false}
                      />
                    </div>
                  ) : null}
                </IonCol>
              </>
            )}
          </IonRow>
          {currentPageTitle === "MODEL SEARCH" ? (
            <IonRow>
              <IonCol
                sizeXs="8"
                sizeMd="12"
                sizeLg="12"
                sizeXl="12"
                className="gridWelcomeText"
              >
                We're excited to have you on board. Upon logging into our app,
                you enter a realm of innovative solutions and invaluable
                insights for your quest in finding your dream car. Whether
                you're browsing available options, studying costs, or exploring
                the many features of our software solutions, we're here to
                assist you every step of the way.
              </IonCol>
            </IonRow>
          ) : currentPageTitle === "FILTERED SEARCH" ? (
            // <IonRow className="gridFilterVoiceRow">
            //   <IonCol
            //     id="cards"
            //     size="12"
            //     sizeXs="12"
            //     sizeMd="12"
            //     sizeLg="10"
            //     sizeXl="12"
            //   >
            //     <div className="box">
            //       <div className="innerBox">
            //         <div className="top">
            //           <img
            //             src={icons.voiceRec}
            //             className="img"
            //             alt="Camera Scan"
            //             onClick={activateVoice}
            //           />
            //         </div>
            //         <IonText className="bottom">
            //           Our mobile app includes a fantastic voice recognition
            //           feature. All you need to do is say the name of the vehicle
            //           you're searching for, and voilà! The filter options will
            //           be automatically selected for you. It's that easy! Happy
            //           browsing!
            //         </IonText>
            //       </div>
            //     </div>
            //   </IonCol>
            // </IonRow>
            <>
             {/* <img
                        src={icons.voiceRec}
                        className="img"
                        alt="Camera Scan"
                        onClick={activateVoice}
                      /> */}
            </>
          ) : null}
          <IonGrid className="globalContentHolder">{children}</IonGrid>
        </IonGrid>
      </div>
    </>
  );
};

export default GlobalGrid;
