import { IonCol, IonContent, IonIcon, IonPage, IonRow } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";
import "./index.css";
import { RootState } from "../../redux/store";
import { setScreen } from "../../redux/ui/uiSlice";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useHistory } from "react-router-dom";
import { icons } from "../../assets";
import { chevronBackCircleSharp } from "ionicons/icons";
import { images } from "../../assets";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

const Contact: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();

  // const fixedText = `Hey there!\n\n
  // Are you dreaming of purchasing the perfect vehicle? Check out MAPO's awesome mobile app at https://mobile.mapo-int.com. \n\n
  // My advice to you... Stay informed, plan effectively, and approach your next vehicle purchase with confidence. This app is packed with amazing features. I easily researched the vehicle that perfectly matched my needs. Effortlessly access precise and up-to-date service costs, maintenance expenses, total cost of ownership, residual values, and even parts pricing — all conveniently from my phone!\n\n
  // Also, have a look at this YouTube video that shows some of the amazing features included in the app: https://youtu.be/k7wCkS5C7FQ.\n\n
  // Your dream car is closer than you think. Get the MAPO mobile app now.\n\n
  // Cheers`;
  // const [textToShare, setTextToShare] = useState<string>(fixedText);

  const onBackPress = useCallback(() => {
    if (uiState.fromSearch) {
      //dispatch(setScreen(AppScreens.SearchResult));
      history.push("/home");
    } else {
      // dispatch(setScreen(AppScreens.Home));
      history.push("/home");
    }
  }, [dispatch, uiState]);

  const [MailSentShow, setMailSentShow] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const EmailHandleClose = () => setMailSentShow(false);
  const EmailHandleShow = () => setMailSentShow(true);

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    message: "",
    number: "",
  });

  function handleStateChange(e: { target: { name: any; value: any } }) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    sessionStorage.setItem("UserEmail", mailerState.email);
  }

  function handleReset() {
    setMailerState({
      name: "",
      email: "",
      message: "",
      number: "",
    });
  }

  const submitEmail = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // Verify reCAPTCHA response here before sending the email
    // const recaptchaResponse = (window as any).grecaptcha.getResponse();
    // if (!recaptchaResponse) {
    //   // reCAPTCHA not verified, display an error message or prevent submission
    //   return;
    // }

    let data = JSON.stringify({
      sender: {
        email: mailerState.email,
      },
      to: [{ email: "admin@mapo-int.com" }],
      replyTo: { email: "admin@mapo-int.com" },
      textContent:
        "Name of the Sender  " +
        mailerState.name +
        "      " +
        mailerState.message +
        " The User Contact number " +
        mailerState.number +
        " The User Email Address: " +
        mailerState.email,
      subject: mailerState.name + " From MAPO APP",
    });

    const response = await fetch("https://api.brevo.com/v3/smtp/email", {
      method: "post",
      headers: {
        "api-key":
          "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",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((response) =>
        response.json().then((res) => ({ status: response.status, data: res }))
      )
      .then((apiResponse) => {
        if (apiResponse.status === 201) {
          EmailHandleShow();
        } else if (apiResponse.status === 400) {
          handleShow();
        }
      })
      .catch((error) => {
        console.error("Error: 2", error);
      })
      .then(() => {});
  };

  const fixedText: string = uiState.selectedVehicle
    ? `Good day MAPO Team.\n\n 
  *My Question:*`
    : "";
  const [textToShare, setTextToShare] = useState<string>(fixedText);

  const handleShareClick = () => {
    const encodedText = encodeURIComponent(textToShare);
    const whatsappURL = `https://wa.me/+27646826775?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <>
      
        <GlobalGrid
          currentPageTitle="CONTACT US"
          closeCurrentPage={() => onBackPress()}
        >
          <div className="EmailContainer">
            <img src={icons.newMail} className="EmailImage" />
            <span className="MailTextPosition">
              {" "}
              <p className="VIAEMAIL">VIA EMAIL</p>
            </span>
          </div>

          <form action="" onSubmit={submitEmail} className="ContactForm">
            <label className="InputLabel">Name</label>

            <input
              type="text"
              onChange={handleStateChange}
              name="name"
              className="ContactInput"
              value={mailerState.name}
              placeholder=""
              required
            />

            <label className="InputLabel">Email Address</label>

            <input
              type="text"
              onChange={handleStateChange}
              name="email"
              className="ContactInput"
              value={mailerState.email}
              placeholder=""
              required
            />

            <label className="InputLabel">Contact Number</label>

            <input
              type="number"
              onChange={handleStateChange}
              className="ContactInput"
              name="number"
              value={mailerState.number}
              placeholder=""
            />
            <label className="InputLabel">Message</label>

            <textarea
              onChange={handleStateChange}
              name="message"
              className="ContactInputMessage"
              value={mailerState.message}
              placeholder=""
              style={{ height: "170px" }}
            ></textarea>

            <br />
            <br />
            {/* <div
              className="g-recaptcha"
              data-sitekey="6LdDra4pAAAAAKcPjDhFo9iQguKrb6VC492XFbHZ"
            ></div> */}

            <br />
            <section className="CantactUsTwoBTN">
              <button onClick={handleReset}>RESET</button>
              <button type="submit" value="Submit">
                SUBMIT
              </button>
            </section>
          </form>
          <br />
          <br />

          <div className="EmailContainer">
            <img src={icons.mnewWhatsApp} className="EmailImage" />
            <span className="MailTextPosition">
              {" "}
              <p className="VIAEMAIL">VIA WHATSAPP</p>
            </span>

            <p className="txtOnClickWhatsApp">
              Click on the icon below and contact out MAPO Team Via <br />{" "}
              WhatsApp.
            </p>

            <span className="WhatsAppBtnImg">
              <img onClick={handleShareClick} src={icons.mnewWhatsApp} />
            </span>
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <span className="ModalMessage">Email Not sent</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="ModalMessage">
              Make sure the Email is correct:{" "}
              <b>{sessionStorage.getItem("UserEmail")} </b>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                <span className="ModalMessage">OK</span>
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={MailSentShow} onHide={EmailHandleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                <span className="ModalMessage">Email sent</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="ModalMessage">Email sent</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={EmailHandleClose}>
                <span className="ModalMessage">OK</span>
              </Button>
            </Modal.Footer>
          </Modal>
        </GlobalGrid>
      
    </>
  );
};

export default Contact;
