import { IonCol, IonHeader, IonModal, IonRow } from "@ionic/react";
import "./style.css";
import "../../Global.css";
import { I } from "scandit-web-datacapture-core/build/js/Camera-ugAOEuHY";
import { useCallback, useState } from "react";
import { sessionLogout } from "../../redux/session/sessionSlice";
import {
  hideLoader,
  setFilteredSearchVisible,
  setUndentifiedVinSearchVisible,
  showLoader,
} from "../../redux/ui/uiSlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../redux/store";

interface AccountRemoveProps {
  isVisible: boolean;
  onClosed: () => void;
}

const AccountRemove: React.FC<AccountRemoveProps> = ({
  isVisible,
  onClosed,
}: AccountRemoveProps) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [DeleteAccRespons, setDeleteAccountRes] = useState("");

  const onLogout = useCallback(() => {
    dispatch(sessionLogout());
    localStorage.removeItem("barcodeResults");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/login");
    }, 500);
  }, [dispatch]);

  const handleSubmit = async (e: any) => {
    dispatch(showLoader());
    try {
      const response = await fetch(
        `https://za-api.mapo-int.com/api/UserData/DeleteUserData?username=${sessionState.user?.username}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setDeleteAccountRes(
          responseData.message || "Account removed successfully."
        );
      } else {
        const errorData = await response.json();
        setDeleteAccountRes(errorData.message || "Failed to remove account.");
      }
    } catch (error) {
      // Handle network errors
    } finally {
      setTimeout(() => {
        dispatch(hideLoader());
        onLogout();
        onClosed();
      }, 5000);
    }
  };
  return (
    <>
      <IonModal
        className="ion-modal-remove-acc"
        onDidDismiss={onClosed}
        isOpen={isVisible}
      >
        <IonRow className="ion-justify-content-center ion-align-items-center">
          <p className="remove-header-txt">Remove Account</p>
        </IonRow>

        <IonRow className="ion-justify-content-center ion-align-items-center">
          <p className="txt-remove-acc">
            Are you sure you want to remove your account? This action cannot be
            undone.
          </p>
        </IonRow>

        <IonRow>
          <IonCol
            size="12"
            sizeXs="12"
            sizeMd="12"
            sizeLg="12"
            sizeXl="12"
            className="gridFilterBtnCont"
          >
            <button
              className="gridFilterBtns"
              style={{ background: "green" }}
              onClick={onClosed}
            >
              CANCEL
            </button>

            <button
              style={{ background: "#b61010" }}
              className="gridFilterBtns "
              onClick={handleSubmit}
            >
              YES, REMOVE
            </button>
          </IonCol>
        </IonRow>
        <div
          style={{
            color: "green",
            fontSize: "35px",
            textAlign: "center",
          }}
        >
          <p>{DeleteAccRespons}</p>
        </div>
        <div
          style={{
            color: "black",
            fontSize: "35px",
            textAlign: "center",
          }}
        >
          <small>
            <b>**Please note:**</b> <br /> By submitting this request,we will
            permanently delete your username and password from our database.
            This action cannot be undone. We retain your data for a maximum of
            30 days before permanent deletion.
          </small>
        </div>
        <br />
      </IonModal>
    </>
  );
};

export default AccountRemove;
