import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";

import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addVehicle, getImage, manualSearch } from "../../api/mapo";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import Header from "../../components/header";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "./style.css";
import { RootState } from "../../redux/store";
import {
  hideLoader,
  hideSideMenuDisabledItems,
  setCompetitors,
  setCustomReportParams,
  setFilteredSearchVisible,
  setScreen,
  setSelectedVehicle,
  setUndentifiedVinSearchVisible,
  showLoader,
  showSideMenuDisabledItems,
} from "../../redux/ui/uiSlice";
import { decVal, format } from "../../utils";

import { CompetitorData } from "../../interfaces/competitorData";
import { VehicleCustomReportParams } from "../../interfaces/customReportParams";
import { useHistory } from "react-router-dom";
import { eyeSharp, thumbsUpSharp } from "ionicons/icons";
import { icons } from "../../assets";
import { Competitor } from "../../interfaces/competitors";
import {
  getAllSelectedData,
  getSelectedSpecificModelData,
} from "../../api/getAll";
import { SelectedVehicle, Vehicle } from "../../interfaces";
import { useMediaQuery } from "react-responsive";
import { sessionLogout } from "../../redux/session/sessionSlice";

interface CompViewProps {
  competitor: CompetitorData;
  compIndex: Number;
  Addtocart: () => void;
}

const CompetitorView: React.FC<CompViewProps> = ({
  competitor,
  compIndex,
  Addtocart,
}: CompViewProps) => {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableView, setTableView] = useState(true);
  const [CartClicked, setIsClicked] = useState(false);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [addCartAlert, setAddCartAlert] = useState(false);
  const uiState = useSelector((state: RootState) => state.ui);
  const [cartItems, setCartItems] = useState([]);
  const [duplicateItemIndex, setDuplicateItemIndex] = useState<number | null>(
    null
  );
  // useEffect(() => {
  //   const getCartFromStorage = () => {
  //     try {
  //       const storedCart = localStorage.getItem("cart");
  //       if (storedCart) {
  //         setCartItems(JSON.parse(storedCart));
  //       }
  //     } catch (error) {
  //       console.error("Error parsing cart from localStorage:", error);
  //     }
  //   };

  //   getCartFromStorage();
  // }, [cartItems]);

  const addToCart = (competitor: any) => {
    const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");

    // Check if 'modelClass.result' exists and has at least one element
    if (
      competitor.modelClass &&
      competitor.modelClass.result &&
      competitor.modelClass.result.length > 0
    ) {
      // Extract specific details from the competitor object
      const {
        modelClass,
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      } = competitor.modelClass.result[0];

      // Check if the competitor is already in the cart
      const isInCart = cartItems.some(
        (item: any) => item.modelCode === modelCode
      );

      if (!isInCart) {
        // Add the competitor details to the cart
        cartItems.push({
          modelClass,
          make,
          modelCode,
          groupCode,
          variantCode,
          introDate,
          mapoCode,
          modelClassShortDescription,
          variant,
          variantId,
          status,
          model,
        });

        // Update localStorage with the new cart items
        localStorage.setItem("cart", JSON.stringify(cartItems));

        // The console
        //console.log("Cart Data:", cartItems);

        //alert("Item added to cart!");
        setIsClicked(true);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ADDED TO CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      } else {
        setDuplicateItemIndex(competitor);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ALREADY IN CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      }
    } else {
      console.error("Error: No result found in competitor.modelClass object.");
    }
  };

  //The func to extract the competitors details
  function extractDetailsFromCompetitor(competitor: any) {
    if (
      competitor.modelClass &&
      competitor.modelClass.result &&
      competitor.modelClass.result.length > 0
    ) {
      // Extract specific details from the competitor object
      const {
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      } = competitor.modelClass.result[0];

      return {
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      };
    } else {
      // Return a default value or handle the case where the required data is not available
      return null;
    }
  }

  // Example usage:
  const competitorDetails = extractDetailsFromCompetitor(competitor);

  // Check if details are available before using them
  if (competitorDetails) {
  } else {
    // Handle the case where the required data is not available
    console.error("Competitor details not available");
  }

  const selectPrimaryVehicle = useCallback(() => {
    const competitorDetails = extractDetailsFromCompetitor(competitor);

    if (competitorDetails) {
      const selectedVehicle: SelectedVehicle = {
        make: competitorDetails.make,
        model: competitorDetails.model,
        variant: competitorDetails.variant,
        g_id: competitorDetails.groupCode,
        m_id: competitorDetails.modelCode,
        v_id: competitorDetails.variantCode,
        // variant_id: v.oem_code,
      };
      const gmvCode =
        "G" +
        selectedVehicle.g_id +
        "M" +
        selectedVehicle.m_id +
        "V" +
        selectedVehicle.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getSelectedSpecificModelData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                dispatch(setCompetitors(allData.competition ?? []));
                dispatch(hideLoader());
                sessionState.user?.username !== "GUEST" ? ( // If user is not a guest
                  history.push("/VehicleDetail")
                ) : (
                  // If user is a guest
                  history.push("/OverViewDetail")
                );
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert("API Error 1: " + JSON.stringify(err));
              });
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          //alert("API Error 2: " + JSON.stringify(err));
        });
    }
  }, []);

  const selectPrimaryVehic = useCallback(() => {
    const competitorDetails = extractDetailsFromCompetitor(competitor);

    if (competitorDetails) {
      const selectedVehicle: SelectedVehicle = {
        make: competitorDetails.make,
        model: competitorDetails.model,
        variant: competitorDetails.variant,
        g_id: competitorDetails.groupCode,
        m_id: competitorDetails.modelCode,
        v_id: competitorDetails.variantCode,
        // variant_id: v.oem_code,
      };
      const gmvCode =
        "G" +
        selectedVehicle.g_id +
        "M" +
        selectedVehicle.m_id +
        "V" +
        selectedVehicle.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            dispatch(setSelectedVehicle(selectedVehicle));
            dispatch(showLoader());

            getAllSelectedData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0,
              uiState.customReportParams.deposit ?? 0,
              uiState.customReportParams.interest ?? 0,
              uiState.customReportParams.term ?? 0,
              uiState.customReportParams.residual ?? 0
            ).then((allData) => {
              dispatch(
                setSelectedVehicle({
                  ...searchResults[0],
                  make: searchResults[0].g_desc,
                  model: searchResults[0].m_desc,
                  data: allData.selected,
                  vehicleData: searchResults[0],
                })
              );
              const selectedVehicleCustomParams: VehicleCustomReportParams = {
                retailprice: decVal(
                  allData.selected?.modelSpecification.result?.basic_specs
                    ?.base_price
                ),
                co2tax: Number(
                  allData.selected?.modelSpecification.result?.engine_specs
                    .co2 ?? ""
                ),

                // Service
                servicestandardkm: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.service_plan ?? "0"
                ),
                servicestandardmnt: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.service_plan_months ?? "0"
                ),

                // Maintenance
                maintenancestandardkm: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.maintenance_plan ?? "0"
                ),
                maintenancestandardmnt: Number(
                  allData.selected?.modelSpecification.result
                    ?.service_maintenance_warranty.maintenance_plan_months ??
                    "0"
                ),

                // baloonpayment: 0,
              };
              dispatch(showSideMenuDisabledItems());
              dispatch(
                setCustomReportParams({
                  ...uiState.customReportParams,
                  selectedVehicleCustomParams: selectedVehicleCustomParams,
                  //competitorCustomParams: competitorCustomParams,
                })
              );

              // setTimeout(() => {
              //   //dispatch(setScreen(AppScreens.VehicleLanding));
              //   history.push("/VehicleDetail");
              // }, 500);
            });
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          //alert("API Error 2: " + JSON.stringify(err));
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error: " + JSON.stringify(err));
        });
    } else {
      // Handle the case where the required data is not available
      console.error("Competitor details not available");
    }
    dispatch(hideSideMenuDisabledItems());
  }, [dispatch, competitor, uiState, uiState.customReportParams]);


  const [condOne, setCondOne] = useState(
    competitor.competitorBase?.price_match_percentage.toFixed(2)
  );
  const [orangeClass, setOrangeClass] = useState("orangeClass");
  const [redClass, setRedClass] = useState("redClass");
  const classArray = [" ", orangeClass, redClass];

  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);

  const getFiveDaysAgoDate = (): string => {
    const today = new Date();
    const fiveDaysAgo = new Date(today);
    fiveDaysAgo.setDate(today.getDate() - 5);

    // Ensure time is set to 00:00:00
    fiveDaysAgo.setHours(0, 0, 0, 0);

    const year = fiveDaysAgo.getFullYear();
    const month = String(fiveDaysAgo.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(fiveDaysAgo.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}T00:00:00`;
  };

  const onAddToGarage = useCallback(
    (g_id, m_id, v_id) => {
      if (sessionState.user) {
        console.log("The garage", g_id, m_id, v_id);
        dispatch(showLoader());
        addVehicle(
          sessionState.user?.username ?? "",
          g_id,
          m_id,
          v_id,
          getFiveDaysAgoDate(),
          []
        )
          .then((response) => {
            if (response.status === 200) {
              // dispatch(setFilteredSearchVisible(false));
              // dispatch(setUndentifiedVinSearchVisible(false));
              setTimeout(() => {
                history.push("/Garage");
              }, 500);
              dispatch(hideLoader());
              // Handle success
            } else {
              alert("Vehicle was not added successfully");
            }
          })
          .catch((err) => {
            alert(JSON.stringify(err));
          });
      }
    },
    [history, sessionState.user, addVehicle]
  );

  let isButtonClickable = false;
  if (sessionState.user?.loginResponse?.userExpiryDate) {
    const userExpiryDateStr = sessionState.user.loginResponse
      .userExpiryDate as unknown as string;
    const userExpiryDate = new Date(userExpiryDateStr);
    const currentDate = new Date();

    if (currentDate > userExpiryDate) {
      isButtonClickable = true;
    } else {
      isButtonClickable = false;
    }
  } else {
    isButtonClickable = false; // Default to disabled if no expiry date is found
  }

  const onLogout = useCallback(() => {
    dispatch(sessionLogout());
    //history.push("/login");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/login");
    }, 500);
  }, [dispatch]);

  return (
    <IonCard className="gridCompetitorCard">
      <div className="compTitle">COMPETITOR</div>
      <IonGrid>
        <IonRow id="compCardHeader">
          <IonCol sizeXs="6" sizeMd="4" sizeLg="4" id="gridOverviewImageCont">
            <img
              id="gridOverviewImage"
              src={getImage(
                competitor.modelSpecification.attributes.G.toString(),
                competitor.modelSpecification.attributes.M.toString(),
                competitor.modelSpecification.attributes.V.toString()
              )}
            />
          </IonCol>
          <IonCol sizeXs="6" sizeMd="8" sizeLg="8" id="gridOverviewDetails">
            <h1> {competitor.modelSpecification.attributes.make}</h1>
            <h1> {competitor.modelSpecification.attributes.model}</h1>
            <h1 id="gridOverviewVariant">
              {" "}
              {competitor.modelSpecification.attributes.variant}
            </h1>

            <span id="overViewCluster">
              <h1>
                {competitor.modelClass?.result &&
                competitor.modelClass?.result.length > 0
                  ? moment(competitor.modelClass?.result[0].introDate).format(
                      "YYYY"
                    )
                  : ""}
              </h1>
              <h1>
                G{competitor.modelSpecification.attributes.G}M
                {competitor.modelSpecification.attributes.M}V
                {competitor.modelSpecification.attributes.V}{" "}
              </h1>
              {/* <h1>
                {" "}
                {competitor.modelClass?.result &&
                competitor.modelClass?.result.length > 0
                  ? competitor.modelClass?.result[0].variantId
                  : ""}
              </h1> */}
            </span>
          </IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">{""}</IonCol>
          <IonCol>{uiState.selectedVehicle?.make}</IonCol>
          <IonCol size="1">VS</IonCol>
          <IonCol>{competitor.modelSpecification.attributes.make}</IonCol>
          <IonCol>MATCH</IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">LIST PRICE</IonCol>
          <IonCol>
            {" "}
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.basic_specs?.base_price
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {" "}
            {competitor.modelSpecification?.result.basic_specs.base_price}
          </IonCol>
          <IonCol>
            {" "}
            {competitor.competitorBase?.price_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">KW</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.performance_consumption.max_power_kw
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {
              competitor.modelSpecification?.result.performance_consumption
                .max_power_kw
            }
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.kw_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">NM</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.performance_consumption.torque_nm
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {
              competitor.modelSpecification?.result.performance_consumption
                .torque_nm
            }
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.nm_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">DISPLACEMENT</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.engine_specs?.capacity
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {competitor.modelSpecification?.result.engine_specs?.capacity}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.displacement_match_percentage.toFixed(
              2
            )}
            %
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">TRANSMISSION</IonCol>
          <IonCol>
            {uiState.selectedVehicle?.data?.modelSpecification.result
              ?.basic_specs.manual_or_auto === "a"
              ? "AUTO"
              : "MANUAL"}
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {competitor.modelSpecification?.result.basic_specs
              .manual_or_auto === "a"
              ? "AUTO"
              : competitor.modelSpecification?.result.basic_specs
                  .manual_or_auto === "m"
              ? "MANUAL"
              : " "}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.transtype_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">FUEL TYPE</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.basic_specs.fuel_type
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {competitor.modelSpecification?.result.basic_specs.fuel_type}
          </IonCol>
          <IonCol
            className={
              competitor?.competitorBase?.fueltype_match_percentage !==
              undefined
                ? competitor.competitorBase.fueltype_match_percentage <= 50
                  ? "redClass"
                  : competitor.competitorBase.fueltype_match_percentage < 80
                  ? "orangeClass"
                  : ""
                : ""
            }
          >
            {competitor.competitorBase?.fueltype_match_percentage !== undefined
              ? `${competitor.competitorBase.fueltype_match_percentage.toFixed(
                  2
                )}%`
              : "N/A"}
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">MODEL YEAR</IonCol>
          <IonCol>
            {moment(uiState.selectedVehicle?.vehicleData?.intro_date).format(
              "YYYY"
            )}
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {moment(competitor.modelClass?.result[0].introDate).format("YYYY")}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.modelyear_match_percentage}%
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">MODEL CLASS</IonCol>
          <IonCol>
            {" "}
            {uiState.selectedVehicle?.data?.modelSpecification.result
              .basic_specs.model_class === "CARS - PICKUP & TRUCKS DC"
              ? " PICKUP DC"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "COMM - UTILITY VAN"
              ? "UTILITY VAN"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - SPORT UTILITY VEHICLE"
              ? "SUV"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - HATCHBACK"
              ? "HATCHBACK"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - MULTY PURPOSE VEHICLE"
              ? "MPV"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - FAMILY SEDAN"
              ? "FAMILY SEDAN"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - PICKUP & TRUCKS SC"
              ? " PICKUP SC"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "COMM - LIGHT PASSENGER"
              ? " LIGHT PASSENGER"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - SPORT WAGEN"
              ? " SPORT WAGEN"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class}
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {" "}
            {competitor.modelSpecification?.result.basic_specs.model_class ===
            "CARS - PICKUP & TRUCKS DC"
              ? "PICKUP DC"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - PICKUP & TRUCKS SC"
              ? "PICK UP SC"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "COMM - UTILITY VAN"
              ? "UTILITY VAN"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - SPORT UTILITY VEHICLE"
              ? "SUV"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - FAMILY SEDAN"
              ? "FAMILY SEDAN"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "COMM - LIGHT PASSENGER"
              ? "LIGHT PASSENGER"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - MULTY PURPOSE VEHICLE"
              ? "MPV"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - HATCHBACK"
              ? "HATCHBACK"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - SPORT WAGEN"
              ? "SPORT WAGEN"
              : "Class Unknown"}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.modelclass_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>
        <IonRow className="compAddCart">
          <IonCol size="6">
            {" "}
            <button
            onClick={() => {
  
                selectPrimaryVehicle();
                selectPrimaryVehic();
              
            }}
            >
              {" "}
              <IonIcon src={eyeSharp} />
            </button>
            {sessionState.user?.username !== "GUEST" ? (
              <button
                onClick={() =>
                  onAddToGarage(
                    competitor.modelSpecification.attributes.G.toString(),
                    competitor.modelSpecification.attributes.M.toString(),
                    competitor.modelSpecification.attributes.V.toString()
                  )
                }
              >
                ADD TO GARAGE
                <img src={icons.GarageHeader} alt="tco" />
              </button>
            ) : (
              <button onClick={onLogout}>
                CREATE ACCOUNT
               
              </button>
            )}
          </IonCol>

          <IonCol size="6" className="compTotalMatch">
            <IonCol size="12">
              {competitor.competitorBase?.match_percentage} %
            </IonCol>
            <IonCol size="12">MATCH</IonCol>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default CompetitorView;
