import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonRange,
  IonRow,
} from "@ionic/react";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Button from "../../../components/button";
import DesktopContainer from "../../../components/desktop-container";
import Header from "../../../components/header";
import MessageModal from "../../../components/message-modal";
import SubPageHeading from "../../../components/sub-page-heading";
import { AppScreens, Format } from "../../../enums";
import "../../../Global.css";
import { RootState } from "../../../redux/store";
import { hideLoader, hideSideMenu, setFilteredSearchVisible, setLargeHeader, setScreen, setShareSocialVisible, setUndentifiedVinSearchVisible, showLoader, showSideMenu } from "../../../redux/ui/uiSlice";
import { useHistory } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

import DesktopVehicleLabel from "../../../components/desktop-vehicle-label";
import { DataCompleteness, SelectedVehicle } from "../../../interfaces";
import {
  Attributes,
  ModelSpecification,
} from "../../../interfaces/modelSpecification";
import DesktopChartLegend from "../../../components/desktop-chart-legend";
import { CompetitorData } from "../../../interfaces/competitorData";
import { format } from "../../../utils";
import { sessionLogout } from "../../../redux/session/sessionSlice";
import {
  addVehicle,
  createTransaction,
  dataCompleteness,
  getImage,
  modelClass,
  modelSpecificationDetails,
  queryTransaction,
} from "../../../api/mapo";
import { Transaction } from "../../../interfaces/transaction";
import { Config } from "../../../Config";
import { desktopImages, icons, images } from "../../../assets";
import { cartSharp, chevronBackCircleSharp, chevronBackOutline, thumbsUpSharp } from "ionicons/icons";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../../components/globalGridMain/globalGrid";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

interface ModelDataType {
  engineCode: null | string;
  groupCode: number;
  introDate: string;
  make: string;
  variant: string;
}

const MaintenancePlan: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);

  const [completeness, setCompleteness] = useState<
    DataCompleteness | undefined
  >();
  const [modelSpecification, setModelSpecification] = useState<
    ModelSpecification | undefined
  >();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [transaction, setTransaction] = useState<Transaction | undefined>();
  const transactionProcessing = useRef(false);
  const transactionStartTime = useRef<Date | undefined>();

  const onModalPress = useCallback((buttonIndex: number) => {
    setModalVisible(false);
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const onBackPress = useCallback(() => {
    history.push('/OverViewDetail');
  }, [dispatch]);

  const startTransaction = useCallback(
    (discountCode: string) => {
      if (sessionState.user && uiState.selectedVehicle) {
        dispatch(showLoader());
        createTransaction(
          sessionState.user?.username ?? "",
          uiState.selectedVehicle?.g_id,
          uiState.selectedVehicle?.m_id,
          uiState.selectedVehicle?.v_id,
          discountCode
        )
          .then((createTransResponse) => {
            dispatch(hideLoader());
            if (createTransResponse.status === 200) {
              setTransaction(createTransResponse.data);
            } else {
              alert(createTransResponse.statusText);
            }
          })
          .catch((err) => {
            dispatch(hideLoader());
            alert(JSON.stringify(err));
          });
      }
    },
    [dispatch, sessionState.user, uiState.selectedVehicle]
  );

  // const onAddToGarage = useCallback(() => {
  //   if (sessionState.user && uiState.selectedVehicle) {
  //     dispatch(showLoader());
  //     addVehicle(
  //       sessionState.user?.username ?? "",
  //       uiState.selectedVehicle?.g_id,
  //       uiState.selectedVehicle?.m_id,
  //       uiState.selectedVehicle?.v_id
  //     )
  //       .then((addVehicleResponse) => {
  //         dispatch(hideLoader());
  //         if (addVehicleResponse.status === 200) {
  //           //dispatch(setScreen(AppScreens.Garage));
  //           history.push('/OverViewDetail');
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch(hideLoader());
  //         alert(JSON.stringify(err));
  //       });
  //   }
  // }, [dispatch, sessionState.user, uiState.selectedVehicle]);

//   const onAddToGarage = useCallback(() => {
//     startTransaction("");
//   }, [startTransaction]);

  const pollQueryTransaction = useCallback(() => {
    if (
      transaction?.transactionId &&
      transactionProcessing &&
      transactionStartTime
    ) {
      queryTransaction(transaction?.transactionId)
        .then((queryResponse) => {
          if (queryResponse.data === "Pending") {
            if (transactionStartTime.current !== undefined) {
              const passedSeconds =
                (new Date().getTime() -
                  transactionStartTime.current?.getTime()) /
                1000;
              if (passedSeconds > 120) {
                dispatch(hideLoader());
                transactionProcessing.current = false;
                transactionStartTime.current = undefined;
                setTransaction(undefined);
                alert(
                  "Payment could not be processed. Please try again later."
                );
                return;
              }
            }
            setTimeout(() => {
              pollQueryTransaction();
            }, 3000);
          } else if (queryResponse.data === "COMPLETE") {
            dispatch(showLoader());
            transactionProcessing.current = false;
            setTransaction(undefined);
            // addToGaragePaid();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [transaction, transactionProcessing, dispatch]);

  const onModalButtonPress = useCallback(
    (buttonIndex: number) => {
      if (buttonIndex === 1) {
        transactionProcessing.current = true;
        transactionStartTime.current = new Date();
        dispatch(showLoader());
        window.open(
          Config.APP_SERVER +
            "/api/payment/" +
            transaction?.transactionId +
            "/" +
            transaction?.cost
        );
        setTimeout(() => {
          pollQueryTransaction();
        }, 3000);
      } else {
        setTransaction(undefined);
      }
      setModalConfirm(false);
      setModalVisible(false);
    },
    [dispatch, transaction, pollQueryTransaction]
  );


  const onNewSearch = useCallback(() => {
    dispatch(setScreen(AppScreens.Home));
  }, [dispatch]);

  useEffect(() => {
    if (
      transaction !== undefined &&
      !modalVisible &&
      !transactionProcessing.current
    ) {
      setModalConfirm(true);
      setModalMessage(
        "Are you sure you want to use continue to add this vehicle to your garage at a cost of R" +
          format(transaction.cost, Format.Currency) +
          "? \n\nA seperate browser window will open to Payfast where you need to complete the payment before the vehicle will be added to your garage."
      );
      setModalVisible(true);
    }
  }, [transaction, modalVisible, transactionProcessing]);

  useEffect(() => {
    if (completeness === undefined && uiState.selectedVehicle) {
      dispatch(showLoader());
      dataCompleteness(
        uiState.selectedVehicle?.g_id,
        uiState.selectedVehicle?.m_id,
        uiState.selectedVehicle?.v_id
      )
        .then((dataCompletenessResponse) => {
          if (
            dataCompletenessResponse.status === 200 &&
            uiState.selectedVehicle
          ) {
            const data: DataCompleteness = dataCompletenessResponse.data;
            setCompleteness(data);
            modelSpecificationDetails(
              uiState.selectedVehicle?.g_id,
              uiState.selectedVehicle?.m_id,
              uiState.selectedVehicle?.v_id
            )
              .then((modelClassResponse) => {
                if (modelClassResponse.status === 200) {
                  setModelSpecification(modelClassResponse.data);
                  dispatch(hideLoader());
                }
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert(JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
            alert(dataCompletenessResponse.statusText);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(JSON.stringify(err));
        });
    }
  }, [completeness, dispatch, uiState.selectedVehicle]);
  const hasData = useCallback(
    (type: string) => {
      switch (type) {
        case "serviceChecks": {
          if (
            ((Number(completeness?.serviceChecks) /
              Number(completeness?.serviceChecks_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceLabour": {
          if (
            ((Number(completeness?.serviceLabour) /
              Number(completeness?.serviceLabour_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceLubes": {
          if (
            ((Number(completeness?.serviceLubes) /
              Number(completeness?.serviceLubes_Max)) *
              100 ?? 0) > 70
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "serviceParts": {
          if (
            ((Number(completeness?.serviceParts) /
              Number(completeness?.serviceParts_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceChecks": {
          if (
            ((Number(completeness?.maintenanceChecks) /
              Number(completeness?.maintenanceChecks_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceLabour": {
          if (
            ((Number(completeness?.maintenanceLabour) /
              Number(completeness?.maintenanceLabour_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "maintenanceParts": {
          if (
            ((Number(completeness?.maintenanceParts) /
              Number(completeness?.maintenanceParts_Max)) *
              100 ?? 0) > 79
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    [completeness]
  );

  //To save the selectedVehicle in the cart
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  // const handleSaveSelectedVehicleToCart = () => {
  //   if (uiState.selectedVehicle) {
  //     // Retrieve existing cart data from localStorage
  //     const storedCartString = localStorage.getItem("cart");
  //     const existingCart = storedCartString ? JSON.parse(storedCartString) : [];

  //     // Check if the item with the same 'gmv' is already in the cart
  //     const isDuplicate = existingCart.some(
  //       (item: any) => item.variant === uiState.selectedVehicle?.variant
  //     );

  //     if (!isDuplicate) {
  //       // Add the current model to the cart
  //       existingCart.push(uiState.selectedVehicle);

  //       localStorage.setItem("cart", JSON.stringify(existingCart));
  //       // Show the "Item added to cart" message
  //       showMessageWithTimeout("Model added to cart");
  //     } else {
  //       // Show the "Item already in cart" message
  //       showMessageWithTimeout("Model already in cart");
  //     }
  //   }
  // }; //text doesn't go away it just stays there
  const handleSaveSelectedVehicleToCart = () => {
   
    history.push("/Cart");
  };

  useEffect(() => {
    // Hide the message after 3 seconds (adjust as needed)
    const timeoutId = setTimeout(() => {
      setShowMessage(false);
      setMessage('');
    }, 3000);

    return () => clearTimeout(timeoutId); // Clear the timeout on component unmount or state change
  }, [showMessage]);

  const showMessageWithTimeout = (text:any) => {
    setMessage(text);
    setShowMessage(true);
  };

  const [ModelDate, setModelDate] = useState<ModelDataType[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if uiState.selectedVehicle is defined
        if (uiState.selectedVehicle) {
          const modelClassResponse = await modelClass(
            uiState.selectedVehicle.g_id,
            uiState.selectedVehicle.m_id,
            uiState.selectedVehicle.v_id
          );

          // Check if the response status is 200
          if (modelClassResponse.status === 200) {
            const result = modelClassResponse.data.result[0]; // Assuming result is an array with at least one element

            // Access and log specific properties

            const data = modelClassResponse.data;

            // Set the fetched data to ModelDate state
            setModelDate(modelClassResponse.data.result || []);
          } else {
            console.warn("Model class response status is not 200");
          }
        } else {
          console.warn("uiState.selectedVehicle is undefined");
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [uiState.selectedVehicle, modelClass]);

  const onToggleMenu = useCallback(() => {
    if (!uiState.sideMenuOpen) {
      dispatch(showSideMenu());
    } else {
      dispatch(hideSideMenu());
    }
  }, [dispatch, uiState.sideMenuOpen]);

  // const onLogout = useCallback(() => {
  //   dispatch(setLargeHeader(false));
  //   dispatch(hideSideMenu());
  //   dispatch(sessionLogout());
  //   dispatch(setScreen(AppScreens.Login));
  // }, [dispatch]);

  const onHome = useCallback(() => {
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Home));
    //history.push("/home");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));

    setTimeout(() => {
      window.location.reload();
      history.push("/home");
    }, 500);
  }, [dispatch]);

  const onGarage = useCallback(() => {
    dispatch(setLargeHeader(false));
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Garage));
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      //dispatch(setScreen(AppScreens.VehicleLanding));
      history.push("/Garage");
    }, 500);
    // history.push("/Garage");
  }, [dispatch]);

  const onTutorial = useCallback(() => {
    //dispatch(setScreen(AppScreens.Tutorial));
    //history.push("/Tutorial");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Tutorial");
    }, 500);
  }, [dispatch]);

  const onAbout = useCallback(() => {
    //dispatch(setScreen(AppScreens.About));
    //history.push("/About");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/About");
    }, 500);
  }, [dispatch]);

  const Myprofile = useCallback(() => {
    //dispatch(setScreen(AppScreens.UserProfile));
    // history.push("/UserProfile");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/UserProfile");
    }, 500);
  }, [dispatch]);

  const onSupport = useCallback(() => {
    // dispatch(setScreen(AppScreens.Support));
    // history.push("/Support");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Support");
    }, 500);
  }, [dispatch]);

  const onContact = useCallback(() => {
    //dispatch(setScreen(AppScreens.Contact));
    //history.push("/Contact");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Contact");
    }, 500);
  }, [dispatch]);

  const OnShare = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    dispatch(setShareSocialVisible(true));
  }, [dispatch]);

  const onLogout = useCallback(() => {
    dispatch(sessionLogout());
    //history.push("/login");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/login");
    }, 500);
  }, [dispatch]);

  const OnCart = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Cart");
    }, 500);
  }, [dispatch]);
  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);

  const sideMenuItems = [
    {
      title: "model search",
      func: onHome,
      icon: icons.mobileSearchIcon,
    },
    {
      title: "my garage",
      func: onGarage,
      icon: icons.myGarageIcon,
    },
    {
      title: "my profile",
      func: Myprofile,
      icon: icons.userProfileIcon,
    },
    {
      title: "SUPPORT",
      func: undefined,
      icon: undefined,
    },
    {
      title: "app tutorial",
      func: onTutorial,
      icon: icons.appTutorialIcon,
    },
    {
      title: "MAPO ",
      func: onAbout,
      icon: icons.mobileMapoIcon,
    },
    {
      title: "CONTACT US",
      func: onContact,
      icon: icons.appContactIcon,
    },
    {
      title: "Share ",
      func: OnShare,
      icon: icons.appShareIcon,
    },
    {
      title: " ",
      func: undefined,
      icon: undefined,
    },
    {
      title: "Log out ",
      func: onLogout,
      icon: icons.appLeaveIcon,
    },
  ];

  const renderSideMenu = useCallback(() => {
    return (
      <>
        {uiState.sideMenuOpen ? (
          <div className="desktopSideMenu">
            {sideMenuItems?.map((value, index) => {
              return (
                <div className="MenuItem" onClick={value.func}>
                  <span className="MenuImg" onClick={value.func}>
                    {value.icon ? (
                      <img onClick={value.func} src={value.icon} />
                    ) : (
                      <></>
                    )}
                  </span>
                  <span onClick={value.func}>{value.title}</span>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  }, [
    uiState.sideMenuOpen,
    onHome,
    onGarage,
    onToggleMenu,
    onAbout,
    onTutorial,
    onContact,
    onSupport,
    sessionState.user,
  ]);

  const onDetailedSpecs = useCallback(() => {
    //dispatch(setScreen(AppScreens.Specs));
    history.push("/DetailSpecs");
  }, [dispatch]);

  const onServiceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Services));
    history.push("/ServicePlan");
  }, [dispatch]);

  const onMaintananceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Maintenance));
    history.push("/MaintenancePlan");
  }, [dispatch]);

  const onTCO = useCallback(() => {
    //dispatch(setScreen(AppScreens.TCO));
    history.push("/TotalCostOwner");
  }, [dispatch]);

  const onPartsBasket = useCallback(() => {
    //dispatch(setScreen(AppScreens.Parts));
    history.push("/Basket");
  }, [dispatch]);

  const on10KRates = useCallback(() => {
    //dispatch(setScreen(AppScreens.Rates10K));
    history.push("/RatesTenK");
  }, [dispatch]);

  const onCompetitors = useCallback(() => {
    //dispatch(setScreen(AppScreens.Competitors));
    history.push("/Competitor");
  }, [dispatch]);

  const onReviews = useCallback(() => {
    // dispatch(setScreen(AppScreens.Reviews));
    history.push("/Reviews");
  }, [dispatch]);

  const onResidualValue = useCallback(() => {
    //dispatch(setScreen(AppScreens.ResidualValue));
    history.push("/ResellValue");
  }, [dispatch]);

  const onFinanceCalculator = useCallback(() => {
    //dispatch(setScreen(AppScreens.FinanceCalculator));
    history.push("/FinanceCalculate");
  }, [dispatch]);

  const onPreowned = useCallback(() => {
    //dispatch(setScreen(AppScreens.Preowned));
    // history.push("/Preowned");
  }, [dispatch]);

  const menuLoadText = [
    "SERVICE SCHEDULES",
    "MAINTENANCE SCHEDULES",
    "TCO INFORMATION",
    "DETAILED SPECIFICATIONS",
    "PARTS BASKET",
    "10K RATES",
    "RESIDUAL VALUE",
  ];

  const [menuLoadTextIndex, setMenuLoadTextIndex] = useState(0);
  const [menuLoadTextDisplayer, setMenuLoadTextDisplayer] =
    useState(" LOADING MENUS...");

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the menuLoadTextDisplayer with the current menu item
      setMenuLoadTextDisplayer(menuLoadText[menuLoadTextIndex]);

      // Move to the next menu item index
      setMenuLoadTextIndex(
        (prevIndex) => (prevIndex + 1) % menuLoadText.length
      );
    }, 1000);

    // Clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, [menuLoadTextIndex, menuLoadText])

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const pageTilte = "MAINTAINCE PLAN"
  return (
    <>
   
        <GlobalGrid currentPageTitle="MAINTENANCE PLAN"
          closeCurrentPage={() => onBackPress()}>
          <div className="innerContent">
            
          <div className="headHolder HeaderStick">
          <IonRow id="selectedVehicleGlobal">
                {uiState.selectedVehicle ? (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeMd="6"
                      sizeLg="6"
                      id="gridOverviewImageCont"
                    >
                      <img
                        id="gridOverviewImage"
                        src={getImage(
                          uiState.selectedVehicle.g_id.toString(),
                          uiState.selectedVehicle.m_id.toString(),
                          uiState.selectedVehicle.v_id.toString()
                        )}
                        alt=""
                      />{" "}
                    </IonCol>

                    <IonCol
                      sizeXs="6"
                      sizeMd="6"
                      sizeLg="6"
                      id="gridOverviewDetails"
                    >
                      <h1>{uiState.selectedVehicle.make}</h1>
                      <h1>{uiState.selectedVehicle.model}</h1>
                      <h1 id="gridOverviewVariant">
                        {Array.isArray(ModelDate) &&
                          ModelDate.map((details, index) => (
                            <>{details.variant}</>
                          ))}
                      </h1>
                      <span id="overViewCluster">
                        {Array.isArray(ModelDate) &&
                          ModelDate.map((details, index) => (
                            <h1 key={index}>
                              MY{" "}
                              {moment(new Date(details.introDate ?? "")).format(
                                "YYYY"
                              )}
                            </h1>
                          ))}
                        <h1>
                          G{uiState.selectedVehicle.g_id.toString()}M
                          {uiState.selectedVehicle.m_id.toString()}V
                          {uiState.selectedVehicle.v_id.toString()}
                        </h1>
                        <h1>{uiState.selectedVehicle.variant_id}</h1>{" "}
                      </span>
                    </IonCol>
                  </>
                ) : null}
              </IonRow>
            </div>
            
          
          <div className="DataCompleteness-sec">
            {/* <span className="AddVehicleText">
                <strong>
                  Add Vehicle in Garage <br />
                  To View Service Costs
                </strong>//
              </span> */}

            <p>
              <strong className="FontMobileSpcs MapoDataReport">
                MAPO DATA REPORT
              </strong>
              <br />
              <span className="FontMobileSpcs">
                The following data is available on the searched
              </span>
            </p>
            <br />

            <>
                {hasData("serviceChecks") ? (
                  <IonCol size="12" className="completenesLabels">
                    <span className="greenData">
                      <IonImg src={icons.serviceChecks} alt="loading" />
                    </span>
                    <p> Service checks data available</p>
                  </IonCol>
                ) : (
                  <IonCol size="12" className="completenesLabels">
                    <span className="redData">
                      <IonImg src={icons.serviceChecks} alt="loading" />
                    </span>
                    <p> Service checks data unavailable</p>
                  </IonCol>
                )}

                {hasData("serviceLabour") ? (
                  <IonCol size="12" className="completenesLabels">
                    <span className="greenData">
                      <IonImg src={icons.serviceLabour} alt="loading" />
                    </span>
                    <p> Service labour data available</p>
                  </IonCol>
                ) : (
                  <IonCol size="12" className="completenesLabels">
                    <span className="redData">
                      <IonImg src={icons.serviceLabour} alt="loading" />
                    </span>
                    <p> Service labour data unavailable</p>
                  </IonCol>
                )}

                {hasData("serviceLubes") ? (
                  <IonCol size="12" className="completenesLabels">
                    <span className="greenData">
                      <IonImg src={icons.serviceLube} alt="loading" />
                    </span>
                    <p> Fluids data available</p>
                  </IonCol>
                ) : (
                  <IonCol size="12" className="completenesLabels">
                    <span className="redData">
                      <IonImg src={icons.serviceLube} alt="loading" />
                    </span>
                    <p> Fluids data unavailable</p>
                  </IonCol>
                )}

                {hasData("serviceChecks") ? (
                  <IonCol size="12" className="completenesLabels">
                    <span className="greenData">
                      <IonImg src={icons.serviceChecks} alt="loading" />
                    </span>
                    <p> Service parts data available</p>
                  </IonCol>
                ) : (
                  <IonCol size="12" className="completenesLabels">
                    <span className="redData">
                      <IonImg src={icons.serviceChecks} alt="loading" />
                    </span>
                    <p> Service parts data unavailable.</p>
                  </IonCol>
                )}

                {hasData("serviceChecks") ? (
                  <IonCol size="12" className="completenesLabels">
                    <span className="greenData">
                      <IonImg src={icons.maintenanceChecks} alt="loading" />
                    </span>
                    <p>Replacement parts data available</p>
                  </IonCol>
                ) : (
                  <IonCol size="12" className="completenesLabels">
                    <span className="redData">
                      <IonImg src={icons.maintenanceChecks} alt="loading" />
                    </span>
                    <p> Replacement parts data unavailable</p>
                  </IonCol>
                )}

                {hasData("maintenanceLabour") ? (
                  <IonCol size="12" className="completenesLabels">
                    <span className="greenData">
                      <IonImg src={icons.maintenanceLabour} alt="loading" />
                    </span>
                    <p> Maintenance labour data available</p>
                  </IonCol>
                ) : (
                  <IonCol size="12" className="completenesLabels">
                    <span className="redData">
                      <IonImg src={icons.maintenanceLabour} alt="loading" />
                    </span>
                    <p> Maintenance labour data unavailable</p>
                  </IonCol>
                )}

                {hasData("maintenanceParts") ? (
                  <IonCol size="12" className="completenesLabels">
                    <span className="greenData">
                      <IonImg src={icons.maintenanceParts} alt="loading" />
                    </span>
                    <p> Maintenance parts data available.</p>
                  </IonCol>
                ) : (
                  <IonCol size="12" className="completenesLabels">
                    <span className="redData">
                      <IonImg src={icons.maintenanceParts} alt="loading" />
                    </span>
                    <p> Maintenance parts data unavailable.</p>
                  </IonCol>
                )}
              </>
            <br />
            {/* <div className="alertCartMessages">
            <p className={showMessage ? "show-message" : "hide-message"}>
              <strong >{message}</strong>
            </p>
          </div> */}
          </div>
          </div>
          
          {showMessage ? (
          <div className="addToCartAlertCont">
            <div className="thumb">
              <IonIcon src={thumbsUpSharp}></IonIcon>
            </div>
            <p>{message}</p>
          </div>
        ) : null}
        </GlobalGrid>
        <div
        className="RenewBanner"
        // onClick={() => {
        //   setShowVisitor(!showVisitor);
        //   setPrevSeen(prevSeen + 1);
        // }}
      >
       {sessionState.user?.username !== "GUEST" ? (
               <div className="RenewText" onClick={handleSaveSelectedVehicleToCart}>
                 RENEW SUBCRIPTION
                 <span id="showVisitorIcon">
                   <img className="iconRenew" src={icons.WhiteCartIcon} />
                 </span>
               </div>
               ) : (
                 <div className="RenewText" onClick={onLogout}>
                  CREATE ACCOUNT
                 </div>
               )}
      </div>
    
      {modalConfirm ? (
        <MessageModal
          icon={images.orangeQuestion}
          visible={modalVisible}
          message={modalMessage}
          button1="Yes, Continue"
          button2="Cancel"
          onPress={onModalButtonPress}
        />
      ) : (
        <MessageModal
          visible={modalVisible}
          message={modalMessage}
          onPress={onModalPress}
        />
      )}
    </>
  );
};

export default MaintenancePlan;
