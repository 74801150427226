import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonButton,
  IonToolbar,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { icons, images } from "../../assets";
import "./style.css";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  cartSharp,
  logOut,
  menuOutline,
  personCircle,
  shareSocial,
} from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { sessionLogout } from '../../redux/session/sessionSlice';
import {
  hideSideMenu,
  setFilteredSearchVisible,
  setLargeHeader,
  setRemoveAccountVisible,
  setScreen,
  setShareSocialVisible,
  setUndentifiedVinSearchVisible,
  showSideMenu,
} from "../../redux/ui/uiSlice";
import { IonMenuButton } from "@ionic/react";
import { AppScreens } from "../../enums";
import MenuButton from "../menu-button";
import Bars from "../../assets/mobile/images/BarsMenu.png";
import { sessionLogout } from "../../redux/session/sessionSlice";
import { useHistory } from "react-router-dom";
import RatingModal from "../ratingModal";

interface HeaderProps {
  smallHeader?: boolean;
}

const Header: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();

  const onToggleMenu = useCallback(() => {
    if (!uiState.sideMenuOpen) {
      dispatch(showSideMenu());
    } else {
      dispatch(hideSideMenu());
    }
  }, [dispatch, uiState.sideMenuOpen]);

  // const onLogout = useCallback(() => {
  //   dispatch(setLargeHeader(false));
  //   dispatch(hideSideMenu());
  //   dispatch(sessionLogout());
  //   dispatch(setScreen(AppScreens.Login));
  // }, [dispatch]);

  const onHome = useCallback(() => {
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Home));
    //history.push("/home");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));

    setTimeout(() => {
      window.location.reload();
      history.push("/home");
    }, 500);
  }, [dispatch]);

  const onGarage = useCallback(() => {
    dispatch(setLargeHeader(false));
    dispatch(hideSideMenu());
    //dispatch(setScreen(AppScreens.Garage));
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      //dispatch(setScreen(AppScreens.VehicleLanding));
      history.push("/Garage");
    }, 500);
    // history.push("/Garage");
  }, [dispatch]);

  const onTutorial = useCallback(() => {
    //dispatch(setScreen(AppScreens.Tutorial));
    //history.push("/Tutorial");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Tutorial");
    }, 500);
  }, [dispatch]);

  const onAbout = useCallback(() => {
    //dispatch(setScreen(AppScreens.About));
    //history.push("/About");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/About");
    }, 500);
  }, [dispatch]);

  const Myprofile = useCallback(() => {
    //dispatch(setScreen(AppScreens.UserProfile));
    // history.push("/UserProfile");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/UserProfile");
    }, 500);
  }, [dispatch]);

  const onSupport = useCallback(() => {
    // dispatch(setScreen(AppScreens.Support));
    // history.push("/Support");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Support");
    }, 500);
  }, [dispatch]);

  const onContact = useCallback(() => {
    //dispatch(setScreen(AppScreens.Contact));
    //history.push("/Contact");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Contact");
    }, 500);
  }, [dispatch]);

  const OnShare = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    dispatch(setShareSocialVisible(true));
  }, [dispatch]);

  const onLogout = useCallback(() => {
    dispatch(sessionLogout());
    //history.push("/login");
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/login");
    }, 500);
  }, [dispatch]);

  const OnCart = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    setTimeout(() => {
      history.push("/Cart");
    }, 500);
  }, [dispatch]);

  const OnAccountRemoval = useCallback(() => {
    dispatch(setFilteredSearchVisible(false));
    dispatch(setUndentifiedVinSearchVisible(false));
    dispatch(setRemoveAccountVisible(true));
  }, [dispatch]);

  const [localStorageLength, setLocalStorageLength] = useState(0);

  let isUserSubscriptionValid = false;
  if (sessionState.user?.loginResponse?.userExpiryDate) {
    const userExpiryDateStr = sessionState.user.loginResponse
      .userExpiryDate as unknown as string;
    const userExpiryDate = new Date(userExpiryDateStr);
    const currentDate = new Date();

    if (currentDate > userExpiryDate) {
      isUserSubscriptionValid = false;
    } else {
      isUserSubscriptionValid = true;
    }
  } else {
    isUserSubscriptionValid = false; // Default to disabled if no expiry date is found
  }

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);

  const renderSideMenu = useCallback(() => {
    return (
      <>
        {uiState.sideMenuOpen ? (
          <div
            className="gridSideMenuCont"
            onClick={() => {
              onToggleMenu();
            }}
          >
            <IonRow className="gridSideMenu">
              {}
              <IonCol size="12" sizeXl="12" className="gridSideMenuItems">
                MAIN MENU
              </IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onHome}
              >
                <img src={icons.mobileSearchIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">MODEL SEARCH</span>
              </IonCol>
              {sessionState.user?.username !== "GUEST" &&
              isUserSubscriptionValid ? (
                <IonCol
                  size="12"
                  sizeXl="12"
                  className="gridSideMenuItems"
                  onClick={onGarage}
                >
                  <img src={icons.myGarageIcon} className="gridMenuImg" />
                  <span className="gridMenuTxt">MY GARAGE</span>
                </IonCol>
              ) : null}
              {sessionState.user?.username !== "GUEST" ? (
                <IonCol
                  size="12"
                  sizeXl="12"
                  className="gridSideMenuItems"
                  onClick={Myprofile}
                >
                  <img src={icons.userProfileIcon} className="gridMenuImg" />
                  <span className="gridMenuTxt">MY PROFILE</span>
                </IonCol>
              ) : null}
              <IonCol size="12" sizeXl="12" className="gridSideMenuItems">
                INFO &amp; SUPPORT
              </IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onTutorial}
              >
                <img src={icons.appTutorialIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">APP TUTORIAL</span>
              </IonCol>
              {/* <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onSupport}
              >
                <img src={icons.appSupportIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">APP SUPPORT</span>
              </IonCol> */}
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onAbout}
              >
                <img src={icons.mobileMapoIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">MAPO SOLUTIONS</span>
              </IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onContact}
              >
                <img src={icons.appContactIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">CONTACT US</span>
              </IonCol>
              {sessionState.user?.username !== "GUEST" ? (
                <IonCol
                  size="12"
                  sizeXl="12"
                  className="gridSideMenuItems"
                  onClick={OnAccountRemoval}
                >
                  <img src={icons.RemoveAccount} className="gridMenuImg" />
                  <span className="gridMenuTxt">Account Removal</span>
                </IonCol>
              ) : null}
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={OnShare}
              >
                <img src={icons.appShareIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">Share</span>
              </IonCol>

              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
              ></IonCol>
              <IonCol
                size="12"
                sizeXl="12"
                className="gridSideMenuItems"
                onClick={onLogout}
              >
                <img src={icons.appLeaveIcon} className="gridMenuImg" />
                <span className="gridMenuTxt">Log out</span>
              </IonCol>
            </IonRow>
          </div>
        ) : null}
      </>
    );
  }, [
    uiState.sideMenuOpen,
    onHome,
    onGarage,
    onToggleMenu,
    onAbout,
    onTutorial,
    onContact,
    onSupport,
    sessionState.user,
  ]);
  const [rating, setRating] = useState(true);

  return (
    <>
      <IonGrid>
        <IonRow className="gridHeader">
          <IonCol>
            <img
              id="grdiHeaderIcons"
              src={icons.WhiteIconMenu}
              onClick={() => {
                onToggleMenu();
              }}
            />
          </IonCol>
          <IonCol sizeXs="6" sizeMd="6" sizeLg="6" sizeXl="4">
            <img id="grdiHeaderLogo" src={images.logo} alt="" />
          </IonCol>
          <IonCol className="rightCol">
            {/* <img
              id="grdiHeaderIcons"
              src={icons.WhiteCartIcon}
              onClick={() => {
                OnCart();
              }}
            />
            <p id="gridHeaderCounter">{localStorageLength}</p> */}
            <img
              id="grdiHeaderIcons"
              src={icons.HeaderSearchIcon}
              onClick={() => {
                onHome();
              }}
            />
            {sessionState.user?.username !== "GUEST" &&
            isUserSubscriptionValid ? (
              <img
                id="grdiHeaderIcons"
                src={icons.GarageHeader}
                onClick={onGarage}
              />
            ) : null}
            <img id="grdiHeaderIcons" src={icons.AppShare} onClick={OnShare} />
          </IonCol>
        </IonRow>
      </IonGrid>

      {renderSideMenu()}
    </>
  );
};

export default Header;
