import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonIcon,
  IonGrid,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonText,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonToggle,
} from "@ionic/react";
import {
  Key,
  ReactChild,
  ReactFragment,
  ReactPortal,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  personCircle,
  locationOutline,
  cogSharp,
  refresh,
} from "ionicons/icons";
import { RootState } from "../../redux/store";
import axios from "axios";
import { useHistory } from "react-router-dom";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import "./userProfile.css";
import "../../dynamsoft.config";
import VideoCapture from "./ScanComponents/VideoCapture/VideoCapture";
import DesktopContainer from "../../components/desktop-container";
import { images } from "../../assets";
import { LicenseCard } from "../../api/mapo";
import { hideLoader, showLoader } from "../../redux/ui/uiSlice";

enum Modes {
  VIDEO_CAPTURE = "video",
  IMAGE_CAPTURE = "image",
}

interface BarcodeResult {
  format: string;
  text: string;
  [key: string]: any; // This allows for any other parsed fields
}

const UserProfile: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.Home));
    history.push("/home");
  }, [dispatch]);
  const [mode, setMode] = useState<Modes>(Modes.VIDEO_CAPTURE);
  const [results, setResults] = useState<BarcodeResult[]>([]);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [fingerprint, setFingerprint] = useState(() => {
    const storedFingerprintToggle = localStorage.getItem("fingerprintToggle");
    return storedFingerprintToggle
      ? JSON.parse(storedFingerprintToggle)
      : false;
  });
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [mobile, setMobile] = useState(localStorage.getItem("mobile") || "");
  const [renewLicense, setRenewLicense] = useState(false);

  useEffect(() => {
    // Store the current state in localStorage whenever it changes
    localStorage.setItem("fingerprintToggle", JSON.stringify(fingerprint));
    localStorage.setItem("user_name", sessionState.user?.username || "");

    if (email) {
      localStorage.setItem("email", email);
    }
    if (mobile) {
      localStorage.setItem("mobile", mobile);
    }

    const storedState = localStorage.getItem("renewLicense");
    if (storedState !== null) {
      setRenewLicense(JSON.parse(storedState)); // Parse the stored value to boolean
    }
  }, [fingerprint, email, mobile]);

  // Handle toggle change and store the state in localStorage
  const handleToggleChange = (e: any) => {
    const checked = e.detail.checked;
    setRenewLicense(checked);
    localStorage.setItem("renewLicense", JSON.stringify(checked)); // Store the value in localStorage
  };

  const showVideoCapture = () => {
    setMode(Modes.VIDEO_CAPTURE);
    setIsCameraVisible((prevState) => !prevState);
  };
  const showImageCapture = () => {
    setMode(Modes.IMAGE_CAPTURE);
  };

  console.log("The results", results);
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [photo, setPhoto] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Load image from local storage on component mount
    getGeoInfo();
  }, []);

  function convertHex(val: string): string {
    // Function to convert bytes to a hex string
    function hexFromBytes(bytes: Uint8Array): string {
      return Array.from(bytes)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase();
    }

    // Convert string to bytes using TextEncoder for "ISO-8859-1" encoding
    const encoder = new TextEncoder();
    const bytes = encoder.encode(val);

    // Get hex representation
    let ss = hexFromBytes(bytes);

    // Replace "3F3F" with "00" and convert to lowercase
    ss = ss.replace(/3F3F/g, "00").toLowerCase();

    return ss;
  }

  const [hexResult, setHexResult] = useState("");
  const [inputString, setInputString] = useState("");
  // Handle input change
  const handleInputChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setInputString(value);
    setHexResult(convertHex(value)); // Convert to hex when input changes
  };

  console.log("The decryppted ", hexResult);

  const [hexInput, setHexInput] = useState<string>("");
  const [readableOutput, setReadableOutput] = useState<string>("");
  const [refreshKey, setRefreshKey] = useState(0);

  const hexToReadable = (hex: string) => {
    let result = "";

    // Convert hex to binary
    for (let i = 0; i < hex.length; i += 2) {
      const byte = hex.substring(i, i + 2);
      const charCode = parseInt(byte, 16);
      result += String.fromCharCode(charCode);
    }

    return result;
  };

  const handleConvert = () => {
    // const readableText = hexToReadable(hexInput);
    // setReadableOutput(readableText);
    // setReadableOutput
    localStorage.removeItem("barcodeResults");
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      const message = event.data;

      if (message === "Biometric authentication successful.") {
        // setAuthMessage(message);
      } else if (message === "Biometric authentication Not successful.") {
        // alert("Fingerprint authentication failed.");
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    // Retrieve and log the saved barcode results from localStorage
    let savedResults = JSON.parse(
      localStorage.getItem("barcodeResults") || "[]"
    );
    console.log("Fetched results from localStorage:", savedResults);

    // Optional: Remove duplicates if they exist
    savedResults = savedResults.filter(
      (item: { text: any }, index: any, self: any[]) =>
        index === self.findIndex((t: { text: any }) => t.text === item.text)
    );
    const storedCart = localStorage.getItem("authToken");

    setResults(savedResults);
  }, [refreshKey]);

  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const handleCombinedClick = () => {
    showVideoCapture();
    handleRefresh();
  };

  // Utility function to format field names with capitalized words
  function formatFieldName(fieldName: string): string {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const formatDate = (dateString:any) => {
    if (!dateString) return "";
  
    // Extract the year, month, and day from the date string
    const year = dateString.slice(0, 4);
    const month = parseInt(dateString.slice(4, 6), 10);
    const day = dateString.slice(6, 8);
  
    // Array of short month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    // Get the month name from the month number
    const monthName = monthNames[month - 1]; // Month is zero-indexed
  
    // Return in the desired format
    return `${day} ${monthName} ${year}`;
  }; 

  useEffect(() => {
    if (results.length) {
      dispatch(showLoader())
      results.forEach((result) => {
        const initialsObject = result.ResultInfo.find(
          (item: { FieldName: string }) => item.FieldName === "initials"
        );
        const surnameObject = result.ResultInfo.find(
          (item: { FieldName: string }) => item.FieldName === "surname"
        );
        const  birthObject = result.ResultInfo.find(
          (item: { FieldName: string }) => item.FieldName === "birthDate"
        );
  
        const validityFromObject = result.ResultInfo.find(
          (item: { FieldName: string }) =>
            item.FieldName === "licenseValidityFrom"
        );
        const validityToObject = result.ResultInfo.find(
          (item: { FieldName: string }) =>
            item.FieldName === "licenseValidityTo"
        );

        // Making the API call for each result
        LicenseCard(
          sessionState.user?.username ?? "",
          initialsObject ? initialsObject.Value : "" + surnameObject ? initialsObject.Value : "",
          birthObject,
          validityFromObject ? validityFromObject.Value : "",
          validityToObject ? validityToObject.Value : ""
        )
          .then((response) => {
           dispatch(hideLoader())
          })
          .catch((error) => {
            console.error("Error saving data:", error);
          });
      });
    }
  }, [results]); 

  return (
    <GlobalGrid
      currentPageTitle="MY PROFILE"
      closeCurrentPage={() => onBackPress()}
    >
      {/* <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          display: "flex",
        }}
      >
        <DesktopContainer>
          <h2
            style={{
              textAlign: "center",
              fontSize: "50px",
              fontWeight: "bold",
              marginTop: "40px",
            }}
          >
            Driver License Details
          </h2>
          {!isCameraVisible && (
            <div className="buttons-container">
              {results.length ? (
                <IonGrid
                  className=""
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid #000",
                    marginBottom: "20px",
                  }}
                >
                  {results.map((result, index) => (
                    <div key={index} style={{ textAlign: "center" }}>
                      {result.ResultInfo.filter(
                        (item: any) => item.FieldName !== "ChildFields"
                      )
                        .sort((a: any, b: any) => {
                          const fieldNameOrder = [
                            "initials",
                            "surname",
                            "gender",
                            "birthDate",
                            "idNumber",
                            "idIssuedCountry",
                            "driverRestrictionCodes",
                            "idNumberType",
                            "licenseIssueNumber",
                            "licenseIssuedCountry",
                            "licenseNumber",
                            "licenseValidityFrom",
                            "licenseValidityTo",
                            "professionalDrivingPermitCodes",
                            "professionalDrivingPermitExpiryDate",
                          ];
                          const indexA = fieldNameOrder.indexOf(a.FieldName);
                          const indexB = fieldNameOrder.indexOf(b.FieldName);
                          return (
                            (indexA === -1 ? fieldNameOrder.length : indexA) -
                            (indexB === -1 ? fieldNameOrder.length : indexB)
                          );
                        })
                        .map((item: any, index: any) => (
                          <IonRow key={index} className="basic-spec-row">
                            {item.FieldName === "vehicleLicense" ? (
                              <IonCol className="license-details">
                                {(() => {
                                  try {
                                    const vehicleData = JSON.parse(item.Value);
                                    return vehicleData.map(
                                      (field: any, fieldIndex: any) => (
                                        <div
                                          key={fieldIndex}
                                          className="LicenseItems"
                                        >
                                          <IonRow className="ion-justify-content-center">
                                            <IonCol className="label-colu">
                                              <span>License Issued Date</span>
                                            </IonCol>
                                            <IonCol className="value-colu">
                                              <span>
                                                {field.licenseCodeIssuedDate}
                                              </span>
                                              <br />
                                            </IonCol>
                                          </IonRow>
                                          <IonRow>
                                            <IonCol className="label-colu">
                                              <span>Vehicle Code</span>
                                            </IonCol>
                                            <IonCol className="value-colu">
                                              <span>{field.vehicleCode}</span>
                                              <br />
                                            </IonCol>
                                          </IonRow>
                                          <IonRow>
                                            <IonCol className="label-colu">
                                              <span>Vehicle Restriction</span>
                                            </IonCol>
                                            <IonCol className="value-colu">
                                              <span>
                                                {field.vehicleRestriction}
                                              </span>
                                            </IonCol>
                                          </IonRow>
                                        </div>
                                      )
                                    );
                                  } catch (e) {
                                    return (
                                      <div>
                                        Error parsing vehicle license data
                                      </div>
                                    );
                                  }
                                })()}
                              </IonCol>
                            ) : (
                              <IonCol className="center-content">
                                <div className="center-wrapper">
                                  <IonCol size="6" className="label-col">
                                    {formatFieldName(item.FieldName)}
                                  </IonCol>
                                  <IonCol size="6" className="value-col">
                                    {item.Value}
                                  </IonCol>
                                </div>
                              </IonCol>
                            )}
                          </IonRow>
                        ))}
                    </div>
                  ))}
                </IonGrid>
              ) : (
                <>
                  {" "}
                  <p style={{ textAlign: "center", fontSize: "35px" }}>
                    No record of drivers license data
                  </p>
                </>
              )}
            </div>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="buttons-container">
            <button
              style={{
                backgroundColor:
                  mode === Modes.VIDEO_CAPTURE ? "grey" : "white",
                fontSize: "45px",
                color: "white",
                paddingTop: "10px",
              }}
              onClick={handleCombinedClick}
            >
              {isCameraVisible ? "Close Camera" : "Scan Driver's License"}
            </button>
          </div>{" "}
          {isCameraVisible && (
            <div className="container">
              {mode === Modes.VIDEO_CAPTURE ? <VideoCapture /> : <></>}
            </div>
          )}
        </DesktopContainer>
      </div> */}
      {!isCameraVisible && (
        <>
          <div>
            <div className="HeaderDivider"></div>
            <IonText className="Details-title">LOGIN DETAILS</IonText>
          </div>
          <div className="login-deatils">
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol className="Label-col">
                <span>Username:</span>
              </IonCol>
              <IonCol className="Value-col">
                <span>{sessionState.user?.username}</span>
                <br />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol className="Label-col">
                <span>Password:</span>
              </IonCol>
              <IonCol className="Value-col">
                <span>*********</span>
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol className="Toggle-finger-print AllowFingerPrint">
                <span className="AllowFingerPrint">
                  {" "}
                  Allow user to login with finger print:
                </span>

                <IonToggle
                  className="toggle-finger-print"
                  checked={fingerprint}
                  onIonChange={(e) => {
                    const isChecked = e.detail.checked;
                    setFingerprint(isChecked);

                    if (isChecked) {
                      // Post message to React Native
                      if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          "checkBiometricSupport"
                        );
                      } else {
                        console.warn("ReactNativeWebView is not available");
                      }
                    }
                  }}
                />
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol className="Label-col">
                <span>Organization:</span>
              </IonCol>
              <IonCol className="Value-col">
                <span>
                  {
                    sessionState.user?.loginResponse?.organizationDetails
                      .organizationName
                  }
                </span>
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol className="Label-col">
                <span>User role description:</span>
              </IonCol>
              <IonCol className="Value-col">
                <span></span>
              </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol className="Label-col">
                <span>Region:</span>
              </IonCol>
              <IonCol className="Value-col">
                <span>
                  {
                    sessionState.user?.loginResponse?.organizationDetails
                      .address
                  }
                </span>
              </IonCol>
            </IonRow>
          </div>

          <div>
            <div className="HeaderDivider"></div>
            <IonText className="Details-title">
              DRIVER LICENSE DETAILS & NOTIFICATIONS
            </IonText>
          </div>
        </>
      )}
      <div className="ion-justify-content-center ion-align-items-center scan-container">
        <button className="Scanbutton" onClick={handleCombinedClick}>
          {isCameraVisible
            ? "CLOSE CAMERA"
            : "CLICK HERE TO SCAN DRIVER'S LICENSE"}
        </button>
        <img src={images.scanButton} alt="Scan Button" className="scan-image" />
      </div>
      {!isCameraVisible && (
        <>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol className="Toggle-finger-print AllowFingerPrint">
              <span className="AllowFingerPrint">
                {" "}
                Please send me reminder to renew license:
              </span>

              <IonToggle
                className="toggle-finger-print "
                checked={renewLicense}
                onIonChange={handleToggleChange}
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol className="Label-col">
              <span>Contact - Email Address:</span>
            </IonCol>
            <IonCol className="Value-col">
              {renewLicense ? (
                <IonInput
                  type="email"
                  value={email}
                  placeholder="Enter Email Address"
                  style={{ fontSize: "35px", borderBottom: "0.3px solid" }}
                  onIonChange={(e) => setEmail(e.detail.value || "")}
                />
              ) : null}
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol className="Label-col">
              <span>Contact - Mobile Phone:</span>
            </IonCol>
            <IonCol className="Value-col">
              {renewLicense ? (
                <IonInput
                  type="number"
                  value={mobile}
                  placeholder="Enter Mobile Number"
                  style={{ fontSize: "35px", borderBottom: "0.3px solid" }}
                  onIonChange={(e) => setMobile(e.detail.value || "")}
                />
              ) : null}
            </IonCol>
          </IonRow>
          {results.length ? (
            <>
              {results.map((result, index) => {
                const initialsObject = result.ResultInfo.find(
                  (item: { FieldName: string }) => item.FieldName === "initials"
                );
                const surnameObject = result.ResultInfo.find(
                  (item: { FieldName: string }) => item.FieldName === "surname"
                );
                const validityFromObject = result.ResultInfo.find(
                  (item: { FieldName: string }) =>
                    item.FieldName === "licenseValidityFrom"
                );
                const validityToObject = result.ResultInfo.find(
                  (item: { FieldName: string }) =>
                    item.FieldName === "licenseValidityTo"
                );

                return (
                  <div key={index} style={{ textAlign: "center" }}>
                    <IonRow className="basic-spec-row">
                      <IonCol className="Label-col">
                        <span>Initials and Surname:</span>
                      </IonCol>
                      <IonCol className="Value-col">
                        <span>
                          {initialsObject ? initialsObject.Value : ""}{" "}
                          {surnameObject ? surnameObject.Value : ""}
                        </span>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                      <IonCol className="Label-col">
                        <span>License valid from:</span>
                      </IonCol>
                      <IonCol className="Value-col">
                        <span>
                          {/* {validityFromObject ? validityFromObject.Value : ""} */}
                          {validityFromObject && validityFromObject.Value ? formatDate(validityFromObject.Value) : ""}
                        </span>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                      <IonCol className="Label-col">
                        <span>License valid until:</span>
                      </IonCol>
                      <IonCol className="Value-col">
                        <span>
                          {/* {validityToObject ? validityToObject.Value : ""} */}
                          {validityToObject && validityToObject.Value ? formatDate(validityToObject.Value) : ""}
                        </span>
                      </IonCol>
                    </IonRow>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <IonRow className="ion-justify-content-center ion-align-items-center">
                <IonCol className="Label-col">
                  <span>Initials and Surname:</span>
                </IonCol>
                <IonCol className="Value-col">
                  <span> </span>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-align-items-center">
                <IonCol className="Label-col">
                  <span>License valid from:</span>
                </IonCol>
                <IonCol className="Value-col">
                  <span></span>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-align-items-center">
                <IonCol className="Label-col">
                  <span>License valid until:</span>
                </IonCol>
                <IonCol className="Value-col">
                  <span></span>
                </IonCol>
              </IonRow>
            </>
          )}
        </>
      )}
      {isCameraVisible && (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <DesktopContainer>
            <div className="container">
              {mode === Modes.VIDEO_CAPTURE ? <VideoCapture /> : <></>}
            </div>
          </DesktopContainer>
        </div>
      )}
    </GlobalGrid>
  );
};

export default UserProfile;
