import axios, { AxiosResponse } from "axios";
import { Config } from "../Config";
import { CreateReportData } from "../interfaces/createReport";
import { ParamSearch } from "../interfaces/paramSearch";

export const promoCodes = (
  code: string,
  user: string,
  g: number,
  m: number,
  v: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/PromoCodes?promoCode=${code}&user=${user}&gCode=${g}&mCode=${m}&vCode=${v}`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const TradeRetailRate = (g: number, m: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/TradeRetailRate?gCode=${g}&mCode=${m}`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const FuelPrices = (FuelType: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/FuelPrice?type=${FuelType}`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const groupsFetcher = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/VehicleSearch/Groups`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const modelsFetcher = (gCode: string | number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/VehicleSearch/Models?gCode=${gCode}`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const modelClassFetcher = (gCode: string | number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/VehicleSearch/ModelClasses?gCode=${gCode}`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const VarientsFetcher = (gCode: number, mCode: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gCode=" + gCode + "&mCode=" + mCode;
    const url = `${Config.API_SERVER}/api/VehicleSearch/Variants?` + query;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const vinSearch = (vinNumber: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/ModelClass?vin=` + vinNumber;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const manualSearch = (searchText: string, isDesktop: boolean) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/VehicleSearch?phrase=` +
      searchText +
      "&isMobile=" +
      isDesktop;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllVehicles = (isDesktop: boolean) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/VehicleSearch?phrase=ALL&isMobile=${isDesktop}`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const dataCompleteness = (
  gcode: number,
  mcode: number,
  vcode: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gcode=" + gcode + "&mcode=" + mcode + "&vcode=" + vcode;
    const url =
      `${Config.API_SERVER}/api/DataCompleteness/DataPopulationGMV?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getWallet = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "apiKey=" + Config.API_KEY;
    const url = `${Config.API_SERVER}/api/Wallet?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const modelSpecificationDetails = (
  gcode: number,
  mcode: number,
  vcode: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gcode=" + gcode + "&mcode=" + mcode + "&vcode=" + vcode;
    const url = `${Config.API_SERVER}/api/ModelSpecificationDetails?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const modelSpecs = (gcode: number, mcode: number, vcode: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gcode=" + gcode + "&mcode=" + mcode + "&vcode=" + vcode;
    const url = `${Config.API_SERVER}/api/ModelSpecs?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const modelClass = (gcode: number, mcode: number, vcode: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gcode=" + gcode + "&mcode=" + mcode + "&vcode=" + vcode;
    const url = `${Config.API_SERVER}/api/ModelClass?` + query;

    console.log("url", url);

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const LicenseCard = (
  username: string,
  Initials: string,
  Date_of_birth: string,
  Valid_from: string,
  Valid_to: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/LicenseCard/AddLicense`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };

    const data = {
      Username: username,
      Initials: Initials,
      Date_of_birth: Date_of_birth,
      Valid_from: Valid_from,
      Valid_to: Valid_to,
    };

    axios
      .post(url, data, config)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const addFleetVehicle = (
  username: string,
  GCode: number,
  MCode: number,
  VCode: number,
  Vehicles: Array<{
    LicenseExpiry: string;
    RegNumber: string;
    VinNumber: string;
  }>
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/MobileFleet/AddVehicle`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };

    const data = {
      Username: username,
      GCode: GCode,
      MCode: MCode,
      VCode: VCode,
      Vehicles: Vehicles,
    };

    axios
      .post(url, data, config)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const getFleet = (username: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "username=" + username;
    const url = `${Config.API_SERVER}/api/MobileFleet/GetUserFleet?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteFleetVehicle = (
  username: string,
  gcode: number,
  mcode: number,
  vcode: number,
  vehicles?: Array<{
    LicenseExpiry: string;
    RegNumber: string;
    VinNumber: string;
  }> // Optional parameter
): Promise<AxiosResponse> => {
  const url = `${Config.API_SERVER}/api/MobileFleet/DeleteVehicle`;

  const data = {
    gCode: gcode,
    mCode: mcode,
    vCode: vcode,
    username: username,
    Vehicles: vehicles || [], // Defaults to an empty array if vehicles is not provided
  };

  const config = {
    headers: { apikey: Config.API_KEY },
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error("Network or server error");
      }
    });
};

export const addVehicle = (
  username: string,
  gcode: number,
  mcode: number,
  vcode: number,
  endDate: string,
  competitors: string[]
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/garage/addvehicle`;

    const data = {
      gCode: gcode,
      mCode: mcode,
      vCode: vcode,
      endDate: endDate,
      username: username,
      competitors: competitors,
    };

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const Subscription = (username: string, endDate: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/Subscription/ExtendSubscription`;

    const data = {
      username: username,
      endDate: endDate,
    };

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const garageCost = (gcode: number, mcode: number, vcode: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gcode=" + gcode + "&mcode=" + mcode + "&vcode=" + vcode;
    const url = `${Config.API_SERVER}/api/GarageCost?` + query;

    const data = {
      gCode: gcode,
      mCode: mcode,
      vCode: vcode,
    };

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteVehicle = (
  username: string,
  gcode: number,
  mcode: number,
  vcode: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/garage/deletevehicle`;

    const data = {
      gCode: gcode,
      mCode: mcode,
      vCode: vcode,
      username: username,
    };

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getGarage = (username: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "username=" + username;
    const url = `${Config.API_SERVER}/api/garage/getusergarage?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const servicePlanTotals = (
  gcode: number,
  mcode: number,
  vcode: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gCode=" + gcode + "&mCode=" + mcode + "&vCode=" + vcode;
    const url = `${Config.API_SERVER}/api/ServicePlan/Totals?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const LabourRates = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/LabourRates`;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const servicePlan = (gcode: number, mcode: number, vcode: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gCode=" + gcode + "&mCode=" + mcode + "&vCode=" + vcode;
    const url = `${Config.API_SERVER}/api/ServicePlan?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const maintenancePlan = (
  gcode: number,
  mcode: number,
  vcode: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gCode=" + gcode + "&mCode=" + mcode + "&vCode=" + vcode;
    const url = `${Config.API_SERVER}/api/MaintenancePlan?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const maintenancePlanTotals = (
  gcode: number,
  mcode: number,
  vcode: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gCode=" + gcode + "&mCode=" + mcode + "&vCode=" + vcode;
    const url = `${Config.API_SERVER}/api/MaintenancePlan/Totals?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const TCO = (
  gcode: number,
  mcode: number,
  vcode: number,
  lifeTime: number,
  tyreChangeInterval: number,
  kmPerYear: number,
  financeDeposit: number,
  financeInterest: number,
  financeTerm: number,
  financeResidual: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query =
      "gCode=" +
      gcode +
      "&mCode=" +
      mcode +
      "&vCode=" +
      vcode +
      "&lifetime=" +
      lifeTime +
      "&tyreChangeInterval=60000" +
      // tyreChangeInterval +
      "&kmPerYear=" +
      kmPerYear +
      "&financeDeposit=" +
      financeDeposit +
      "&financeInterest=" +
      financeInterest +
      "&financeTerm=" +
      financeTerm +
      "&financeResidual=" +
      financeResidual;

    const url = `${Config.API_SERVER}/api/TCO?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const BasketLayout = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "type=" + Config.TYPE_VEHICLE;
    const url = `${Config.API_SERVER}/api/BasketLayout?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const partsBaskets = (gcode: number, mcode: number, vcode: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gCode=" + gcode + "&mCode=" + mcode + "&vCode=" + vcode;
    const url = `${Config.API_SERVER}/api/BasketCollection/Top50?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const rates10KIntervals = (
  gcode: number,
  mcode: number,
  vcode: number,
  startKm: number,
  lifetime: number,
  kmPerYear: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query =
      "gCode=" +
      gcode +
      "&mCode=" +
      mcode +
      "&vCode=" +
      vcode +
      "&startKm=" +
      startKm +
      "&lifetime=" +
      lifetime +
      "&kmPerYear=" +
      kmPerYear;
    const url = `${Config.API_SERVER}/api/Rates/Intervals?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        console.log("10k rates done");
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const rates10K = (
  gcode: number,
  mcode: number,
  vcode: number,
  startKm: number,
  lifetime: number,
  kmPerYear: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query =
      "gCode=" +
      gcode +
      "&mCode=" +
      mcode +
      "&vCode=" +
      vcode +
      "&startKm=" +
      startKm +
      "&lifetime=" +
      lifetime +
      "&kmPerYear=" +
      kmPerYear;
    const url = `${Config.API_SERVER}/api/Rates?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const createTransaction = (
  userId: string,
  gcode: number,
  mcode: number,
  vcode: number,
  discountCode: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query =
      "userId=" +
      userId +
      "&gCode=" +
      gcode +
      "&mCode=" +
      mcode +
      "&vCode=" +
      vcode +
      "&discountCode=" +
      discountCode;
    const url = `${Config.API_SERVER}/api/payment/createTransaction?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCompetitors = (gcode: number, mcode: number, vcode: number) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query = "gCode=" + gcode + "&mCode=" + mcode + "&vCode=" + vcode;
    const url = `${Config.API_SERVER}/api/competitors?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getImage = (
  gcode: string,
  mcode: string,
  vcode: string
): string => {
  return (
    "https://za-api.mapo-int.com/api/image/getimage?gcode=" +
    gcode +
    "&mcode=" +
    mcode +
    "&vcode=" +
    vcode
  );
};

export const queryTransaction = (transactionId: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/payment/queryTransaction?transactionId=` +
      transactionId;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getTheme = (username: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/theme/gettheme?user=` + username;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getTerms = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/terms/getterms?solution=solutionName`;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userAllowedVehicles = (username: string, g_id: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/VehicleSearch/UserAllowedVehicles?isMobile=false&userName=` +
      username +
      "&gCode=" +
      g_id;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportGetScreens = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/support/screens?solution=sales`;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportGetCategories = (screenName: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/support/categories?solution=sales&screen=` +
      screenName;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportSubmitTicket = (
  solution: string,
  screen: string,
  category: string,
  description: string,
  gcode: string,
  mcode: string,
  vcode: string,
  userName: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/support/createticket`;

    const data = {
      solution,
      screen,
      category,
      description,
      gcode,
      mcode,
      vcode,
      userName,
    };

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportGetTickets = (username: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/support/gettickets?username=` + username;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportGetTicketReplies = (
  username: string,
  reference: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/support/getticketreplies?username=` +
      username +
      `&reference=` +
      reference;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportCloseTicket = (username: string, reference: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/support/closeticket?reference=` +
      reference +
      `&username=` +
      username;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportSubmitReply = (
  reference: string,
  username: string,
  text: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/support/reply`;
    const data = {
      username,
      reference,
      text,
    };
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const supportGetFAQ = (screen: string, category: string) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url =
      `${Config.API_SERVER}/api/support/faq?solution=sales&screen=` +
      screen +
      `&category=` +
      category;
    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDepreciation = (
  gcode: number,
  mcode: number,
  vcode: number,
  lifeTime: number
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query =
      "gcode=" +
      gcode +
      "&mcode=" +
      mcode +
      "&vcode=" +
      vcode +
      "&lifetime=" +
      lifeTime;
    const url = `${Config.API_SERVER}/api/Depreciation?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCustomCompetitors = (
  gcode: number,
  mcode: number,
  vcode: number,
  competitorsList: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const query =
      "gCode=" +
      gcode +
      "&Mcode=" +
      mcode +
      "&vCode=" +
      vcode +
      "&competitorsList=" +
      competitorsList;
    const url = `${Config.API_SERVER}/api/Competitors/Custom?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getPriceFileAge = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/PriceFile/Age`;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const VistorsParking = () => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/VisitorsParking`;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const parameterSearch = (params: ParamSearch) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const transmission =
      (params.transType ?? "") === "Automatic"
        ? "A"
        : (params.transType ?? "") === "Manual"
        ? "M"
        : "";
    const query =
      "group=" +
      (params.group ?? "") +
      "&model=" +
      (params.model ?? "") +
      "&variant=" +
      (params.variant ?? "") +
      "&variantId=" +
      (params.variantId ?? "") +
      "&modelClass=" +
      (params.modelClass ?? "") +
      "&minPrice=" +
      (params.minPrice ?? "0") +
      "&maxPrice=" +
      (params.maxPrice ?? "0") +
      "&fuelType=" +
      (params.fuelType ?? "") +
      "&transType=" +
      transmission +
      "&minKw=" +
      (params.minKw ?? "") +
      "&maxKw=" +
      (params.maxKw ?? "") +
      "&minNm=" +
      (params.minNm ?? "") +
      "&maxNm=" +
      (params.maxNm ?? "") +
      "&minDoors=" +
      (params.minDoors ?? "") +
      "&minSeats=" +
      (params.minSeats ?? "") +
      "&minDisplacement=" +
      (params.minDisplacement ?? "") +
      "&drive=" +
      (params.drive ?? "") +
      "&modelYear=" +
      (params.modelYear ?? "") +
      "&style=" +
      (params.style ?? "") +
      "&GMV=" +
      (params.GMV ?? "");
    const url = `${Config.API_SERVER}/api/ParameterSearch?` + query;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .get(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const createReport = (data: CreateReportData) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    const url = `${Config.API_SERVER}/api/report`;

    const config = {
      headers: { apikey: Config.API_KEY },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
