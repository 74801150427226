import {
  IonCol,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRow,
  IonIcon,
  IonText,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { icons, images, overViewIcons } from "../../../assets";
import DesktopContainer from "../../../components/desktop-container";
import DesktopHeader from "../../../components/desktop-header";
import Header from "../../../components/header";
import SideMenu from "../../../components/side-menu";
import SubPageHeading from "../../../components/sub-page-heading";
import { AppScreens } from "../../../enums";

import { RootState } from "../../../redux/store";
import {
  hideSideMenuDisabledItems,
  setFilteredSearchVisible,
  setScreen,
  setUndentifiedVinSearchVisible,
  showLoader,
} from "../../../redux/ui/uiSlice";
import moment from "moment";
import { getImage, modelClass } from "../../../api/mapo";
import {
  chevronBackCircleSharp,
  chevronBackOutline,
  chevronForward,
  chevronForwardCircleSharp,
  eyeSharp,
  thumbsUpSharp,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { ModelClass } from "../../../interfaces/modelClass";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../../components/globalGridMain/globalGrid";
import { sessionLogout } from "../../../redux/session/sessionSlice";

interface ModelDataType {
  engineCode: null | string;
  groupCode: number;
  introDate: string;
  make: string;
  variant: string;
}
//
const OverViewDetail: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionState = useSelector((state: RootState) => state.session);
  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);

  const onBackPress = useCallback(() => {
    history.push("/home");
  }, [dispatch, uiState]);

  const onDetailedSpecs = useCallback(() => {
    dispatch(setScreen(AppScreens.DetailSpecs));
    history.push("/DetailSpecs");
  }, [dispatch]);

  const onServiceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.ServicePlan));
    history.push("/ServicePlan");
  }, [dispatch]);

  const onMaintananceCosts = useCallback(() => {
    // dispatch(setScreen(AppScreens.MaintenancePlan));
    history.push("/MaintenancePlan");
  }, [dispatch]);

  const onTCO = useCallback(() => {
    // dispatch(setScreen(AppScreens.TotalCostOwner));
    history.push("/TotalCostOwner");
  }, [dispatch]);

  const onPartsBasket = useCallback(() => {
    // dispatch(setScreen(AppScreens.Basket));
    history.push("/Basket");
  }, [dispatch]);

  const on10KRates = useCallback(() => {
    // dispatch(setScreen(AppScreens.RatesTenK));
    history.push("/RatesTenK");
  }, [dispatch]);

  const onCompetitors = useCallback(() => {
    //dispatch(setScreen(AppScreens.Competitor));
    history.push("/Competitor");
  }, [dispatch]);

  const onReviews = useCallback(() => {
    dispatch(setScreen(AppScreens.Reviews));
  }, [dispatch]);

  const onResidualValue = useCallback(() => {
    //dispatch(setScreen(AppScreens.ResellValue));
    history.push("/ResellValue");
  }, [dispatch]);

  const onFinanceCalculator = useCallback(() => {
    //dispatch(setScreen(AppScreens.FinanceCalculate));
    history.push("/FinanceCalculate");
  }, [dispatch]);

  const onPreowned = useCallback(() => {
    dispatch(setScreen(AppScreens.Preowned));
  }, [dispatch]);

  const OnBackPress = () => {
    history.push("/VehicleLanding");
  };

  //The Date for the vehicle in modelClass
  const [ModelDate, setModelDate] = useState<ModelDataType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if uiState.selectedVehicle is defined
        if (uiState.selectedVehicle) {
          const modelClassResponse = await modelClass(
            uiState.selectedVehicle.g_id,
            uiState.selectedVehicle.m_id,
            uiState.selectedVehicle.v_id
          );

          // Check if the response status is 200
          if (modelClassResponse.status === 200) {
            const result = modelClassResponse.data.result[0]; // Assuming result is an array with at least one element

            // Access and log specific properties

            const data = modelClassResponse.data;

            // Set the fetched data to ModelDate state
            setModelDate(modelClassResponse.data.result || []);
          } else {
            console.warn("Model class response status is not 200");
          }
        } else {
          console.warn("uiState.selectedVehicle is undefined");
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [uiState.selectedVehicle, modelClass]);

  //To save the selectedVehicle in the cart
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  const showMessageWithTimeout = (text: any) => {
    setMessage(text);
    setShowMessage(true);
  };

  const handleSaveSelectedVehicleToCart = () => {
    // if (uiState.selectedVehicle) {
    //   // Retrieve existing cart data from localStorage
    //   const storedCartString = localStorage.getItem("cart");
    //   const existingCart = storedCartString ? JSON.parse(storedCartString) : [];

    //   // Check if the item with the same 'gmv' is already in the cart
    //   const isDuplicate = existingCart.some(
    //     (item: any) => item.variant === uiState.selectedVehicle?.variant
    //   );

    //   if (!isDuplicate) {
    //     // Add the current model to the cart
    //     existingCart.push(uiState.selectedVehicle);

    //     localStorage.setItem("cart", JSON.stringify(existingCart));
    //     // Show the "Item added to cart" message
    //     showMessageWithTimeout("Model added to cart");
    //   } else {
    //     // Show the "Item already in cart" message
    //     showMessageWithTimeout("Model already in cart");
    //   }
    // }
    history.push("/Cart");
  };

  const gridMenuTxt = [
    {
      name: "Detailed Specification",
      icon: overViewIcons?.detailedSpecs,
      func: onDetailedSpecs,
      desc: "Unlock comprehensive vehicle details, including engine specs, dimensions, performance figures, fuel economy, safety features, and more. Get all the information you need in one place.",
    },
    {
      name: "Competitor Analysis",
      icon: overViewIcons?.competitors,
      func: onCompetitors,
      desc: "Compare your chosen vehicle against its top competitors. See side-by-side comparisons of features, performance, pricing, and more to help you make an informed decision.",
    },
    {
      name: "Finance Calculator",
      icon: overViewIcons?.financeCalc,
      func: onFinanceCalculator,
      desc: "Estimate your monthly payments with our finance calculator. Adjust terms, interest rates, and deposit amounts to find a payment plan that suits your budget.",
    },
    {
      name: "Service Costs",
      icon: overViewIcons?.service,
      func: onServiceCosts,
      desc: "View detailed service costs for each interval. Get a clear breakdown of maintenance expenses to help you plan ahead and manage your vehicle's upkeep.",
    },
    {
      name: "Maintenance Costs",
      icon: overViewIcons?.maintenance,
      func: onMaintananceCosts,
      desc: "Access detailed costs for wear-and-tear items and maintenance outside of standard services. Plan for additional expenses to keep your vehicle in top condition.",
    },
    {
      name: "TCO",
      icon: overViewIcons?.tco,
      func: onTCO,
      desc: "Explore the full cost of owning your vehicle, including purchase price, fuel, maintenance, insurance, and depreciation. Get a complete overview to understand the long-term financial impact.",
    },
    {
      name: "Parts Basket",
      icon: overViewIcons?.parts,
      func: onPartsBasket,
      desc: "Access pricing for a basket of essential vehicle parts. Get a breakdown of costs to help you estimate maintenance and repair expenses with ease.",
    },
    {
      name: "10k Rates Table",
      icon: overViewIcons?.tenK,
      func: on10KRates,
      desc: "View the combined cost of service and maintenance at fixed 10,000km intervals. Get a clear estimate of expenses regardless of the vehicle’s prescribed service schedule.",
    },
    {
      name: "Residual Value",
      icon: overViewIcons?.residual,
      func: onResidualValue,
      desc: "Discover the projected depreciation and residual value of your vehicle over time. Understand how your car's value will hold up to make informed decisions for future resale or trade-in.",
    },
    {
      name: "Reviews",
      icon: overViewIcons?.reviews,
      func: undefined,
      desc: "Comming Soon",
    },
    {
      name: "Pre-Owned",
      icon: overViewIcons?.preOwned,
      func: undefined,
      desc: "Comming Soon",
    },
  ];

  const onLogout = useCallback(() => {
      dispatch(sessionLogout());
      //history.push("/login");
      dispatch(setFilteredSearchVisible(false));
      dispatch(setUndentifiedVinSearchVisible(false));
      setTimeout(() => {
        history.push("/login");
      }, 500);
    }, [dispatch]);

  return (
    <>
      <GlobalGrid
        currentPageTitle="OVERVIEW"
        closeCurrentPage={() => onBackPress()}
      >
        <div className="innerContent">
          <div className="headHolder HeaderStick">
            <IonRow id="selectedVehicleGlobal">
              {uiState.selectedVehicle ? (
                <>
                  <IonCol
                    sizeXs="6"
                    sizeMd="6"
                    sizeLg="6"
                    id="gridOverviewImageCont"
                  >
                    <img
                      id="gridOverviewImage"
                      src={getImage(
                        uiState.selectedVehicle.g_id.toString(),
                        uiState.selectedVehicle.m_id.toString(),
                        uiState.selectedVehicle.v_id.toString()
                      )}
                      alt=""
                    />{" "}
                  </IonCol>

                  <IonCol
                    sizeXs="6"
                    sizeMd="6"
                    sizeLg="6"
                    id="gridOverviewDetails"
                  >
                    <h1>{uiState.selectedVehicle.make}</h1>
                    <h1>{uiState.selectedVehicle.model}</h1>
                    <h1 id="gridOverviewVariant">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <>{details.variant}</>
                        ))}
                    </h1>
                    <span id="overViewCluster">
                      {Array.isArray(ModelDate) &&
                        ModelDate.map((details, index) => (
                          <h1 key={index}>
                            MY{" "}
                            {moment(new Date(details.introDate ?? "")).format(
                              "YYYY"
                            )}
                          </h1>
                        ))}
                      <h1>
                        G{uiState.selectedVehicle.g_id.toString()}M
                        {uiState.selectedVehicle.m_id.toString()}V
                        {uiState.selectedVehicle.v_id.toString()}
                      </h1>
                      {/* <h1>{uiState.selectedVehicle.variant_id}</h1>{" "} */}
                    </span>
                  </IonCol>
                </>
              ) : null}
            </IonRow>
          </div>
        </div>
        <div className="Scroll-container">
          {gridMenuTxt?.map((vals, ind) => {
            const titleStyle =
              vals?.name === "Service Costs" ||
              vals?.name === "Maintenance Costs" ||
              vals?.name === "TCO" ||
              vals?.name === "10k Rates Table" ||
              vals?.name === "Parts Basket"
                ? { fontWeight: "200" }
                : {};
            return (
              <IonCol
                id="cards"
                size="12"
                sizeXs="12"
                sizeMd="12"
                sizeLg="10"
                sizeXl="3.5"
                style={{
                  marginTop: "40px",
                }}
              >
                <div className="box" onClick={vals?.func}>
                  <div className="innerBox">
                    <div className="top">
                      <img src={vals?.icon} className="img" alt="Camera Scan" />
                    </div>
                    <IonText className="bottom">
                      <IonText className="cardTitle" style={titleStyle}>
                        {vals?.name}
                      </IonText>
                      <p className="cardDesc">{vals?.desc}</p>
                    </IonText>
                    <div className="arrows">
                      <div className="cont">
                        <IonIcon src={chevronForward}></IonIcon>
                      </div>

                      <div className="cont">
                        <span id="status">
                          <span
                            id={
                              vals?.name == "Pre-Owned"
                                ? "preowned"
                                : vals?.name == "Reviews"
                                ? "preowned"
                                : ""
                            }
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </IonCol>
            );
          })}
        </div>

        {showMessage ? (
          <div className="addToCartAlertCont">
            <div className="thumb">
              <IonIcon src={thumbsUpSharp}></IonIcon>
            </div>
            <p>{message}</p>
          </div>
        ) : null}
      </GlobalGrid>
      <div className="RenewBanner">
        {sessionState.user?.username !== "GUEST" ? (
        <div className="RenewText" onClick={handleSaveSelectedVehicleToCart}>
          RENEW SUBCRIPTION
          <span id="showVisitorIcon">
            <img className="iconRenew" src={icons.WhiteCartIcon} />
          </span>
        </div>
        ) : (
          <div className="RenewText" onClick={onLogout}>
           CREATE ACCOUNT
          </div>
        )}
      </div>
    </>
  );
};

export default OverViewDetail;
