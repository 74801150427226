import {
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { desktopImages, icons, images } from "../../assets";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import { setScreen } from "../../redux/ui/uiSlice";
import {
  chevronBackCircleOutline,
  chevronBackOutline,
  volumeHighOutline,
  logoYoutube,
  chevronBackCircleSharp,
  chevronDownCircle,
  chevronUpCircle,
  thumbsUpSharp,
} from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";
import "./style.css";
import "bootstrap/dist/css/bootstrap.css";

const About: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCircle, setShowCircle] = useState(false);
  const [show, setShow] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(3);
  const [ContactShow, setContactShow] = useState(false);
  const ContacthandleClose = () => setContactShow(false);
  const ContacthandleShow = () => setContactShow(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [addCartAlert, setAddCartAlert] = useState(false);

  const onBackPress = useCallback(() => {
    if (uiState.fromSearch) {
      dispatch(setScreen(AppScreens.SearchResult));
    } else {
      //dispatch(setScreen(AppScreens.Home));
      history.push("/home");
    }
  }, [dispatch, uiState]);

  const [cardsData, setCardsData] = useState([
    {
      image: require("../../assets/mobile/images/api.png"),
      text: "API FEED SOLUTION",
      templateId: 23,
      link: "https://sales.mapo-int.com/",
      SOFTWARESLOGAN: "Feed your system",
      TARGETAUDIENCE:
        "Any company with their own software that need vehicle or equipment information.",
      MAPOSOLUTION:
        "In today's dynamic tech landscape, the quest for accurate and comprehensive data to elevate software capabilities is paramount. \n• Are you seeking to enrich your software with precise service costs, maintenance expenditure, total cost of ownership calculations, and detailed parts information? Look no further. MAPO's API solution is your gateway to unlocking the next level of your software excellence.\n• Amidst the relentless pursuit of innovation, staying focused on your strategic game plan is crucial. We understand this need for unwavering focus. That's why our API software is designed to seamlessly integrate with your custom software, ensuring you stay aligned with your core objectives while we handle the data side of the equation.\n• Through our API solution, you'll unlock a new realm of possibilities. Say goodbye to estimations and uncertainties, and say hello to a new era of reliability, accuracy, and innovation.\n• Get hands-on with MAPO's API software today and witness the game-changing impact it can have on your software ecosystem. Join the league of software innovators who trust us to fuel their success.\n• Experience the power of MAPO today and transform your software into a pinnacle of excellence!",
      SOLUTIONFEATURERS:
        "Here are the key features of the MAPO API Data Transfer Solution:\n✔ Json format\n✔ All vehicle and equipment information available as seen in our other solution\n✔ Free API queries on MAPO structural layout information.\n✔ No user interface, data only\n✔ Secure and encrypted data transfer.\n✔ Full budget management.\n✔ Detailed statement on all queries.\nThese features collectively address the challenges faced by corporates with their own software, but require comprehensive vehicle or equipment information to enrich their system.",
      INDUSTRYPROBLEM: "This type of service has never been available.",
    },

    {
      image: require("../../assets/mobile/images/sales.png"),
      text: "SALES SOLUTION",
      templateId: 1,
      link: "https://sales.mapo-int.com/",
      SOFTWARESLOGAN: "Empower your sales force",
      MAPOSOLUTION:
        "The world evolves rapidly, demanding adaptation for success. Embrace change and secure your place in the forefront.\n\n• Elevate your sales strategies with MAPO's Sales Solution, and leave outdated methods behind.\n• The cost of clinging to traditional sales approaches, far exceed the value of transformation.\n• Just as a doctor, mechanic or engineer relies on proper tools, a successful salesperson thrives with precise information, not only of their products, but competing products as well. MAPO provide a unique solution empowering sales teams with comprehensive comparisons of various product insights.\n• Experience our cutting-edge solution that not only delivers model specifications but also provides up-to-date service costs, maintenance expenses, total cost of ownership, and much more. This leading-edge tool, enriched with essential information, is easily accessible with just a few clicks of the mouse or in the palm of your hand.\n• Evolve, and enhance your marketing, sales and customer procurement processes. \n• Unlock the power of your brand and experience MAPO today!",
      TARGETAUDIENCE:
        "Dealership and Sales agents. (New and Pre-owned sales persons)",
      INDUSTRYPROBLEM:
        "• Salespeople are trained primarily on their own products and lack information about competitors.\n• Sales targets are continually raised, but salespeople lack improved tools at their disposal.\n• Clients demand more comprehensive information than just model specifications to make informed decisions.\n• Customers who enter a dealership have often conducted extensive research on models within their price range. Salespeople frequently find themselves unprepared, lacking the necessary information for meaningful conversations with clients.\n• When a customer walks into a dealership with a specific model in mind, salespeople lack the tools to validate the client's choice or recommend potential upselling opportunities.",
      SOLUTIONFEATURERS:
        "Here are the key features of the MAPO SALES Solution:\n\n✔ Model search\n✔ Competitor Analysis\n✔ Finance Calculator\n✔ Service Cost\n✔ Maintenance Costs\n✔ TCO\n✔ 10K Rates Table\n✔ Residual Value\n✔ Detailed report print and email options.\n✔ Full customization of reports and comparisons.\n✔ Usage tracking\n✔ These features collectively address the challenges faced by Dealers & Agents, offering a comprehensive solution that includes much more then just a brochure of your products.\n\nThese features collectively address the challenges faced by Dealers & Agents, offering a comprehensive solution that includes much more then just a brochure of your products.",
    },

    {
      image: require("../../assets/mobile/images/oem.png"),
      text: "OEM SOLUTION",
      templateId: 22,
      link: "https://sales.mapo-int.com/",
      SOFTWARESLOGAN: "Know your strengths and weaknesses",
      TARGETAUDIENCE:
        "Original Vehicle and Equipment Manufacturers.(Toyota, VW, Mazda, Iveco, Scania, Volvo etc.)",
      INDUSTRYPROBLEM:
        "• Original Equipment Manufacturers (OEMs) seeking pricing benchmarking insights, rely on market research to make informed parts pricing decisions. \n• Gathering and analyzing the immense data for accurate reports traditionally takes months, leading to slow, costly, and labor-intensive processes.\n• Reports should swiftly offer precise information at lower costs.",
      MAPOSOLUTION:
        "We understand the challenges faced by Original Equipment Manufacturers in staying ahead of the curve when it comes to market research.\n\n• In a time where accuracy and efficiency take center stage, we know that carefully strategizing the service costs, maintenance expenses, total cost of ownership, and parts pricing for your products are crucial.\n• Traditional market analysis methods fall short in delivering timely and accurate insights, due to the immense amount of data that needs to be processed.\n• MAPO takes on this mammoth task, and deliver unprecedented productivity.\n•We aim to unleash the potential of your brand by establishing goals, meticulous planning, forecasting impacts, and suggesting swift implementable price adjustments.\n• A goal without a plan is merely a dream....... Let's identify your market position and establish clear goals.\n• We then plan pricing strategically, and forecast the impact of adjustments.\n• It's time to take action, stay ahead of the game and empower your decisions with informed, data-driven approaches.\n• Elevate your brand and experience the power of MAPO today!",
      SOLUTIONFEATURERS:
        "Here are the key features of the MAPO OEM Solution:\n\n ✔ Research and benchmark Concept, Current & Discontinued Models.\n✔ TCO – Total Cost of Ownership calculations in seconds.\n✔ Meticulous planning of maintenance expenses\n✔ Parts price benchmarking and tracking\n✔ Dynamic pricing, real-time price adjustments, forecasting impacts.\n✔ Meticulous planning of service schedules and costs\n✔ Competitor analysis\n✔ Monitor and benchmark labour cost, fluid quantities.\n✔ Calculate part price elasticity\n✔ Monitor market trend.\n✔ Aftermarket competitors and position.\n✔ Monitor sales volumes\n✔ Track part price fluctuations\n\nThese features collectively address the challenges faced by OEMs (Original Equipment Manufacturers), offering a comprehensive solution that plan pricing strategically, and forecast the impact of adjustments.",
    },
    {
      image: require("../../assets/mobile/images/app.png"),
      text: "MOBILE SOLUTION",
      templateId: 20,
      SOFTWARESLOGAN: "Make the informed decision",
      TARGETAUDIENCE: "Public (Any person planning to purchase a vehicle)",
      INDUSTRYPROBLEM:
        "Other apps provide basic model listing and specifications.",
      MAPOSOLUTION:
        "I was dreaming of purchasing my perfect car.\n\n• Researching models and specifications online was easy but I needed more...... I needed to know.....\n• How much it would cost to service the vehicle.What would the maintenance expenditure calculate to with the mileage I do per year?\n• How will the model's value depreciate. \n• In all, I needed to know the Total Cost of ownership of my dream car.\n• Then I found the MAPO mobile app! I quickly said goodbye to these uncertainties and turned my dream in to reality!\n• Filtering thousands of models and specification, I easily discovered the one that perfectly matched my needs. Effortlessly accessed precise and up-to-date service costs, maintenance expenses, total cost of ownership, and parts pricing — all conveniently from my mobile device.\n• My advise to you,.... Stay informed, plan effectively, and approach your next vehicle purchase with confidence.\n• Your dream car is closer than you think, get the MAPO mobile app now.",
      link: "https://mobile.mapo-int.com/",
      TextFeature: "App Feature (1) -",
      TextFeatureTwo: "App Feature (2) -",
      Commercial: "Public Commercial",
      ValueOfCar: "What is the current value of my car?",
      ExtendPlan: "Should buy extended plan?",
      AppFeature: "https://www.youtube.com/watch?v=l-ZWymIqnuM",
      AppFeatureTwo: "https://www.youtube.com/watch?v=oCtawEdX93k",
      YouTubeLink: "https://www.youtube.com/watch?v=kZyboi_vQZY",
      SOLUTIONFEATURERS:
        "Here are the key features of the MAPO MOBILE Application:\n\n✔ Model search by VIN scan, filtering options with voice recognition.\n✔ Detailed specifications\n✔ Competitor Analysis\n✔ Finance Calculator\n✔ Detailed, accurate and up-to-date Service Cost\n✔ Detailed, accurate and up-to-date Maintenance Costs\n✔ TCO calculator completely customizable to your requirements.\n✔ Accurate and up-to-date Parts prices\n✔ 10K Rates Table \n✔ Residual Value by variant, Model and Group.\n✔ Full customization of all calculations.\n\nThese features collectively address the challenges faced by the consumer, offering a comprehensive solution that educate and inform the user to make informed decisions on researching for the perfect vehicle.",
    },
    {
      image: require("../../assets/mobile/images/fleet.png"),
      text: "FLEET SOLUTION",
      templateId: 0,
      link: "https://sales.mapo-int.com/",
      SOFTWARESLOGAN: "Manage and plan your fleet.",
      TARGETAUDIENCE: "Fleet Owners or Fleet Management Companies.",
      INDUSTRYPROBLEM:
        "\n•  Fleet Owners or Fleet Management Companies reply on estimations due to the required information not being available.\n• Now is the time fleet owners and fleet management companies require accurate and up-to-date information.",
      MAPOSOLUTIONImage: require("../../assets/mobile/images/Underconstruction.png"),
      MAPOSOLUTION: "coming soon",
      SOLUTIONFEATURERS: "",
    },
    {
      image: require("../../assets/mobile/images/insurance.png"),
      text: "INSURE SOLUTION",
      templateId: 24,
      link: "https://sales.mapo-int.com/",
      SOFTWARESLOGAN: "Insure to make the accurate decision",
      MAPOSOLUTION:
        "In the fast-paced world of insurance, every second counts. But outdated processes and guesswork can slow you down.\n• Estimations, delays, uncertainty... It's a nightmare!\n• Introducing MAPO Insurance Solution – the game-changer for insurance companies.\n• With MAPO, analyzing claims takes milliseconds, not weeks. Our cutting-edge technology accurately identifies models, verifies OEM parts, and ensures the perfect fit – all at the speed of light.\n• Say goodbye to costly errors and hello to precision and speed. With MAPO, insurance companies can confidently finalize claims faster than ever before.\n• Get ahead in the insurance game with MAPO Insurance Solution. Unlock the power of instant, accurate data at your fingertips.\n• MAPO Insurance Solution: Where Speed Meets Precision.\n• Experience the future of insurance today. Visit MAPO.com to learn more.",
      TARGETAUDIENCE:
        "Insurance Companies. (Analyzing and processing accident claims)",
      INDUSTRYPROBLEM:
        "• Fast and accurate Collision parts basket info is crucial from planning stage monitoring and claims finalization stage.\n•  Insurance companies reply on estimations due to the required information not being available.\n• The processing on claims took weeks to finalize.",
      SOLUTIONFEATURERS:
        "Here are the key features of the MAPO Insurance Solution:\n\n✔ Instant Claim Analysis: MAPO analyzes claims in milliseconds, reducing processing time from weeks to moments.\n✔ Accurate Model Identification: The software accurately identifies vehicle models, eliminating guesswork anAccurate Model Identification: The software accurately identifies vehicle models, eliminating guesswork and ensuring precision in claims processing.d ensuring precision in claims processing.\n✔ OEM Part Verification: MAPO verifies that the parts used are genuine OEM (Original Equipment Manufacturer) parts, enhancing the quality and reliability of repairs.\n✔ Part Fit Verification: It ensures that the selected parts fit the specific vehicle model, reducing errors and avoiding delays in repairs.\n✔ Price Verification: MAPO verifies that the prices of parts are in line with OEM recommendations, ensuring fair and transparent cost assessments.\n\nThese features collectively address the challenges faced by insurance companies, offering a comprehensive solution for faster, more accurate claims processing.",
    },
  ]);

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const isNearEnd =
          container.scrollLeft + container.clientWidth >=
          container.scrollWidth - 5;
        if (isNearEnd) {
          // Reset scroll position to the beginning
          container.scrollLeft = 6;
          // Append new cards without duplicating existing ones
          setCardsData((prevData) => [...prevData, ...prevData]);
        }
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedCardIndex !== -4 && scrollContainerRef.current) {
      const selectedCardElement =
        scrollContainerRef.current.children[selectedCardIndex];
      if (selectedCardElement) {
        selectedCardElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [selectedCardIndex]);

  const handleCardClick = (index: number) => {
    setSelectedCardIndex(index === selectedCardIndex ? -6 : index);
  };
  const [mailerState, setMailerState] = useState({
    email: "",
  });

  function handleStateChange(e: any) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    localStorage.setItem("UserEmail", mailerState.email);
  }

  const submitEmail = async (e: any) => {
    e.preventDefault();
    let data = JSON.stringify({
      to: [
        {
          email: mailerState.email,
          name: "MAPO International",
        },
      ],
      templateId: cardsData[selectedCardIndex].templateId,
      headers: {
        "X-Mailin-custom":
          "custom_header_1:custom_value_1|custom_header_2:custom_value_2|custom_header_3:custom_value_3",
        charset: "iso-8859-1",
      },
    });
    console.log({ mailerState });
    const response = await fetch("https://api.brevo.com/v3/smtp/email", {
      method: "post",
      headers: {
        "api-key":
          "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",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((response) =>
        response.json().then((res) => ({ status: response.status, data: res }))
      )
      .then((apiResponse) => {
        console.log("e 1", apiResponse);
        if (apiResponse.status === 201) {
          ContacthandleShow();
          setTimeout(() => {
            setAddCartMsg("EMAIL SENT TO YOUR INBOX");
            setAddCartAlert(true);
            // Set another timeout after the first one has completed
            setTimeout(() => {
              setAddCartAlert(false);
            }, 3000);
          }, 500);
        } else if (apiResponse.status === 400) {
          handleShow();
          setTimeout(() => {
            setAddCartMsg("EMAIL WAS NOT SENT");
            setAddCartAlert(true);
            // Set another timeout after the first one has completed
            setTimeout(() => {
              setAddCartAlert(false);
            }, 3000);
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Error: 2", error);
      })
      .then(() => {
        setMailerState({
          email: "",
        });
      });
  };

  const makeSolutionBold = (text: any) => {
    return text.replace(/solution/gi, '<b className="slogan-fonts"></b>');
  };

  return (
    <>
      <IonPage className="pageBg">
        <Header></Header>

        <IonGrid>
          <IonRow className="informedDecisionAbout">
            <IonCol size="12">
              <small className="WebsiteText">WWW.MAPO-INT.COM</small>
            </IonCol>
            <IonCol size="12">
              {/* <h1 id="header">MAKE THE INFORMED DECISON</h1> */}
              <strong className="HeaderTxt">MAKE THE INFORMED DECISIONS</strong>
            </IonCol>
            <br />

            <IonCol size="12">
              <p>
                {" "}
                MAPO offers subscribers and application users access to accurate
                Model Identification and data in Parts Pricing, Service Costs,
                Maintenance Costs and Total Cost of Ownership, Residual Values
                and much, much more.
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonContent className="contentPag">
          <div className="topMapoSolutions">
            <span className="detailsDropDownAbout">
              <p>More About us....</p>
              <IonIcon
                onClick={() => {
                  setShowCircle(!showCircle);
                }}
                id="icon"
                icon={showCircle == false ? chevronDownCircle : chevronUpCircle}
              ></IonIcon>
            </span>
            {showCircle && (
              <span className="garageDropDown preDetails">
                <IonCol>
                  We offer services that not only address and fix Industry
                  requirements, but provides cost saving through accurate asset
                  identification, streamlines internal processes and ensures
                  that OEM’s are accurately represented in the marketplace,
                  these innovative solutions benefit the OEM, Insurance, Fleet
                  and Consumer as the end user in the value chain.{" "}
                </IonCol>
              </span>
            )}
            <div
              className="horizontal-scroll-container"
              ref={scrollContainerRef}
            >
              {/* <div className="blur"></div> */}
              <div className="horizontal-scroll">
                {cardsData.map((card, index) => (
                  <div
                    key={index}
                    className={`cardsss ${
                      index === selectedCardIndex ? "expanded" : ""
                    }`}
                    onClick={() => handleCardClick(index)}
                  >
                    <div className="circle">
                      <img src={card.image} alt={`Card ${index}`} />
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="blurRight"></div> */}
            </div>
          </div>
          {selectedCardIndex !== -1 && cardsData[selectedCardIndex] && (
            // <div className="mid">
            //   <span className="SolutionName">
            //     {" "}
            //     {cardsData[selectedCardIndex].text}{" "}
            //     <img className="solutionSpeaker" src={icons.blueSpeaker} />
            //   </span>
            //   <p className="SolutionSlogan">
            //     {cardsData[selectedCardIndex].SOFTWARESLOGAN}
            //   </p>

            // </div>
            <div className="mid row align-items-start">
              {/* <div className="col-auto no-padding">
                <img
                  alt="blog"
                  src={cardsData[selectedCardIndex].image}
                  className="solution-icons"
                />
              </div> */}
              <div className="col no-padding">
                <div className="text-container">
                  <span className="red-lines">c</span>
                  <h1 className="title-h p-relative">
                    <span className="slogan-font SolutionNames">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: makeSolutionBold(
                            cardsData[selectedCardIndex].text
                          ),
                        }}
                      />
                    </span>
                    <strong className="Solution-name">SOLUTION</strong>
                    <br />
                    <span className="olutionSlogan">
                      {cardsData[selectedCardIndex].SOFTWARESLOGAN}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          )}

          <div className="botMapoSolutions">
            <div className="selected-card-solution">
              {selectedCardIndex !== -1 && cardsData[selectedCardIndex] && (
                <div className="solutionDetailsCont">
                  <span className="SolutionName">TARGET AUDIENCE:</span>
                  <p className="SolutionSlogan">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cardsData[selectedCardIndex].TARGETAUDIENCE,
                      }}
                    />
                  </p>
                  <br />
                  {/**The form */}

                  {/* <span className="get-more-information">
                    <span className="red-line">c</span>
                    <h1 className="title-h p-relative">
                      <span className="fw-200">
                        <b>
                          <span className="slogan-font">GET MORE</span>{" "}
                          INFORMATION
                        </b>
                        
                      </span>{" "}
                      <p className="SolutionSlogan">
                    <div>
                      <p>Enter your email.We'll send you all the details you need</p>
                    </div>
                  </p>
                    </h1>
                    
                  </span> */}
                  <br />
                  <span className="SolutionName">GET MORE INFORMATION:</span>
                  <p className="SolutionSlogan">
                    Enter your email.We'll send you all the details you need
                  </p>

                  <span className="SolutionName">
                    <form action="" onSubmit={submitEmail}>
                      {/* <label className="SolutionName">Email Address</label> */}

                      <input
                        className="SolutionName input-email"
                        type="text"
                        placeholder="Your Email Address"
                        onChange={handleStateChange}
                        name="email"
                        value={mailerState.email}
                        required
                      />
                    </form>{" "}
                    <div className="button-container">
                      <input
                        onClick={submitEmail}
                        className="submit-btn"
                        type="submit"
                        value="Submit"
                      />
                    </div>
                  </span>

                  <br />
                  <span className="SolutionName">INDUSTRY PROBLEM:</span>
                  <p className="SolutionSlogan">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cardsData[
                          selectedCardIndex
                        ].INDUSTRYPROBLEM.replace(/\n/g, "<br>"),
                      }}
                    />
                  </p>
                  <br />
                  <span className="SolutionName">COMMERCIAL ADVERT:</span>
                  <p className="SolutionSlogan">
                    YouTube Ad -{" "}
                    <a
                      href={cardsData[selectedCardIndex]?.YouTubeLink || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{
                        __html: cardsData[selectedCardIndex]?.Commercial || "",
                      }}
                    />
                  </p>
                  <p className="SolutionSlogan">
                    App Feature (1) -{" "}
                    <a
                      href={cardsData[selectedCardIndex]?.AppFeature || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{
                        __html: cardsData[selectedCardIndex]?.ValueOfCar || "",
                      }}
                    />
                  </p>
                  <p className="SolutionSlogan">
                    App Feature (2) -{" "}
                    <a
                      href={cardsData[selectedCardIndex]?.AppFeatureTwo || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{
                        __html: cardsData[selectedCardIndex]?.ExtendPlan || "",
                      }}
                    />{" "}
                  </p>
                  <br />
                  <span className="SolutionName">MAPO SOLUTION:</span>
                  <p className="SolutionSlogan">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cardsData[
                          selectedCardIndex
                        ].MAPOSOLUTION.replace(/\n/g, "<br>"),
                      }}
                    />
                  </p>
                  <br />
                  <span className="SolutionName">SOLUTION FEATURES</span>
                  <p className="SolutionSlogan">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cardsData[
                          selectedCardIndex
                        ].SOLUTIONFEATURERS.replace(/\n/g, "<br>"),
                      }}
                    />
                  </p>
                  <br />
                  {/* <span className="SolutionName">CONTACT THE MAPO TEAM</span> */}
                  <p className="SolutionSlogan"></p>
                </div>
              )}
            </div>
          </div>
          {addCartAlert && (
            <div className="addToCartAlertCont">
              <div className="thumb">
                <IonIcon src={thumbsUpSharp}></IonIcon>
              </div>
              <p>{addCartMsg}</p>
            </div>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default About;
