import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import Button from "../../components/button";
import "../../Global.css";
import { useDispatch } from "react-redux";
import {
  sessionLogin,
  setBranded,
  setExpiryDate,
  setSelectedOem,
  setTermsAccepted,
  setTheme,
} from "../../redux/session/sessionSlice";
import {
  hideLoader,
  setFileAgeData,
  setLabourRatesData,
  setScreen,
  showLoader,
} from "../../redux/ui/uiSlice";
import { AppScreens } from "../../enums";
import "./style.css";
import Link from "../../components/link";
import { acceptTerms, authUser } from "../../api/auth";
import { User } from "../../interfaces";
import TermsAndCondition from "../../modals/terms-and-conditions";
import { LabourRates, getPriceFileAge, getTheme } from "../../api/mapo";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { closeCircle, fingerPrint, imagesOutline } from "ionicons/icons";
import { icons, images } from "../../assets";
import { useHistory } from "react-router-dom";
import { eye, eyeOff } from "ionicons/icons";
import { useMediaQuery } from "react-responsive";
import React from "react";

interface LoginFormProps {
  onSignUpMode: () => void;
  onForgotPasswordMode: () => void;
}

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSignUpMode,
  onForgotPasswordMode,
}: LoginFormProps) => {
  const uiState = useSelector((state: RootState) => state.ui);
  const [termsVisible, setTermsVisible] = useState(false);
  const [authMessage, setAuthMessage] = useState<string>("");
  const [user, setUser] = useState<User>({
    // username: 'erhard@domain.com',
    // password: 'P@ssw0rd123',
    username: "",
    password: "",
  });

  const [userFinger, setUserFinger] = useState<User>({
    username: localStorage.getItem("user_name"),
    password: localStorage.getItem("authToken") || "",
  });

  const [errorMessage, setErrorMessage] = useState<string>("");
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [fingerprint, setFingerprint] = useState(false);

  const navigateToHome = () => {
    history.push("/home");
  };
  const [showEmma, setShowEmma] = useState(false);

  const dispatch = useDispatch();
  const [showVideo, setShowVideo] = useState(false);

  const toggleButton = () => {
    setShowVideo(!showVideo);
  };

  const handleVideoEnd = () => {
    // alert('Video has ended');
    setShowVideo(!showVideo);
    setShowOffering(true);
  };

  // Handle password change and save to localStorage
  const handlePasswordChange = (ev: any) => {
    const newPassword = ev.target.value;
    setUser((prevUser) => {
      const updatedUser = { ...prevUser, password: newPassword };
      localStorage.setItem("authToken", newPassword);
      return updatedUser;
    });
  };

  useEffect(() => {
    //check if fingerprint is enabled
    const savedFingerprint = JSON.parse(
      localStorage.getItem("fingerprintToggle") || "false"
    );
    if (savedFingerprint !== null) {
      setFingerprint(savedFingerprint);
    }

    const handleMessage = (event: any) => {
      const message = event.data;

      if (message === "Fingerprint-Success") {
        setAuthMessage(message);
        onFingerPrint();
      } else if (message === "Fingerprint-Failed") {
        // alert("Fingerprint authentication failed.");
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const onFingerPrint = useCallback(() => {
    dispatch(showLoader());
    authUser(userFinger)
      .then((regResponse) => {
        if (regResponse.status === 200) {
          LabourRates()
            .then((LabourRatesResponse) => {
              dispatch(setLabourRatesData(LabourRatesResponse.data?.result));
              getPriceFileAge()
                .then((priceAgeResponse) => {
                  dispatch(setFileAgeData(priceAgeResponse.data));
                  getTheme(user.username ?? "")
                    .then((themeResponse) => {
                      dispatch(hideLoader());
                      const newUser: User = {
                        ...userFinger,
                        loginResponse: regResponse.data,
                      };
                      if (
                        newUser?.loginResponse?.oems &&
                        newUser?.loginResponse?.oems?.length > 0
                      ) {
                        dispatch(
                          setSelectedOem(newUser?.loginResponse?.oems[0])
                        );
                        dispatch(
                          setExpiryDate(newUser?.loginResponse.userExpiryDate)
                        );
                      }
                      dispatch(sessionLogin(newUser));
                      dispatch(setTheme(themeResponse.data));
                      dispatch(setBranded(true));

                      if (
                        newUser.loginResponse?.message?.indexOf(
                          "Terms and conditions need to be accepted"
                        ) !== -1
                      ) {
                        if (uiState.isDesktop) {
                          dispatch(setTermsAccepted(false));
                          //dispatch(setScreen(AppScreens.Welcome));
                          history.push("/home");
                        } else {
                          setTermsVisible(true);
                        }
                      } else {
                        dispatch(setTermsAccepted(true));
                        //dispatch(setScreen(AppScreens.Home));
                        history.push("/home");
                      }
                    })
                    .catch((err) => {
                      dispatch(hideLoader());
                      console.log(err);
                      alert(JSON.stringify(err));
                    });
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  console.log(err);
                  alert(JSON.stringify(err));
                });
            })
            .catch((err) => {
              dispatch(hideLoader());
              console.log(err);
              alert(JSON.stringify(err));
            });
        } else {
          alert(regResponse.statusText);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
        // alert(JSON.stringify(err));
        setErrorMessage("Please enter valid username and Password.");
        setTimeout(() => {
          setErrorMessage("");
        }, 9000);
      });
  }, [dispatch, setTermsVisible, user, uiState.isDesktop]);

  const onLoginPress = useCallback(() => {
    dispatch(showLoader());
    authUser(user)
      .then((regResponse) => {
        if (regResponse.status === 200) {
          LabourRates()
            .then((LabourRatesResponse) => {
              dispatch(setLabourRatesData(LabourRatesResponse.data?.result));
              getPriceFileAge()
                .then((priceAgeResponse) => {
                  dispatch(setFileAgeData(priceAgeResponse.data));
                  getTheme(user.username ?? "")
                    .then((themeResponse) => {
                      dispatch(hideLoader());
                      const newUser: User = {
                        ...user,
                        loginResponse: regResponse.data,
                      };
                      if (
                        newUser?.loginResponse?.oems &&
                        newUser?.loginResponse?.oems?.length > 0
                      ) {
                        dispatch(
                          setSelectedOem(newUser?.loginResponse?.oems[0])
                        );
                        dispatch(
                          setExpiryDate(newUser?.loginResponse.userExpiryDate)
                        );
                      }
                      dispatch(sessionLogin(newUser));
                      dispatch(setTheme(themeResponse.data));
                      dispatch(setBranded(true));

                      if (
                        newUser.loginResponse?.message?.indexOf(
                          "Terms and conditions need to be accepted"
                        ) !== -1
                      ) {
                        if (uiState.isDesktop) {
                          dispatch(setTermsAccepted(false));
                          //dispatch(setScreen(AppScreens.Welcome));
                          history.push("/home");
                        } else {
                          setTermsVisible(true);
                        }
                      } else {
                        dispatch(setTermsAccepted(true));
                        //dispatch(setScreen(AppScreens.Home));
                        history.push("/home");
                      }
                    })
                    .catch((err) => {
                      dispatch(hideLoader());
                      console.log(err);
                      alert(JSON.stringify(err));
                    });
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  console.log(err);
                  alert(JSON.stringify(err));
                });
            })
            .catch((err) => {
              dispatch(hideLoader());
              console.log(err);
              alert(JSON.stringify(err));
            });
        } else {
          alert(regResponse.statusText);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
        // alert(JSON.stringify(err));
        setErrorMessage("Please enter valid username and Password.");
        setTimeout(() => {
          setErrorMessage("");
        }, 9000);
      });
  }, [dispatch, setTermsVisible, user, uiState.isDesktop]);

  const onAcceptTerms = useCallback(() => {
    setTermsVisible(false);
    dispatch(showLoader());
    acceptTerms(user)
      .then((regResponse) => {
        dispatch(hideLoader());
        if (regResponse.status === 200) {
          dispatch(setTermsAccepted(true));
          dispatch(setScreen(AppScreens.Home));
        } else {
          alert(JSON.stringify(regResponse));
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (err.status === 400) {
          alert("err");
        } else {
          console.log(err);
          alert(JSON.stringify(err));
        }
      });
  }, [dispatch, user]);

  const onCloseTerms = useCallback(() => {
    setTermsVisible(false);
  }, []);

  const onGuestPress = useCallback(() => {
    const guest: User = {
      username: "GUEST",
    };
    dispatch(sessionLogin(guest));
    dispatch(setBranded(true));
    history.push("/home");
  }, [dispatch]);

  const [showOffering, setShowOffering] = useState(false);
  const [hideVideo, setHideVideo] = useState(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const callFingerPrint = () => {
    window.ReactNativeWebView.postMessage("biometricAuth");
  };
  return (
    <>
      <IonGrid className="loginPageCont">
        {showOffering && hideVideo && (
          <div
            className="emmaVideoContainer"
            onClick={() => setShowEmma(false)}
          >
            <video
              onEnded={() => setShowEmma(false)}
              autoPlay
              className="VideoFrame"
              onClick={() => setShowEmma(false)}
            >
              <source
                src={require("../Login/APP-Introduction.mp4")}
                type="video/mp4"
              />
            </video>
          </div>
        )}

        <IonRow>
          <IonCol size="12" id="speakerCont">
            <img
              onClick={() => {
                setShowOffering(!showOffering);
              }}
              src={icons.blueSpeaker}
              className="Speaker-Icon"
            />
          </IonCol>
          <IonCol size="12" className="guestOption">
            <IonCard className="guest-card">
              <IonCol size="12" className="guest-btn">
                <button title="Continue as Guest">Guest User</button>
              </IonCol>

              <IonCol size="12" className="guest-txt">
                You will have limited acces to model's data , but you can always
                signup later. Enjoy!
              </IonCol>

              <IonLabel className="label">
                <button onClick={onGuestPress} title="Continue">
                  Continue
                </button>
              </IonLabel>
            </IonCard>

            <IonCard className="guest-card">
              <IonCol size="12" className="guest-btn">
                <button title="Sign In">Registered User</button>
              </IonCol>

              <IonCol size="12" className="formLabels">
                <IonLabel className="label">
                  Your username
                  <IonInput
                    className="input"
                    placeholder="Enter your email address"
                    value={user.username}
                    onIonChange={(ev: any) =>
                      setUser({ ...user, username: ev.target.value })
                    }
                  ></IonInput>
                </IonLabel>
                <IonLabel className="label">
                  Password
                  <span id="labelWithEye">
                    <IonInput
                      className="input"
                      placeholder="Fill your password"
                      type={showPassword ? "text" : "password"}
                      value={user.password}
                      // onIonChange={(ev: any) =>
                      //   setUser({ ...user, password: ev.target.value })
                      // }
                      onIonChange={handlePasswordChange}
                    >
                      <IonImg
                        className="eye-icon"
                        src={showPassword ? eye : eyeOff}
                        onClick={toggleShowPassword}
                      />
                    </IonInput>
                  </span>
                </IonLabel>

                <IonRow>
                  <IonCol size="12">
                    <IonText style={{ color: "red" }} className="login-font">
                      {errorMessage}
                    </IonText>
                  </IonCol>
                </IonRow>

                <IonCol className="password">
                  <IonText
                    style={{
                      color: "",
                      padding: "10px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={onForgotPasswordMode}
                  >
                    {" "}
                    Forgot Password
                  </IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel className="label">
                    <button onClick={onLoginPress} title="Sign In">
                      Sign In
                    </button>
                  </IonLabel>
                  {fingerprint && (
                    <IonIcon
                      className="finger-print"
                      onClick={callFingerPrint}
                      src={fingerPrint}
                      size="44"
                    />
                  )}
                </IonCol>
                <br />

                <IonCol size="12" onClick={onSignUpMode} className="noAccount">
                  Don't have a account yet?{" "}
                  <strong onClick={onSignUpMode} style={{ color: "black" }}>
                    SIGN UP
                  </strong>
                </IonCol>
              </IonCol>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default LoginForm;
