import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { images, overViewIcons } from "../../assets";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import {
  hideSideMenuDisabledItems,
  setCompetitors,
  setCustomReportParams,
  setScreen,
  setSelectedVehicle,
  showLoader,
} from "../../redux/ui/uiSlice";
import "./style.css";
import { chevronForward, chevronForwardCircleSharp } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { cancelGetAllData, getAllSelectedData } from "../../api/getAll";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

const VehicleDetail: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionState = useSelector((state: RootState) => state.session);

  const onBackPress = useCallback(() => {
    cancelGetAllData();
    setSelectedVehicle(null);
    // setCustomReportParams(undefined)

    if (uiState.fromSearch) {
      //dispatch(setScreen(AppScreens.SearchResult));
      history.push("/Garage");
    } else {
      //dispatch(setScreen(AppScreens.Garage));
      history.push("/Garage");
    }
  }, [dispatch, uiState]);

  const onDetailedSpecs = useCallback(() => {
    //dispatch(setScreen(AppScreens.Specs));
    history.push("/Specs");
  }, [dispatch]);

  const onServiceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Services));
    history.push("/Services");
  }, [dispatch]);

  const onMaintananceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Maintenance));
    history.push("/Maintenance");
  }, [dispatch]);

  const onTCO = useCallback(() => {
    //dispatch(setScreen(AppScreens.TCO));
    history.push("/TCO");
  }, [dispatch]);

  const onPartsBasket = useCallback(() => {
    //dispatch(setScreen(AppScreens.Parts));
    history.push("/Parts");
  }, [dispatch]);

  const on10KRates = useCallback(() => {
    //dispatch(setScreen(AppScreens.Rates10K));
    history.push("/Rates10K");
  }, [dispatch]);

  const onCompetitors = useCallback(() => {
    //dispatch(setScreen(AppScreens.Competitors));
    history.push("/Competitors");
  }, [dispatch]);

  const onReviews = useCallback(() => {
    // dispatch(setScreen(AppScreens.Reviews));
    history.push("/Reviews");
  }, [dispatch]);

  const onResidualValue = useCallback(() => {
    //dispatch(setScreen(AppScreens.ResidualValue));
    history.push("/ResidualValue");
  }, [dispatch]);

  const onFinanceCalculator = useCallback(() => {
    //dispatch(setScreen(AppScreens.FinanceCalculator));
    history.push("/FinanceCalculator");
  }, [dispatch]);

  const onPreowned = useCallback(() => {
    //dispatch(setScreen(AppScreens.Preowned));
    history.push("/Preowned");
  }, [dispatch]);

  const menuLoadText = [
    "SERVICE SCHEDULES",
    "MAINTENANCE SCHEDULES",
    "TCO INFORMATION",
    "DETAILED SPECIFICATIONS",
    "PARTS BASKET",
    "10K RATES",
    "RESIDUAL VALUE",
  ];

  const gridMenuTxt = [
    {
      name: "Detailed Specification",
      icon: overViewIcons?.detailedSpecs,
      func: onDetailedSpecs,
      desc: "Unlock comprehensive vehicle details, including engine specs, dimensions, performance figures, fuel economy, safety features, and more. Get all the information you need in one place.",
    },
    {
      name: "Competitor Analysis",
      icon: overViewIcons?.competitors,
      func: onCompetitors,
      desc: "Compare your chosen vehicle against its top competitors. See side-by-side comparisons of features, performance, pricing, and more to help you make an informed decision.",
    },
    {
      name: "Finance Calculator",
      icon: overViewIcons?.financeCalc,
      func: onFinanceCalculator,
      desc: "Estimate your monthly payments with our finance calculator. Adjust terms, interest rates, and deposit amounts to find a payment plan that suits your budget.",
    },
    {
      name: "Service Costs",
      icon: overViewIcons?.service,
      func: onServiceCosts,
      desc: "View detailed service costs for each interval. Get a clear breakdown of maintenance expenses to help you plan ahead and manage your vehicle's upkeep.",
    },
    {
      name: "Maintenance Costs",
      icon: overViewIcons?.maintenance,
      func: onMaintananceCosts,
      desc: "Access detailed costs for wear-and-tear items and maintenance outside of standard services. Plan for additional expenses to keep your vehicle in top condition.",
    },
    {
      name: "TCO",
      icon: overViewIcons?.tco,
      func: onTCO,
      desc: "Explore the full cost of owning your vehicle, including purchase price, fuel, maintenance, insurance, and depreciation. Get a complete overview to understand the long-term financial impact.",
    },
    {
      name: "Parts Basket",
      icon: overViewIcons?.parts,
      func: onPartsBasket,
      desc: "Access pricing for a basket of essential vehicle parts. Get a breakdown of costs to help you estimate maintenance and repair expenses with ease.",
    },
    {
      name: "10k Rates Table",
      icon: overViewIcons?.tenK,
      func: on10KRates,
      desc: "View the combined cost of service and maintenance at fixed 10,000km intervals. Get a clear estimate of expenses regardless of the vehicle’s prescribed service schedule.",
    },
    {
      name: "Residual Value",
      icon: overViewIcons?.residual,
      func: onResidualValue,
      desc: "Discover the projected depreciation and residual value of your vehicle over time. Understand how your car's value will hold up to make informed decisions for future resale or trade-in.",
    },
    {
      name: "Reviews",
      icon: overViewIcons?.reviews,
      func: undefined,
      desc: "Comming Soon",
    },
    {
      name: "Pre-Owned",
      icon: overViewIcons?.preOwned,
      func: undefined,
      desc: "Comming Soon",
    },
  ];

  const [menuLoadTextIndex, setMenuLoadTextIndex] = useState(0);
  const [menuLoadTextDisplayer, setMenuLoadTextDisplayer] =
    useState(" LOADING MENUS...");

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the menuLoadTextDisplayer with the current menu item
      setMenuLoadTextDisplayer(menuLoadText[menuLoadTextIndex]);

      // Move to the next menu item index
      setMenuLoadTextIndex(
        (prevIndex) => (prevIndex + 1) % menuLoadText.length
      );
    }, 1000);

    // Clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, [menuLoadTextIndex, menuLoadText]);

  return (
    <GlobalGrid
      currentPageTitle="Overview"
      closeCurrentPage={() => onBackPress()}
    >
      <SubPageHeading vehicle={uiState.selectedVehicle}></SubPageHeading>
      {uiState.showSideMenuDisabledItems ? (
        <div className="Scroll-container">
          {gridMenuTxt?.map((vals, ind) => {
            return (
              <IonCol
                id="cards"
                size="12"
                sizeXs="12"
                sizeMd="12"
                sizeLg="10"
                sizeXl="3.5"
                style={{
                  marginTop: "40px",
                }}
              >
                <div className="box" onClick={vals?.func}>
                  <div className="innerBox">
                    <div className="top">
                      <img src={vals?.icon} className="img" alt="Camera Scan" />
                    </div>
                    <IonText className="bottom">
                      <IonText className="cardTitle">{vals?.name}</IonText>
                      <p className="cardDesc">{vals?.desc}</p>
                    </IonText>
                    <div className="arrows">
                      <div className="cont">
                        <IonIcon src={chevronForward}></IonIcon>
                      </div>

                      <div className="cont">
                        <span id="status">
                          <span
                            id={
                              vals?.name == "Pre-Owned"
                                ? "preowned"
                                : vals?.name == "Reviews"
                                ? "preowned"
                                : ""
                            }
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </IonCol>
            );
          })}
        </div>
      ) : (
        <IonRow className="LoadingClass">
          <div className="top loadChild">
            <div className="loadingData"></div>
          </div>
          <div className="bottom loadChild">
            <p>PLEASE GIVE US A SECOND WHILE WE FETCH UPDATED ....</p>
            <span>{menuLoadTextDisplayer}</span>
          </div>
        </IonRow>
      )}
    </GlobalGrid>
  );
};

export default VehicleDetail;
