import { IonCol, IonIcon, IonRow } from "@ionic/react";
import "./style.css";
import {
  chevronBack,
  chevronBackCircleSharp,
  chevronDown,
  chevronDownOutline,
  chevronForward,
} from "ionicons/icons";
import { SelectedVehicle } from "../../interfaces";
import { getImage } from "../../api/mapo";
import moment from "moment";
import { icons, images } from "../../assets";
import { CompetitorData } from "../../interfaces/competitorData";
import CompetitorView from "../competitorsView/competitorView";
import { useMediaQuery } from "react-responsive";
import { vistorsParking } from "../../interfaces/VistorsParking";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface SubPageHeadingProps {
  onBack?: () => void;
  vehicle?: SelectedVehicle;
  visitor?: Array<vistorsParking> | undefined;
  showFleetContainer?: boolean;
}

const SubPageHeading: React.FC<SubPageHeadingProps> = ({
  onBack,
  vehicle,
  visitor,
  showFleetContainer = false,
}: SubPageHeadingProps) => {
   const uiState = useSelector((state: RootState) => state.ui);
  return (
    <>
      <IonRow id="selectedVehicleGlobal">
        {vehicle ? (
          <>
            <IonCol sizeXs="6" sizeMd="6" sizeLg="6" id="gridOverviewImageCont">
              <img
                id="gridOverviewImage"
                src={getImage(
                  vehicle.g_id.toString(),
                  vehicle.m_id.toString(),
                  vehicle.v_id.toString()
                )}
                alt=""
              />
            </IonCol>

            <IonCol sizeXs="6" sizeMd="6" sizeLg="6" id="gridOverviewDetails">
              <h1>{vehicle.vehicleData?.g_desc}</h1>
              <h1>{vehicle.vehicleData?.m_desc}</h1>
              <h1 id="gridOverviewVariant">{vehicle.vehicleData?.v_desc}</h1>

              <span id="overViewCluster">
                <h1>
                  (MY{" "}
                  {moment(
                    new Date(vehicle.vehicleData?.intro_date ?? "")
                  ).format("YYYY")}
                  )
                </h1>{" "}
                <h1>
                  G{vehicle.vehicleData?.g_id}M{vehicle.vehicleData?.m_id}V
                  {vehicle.vehicleData?.v_id}{" "}
                </h1>
              </span>
            </IonCol>
          </>
        ) : visitor ? (
          <>
            {visitor?.map((result, index) => {
              <>
                <IonCol
                  sizeXs="6"
                  sizeMd="4"
                  sizeLg="4"
                  id="gridOverviewImageCont"
                >
                  <img
                    id="gridOverviewImage"
                    src={getImage(
                      result.g_id.toString(),
                      result.m_id.toString(),
                      result.v_id.toString()
                    )}
                    alt=""
                  />
                </IonCol>

                <IonCol
                  sizeXs="6"
                  sizeMd="8"
                  sizeLg="8"
                  id="gridOverviewDetails"
                >
                  <h1> {result.g_desc}</h1>
                  <h1> {result.m_desc}</h1>
                  <h1 id="gridOverviewVariant">{result.v_desc}</h1>

                  <span id="overViewCluster">
                    <h1>
                      G{result.g_id}M{result.m_id}V{result.v_id}
                    </h1>
                  </span>
                </IonCol>
              </>;
            })}
          </>
        ) : null}
        {showFleetContainer && (
          <div className="fleet-vehicle-container">
            <table className="fleet-table">
              <thead>
                <tr>
                  <th>REG</th>
                  <th>VIN</th>
                  <th>DRIVER</th>
                  <th>ACTION DUE</th>
                </tr>
              </thead>
              <tbody>
                <tr className="fleet-row">
                  <td>{uiState.customReportParams.reg_number}</td>
                  <td>{uiState.customReportParams.vin_number}</td>
                  <td></td>
                  <td>{uiState.customReportParams.license_expiry ?new Date(uiState.customReportParams.license_expiry).toLocaleDateString() : "N/A" }</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </IonRow>
      
    </>
  );
};

export default SubPageHeading;
