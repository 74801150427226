import {
  IonAccordion,
  IonAccordionGroup,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonProgressBar,
  IonRadio,
  IonRouterContext,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  chevronForward,
  micOffOutline,
  micOffSharp,
  micOutline,
  micSharp,
  playCircle,
  refreshCircleOutline,
  search,
  stopCircleOutline,
} from "ionicons/icons";
import "./style.css";
import "../../Global.css";
import { desktopImages, icons, images, VehicleTypes } from "../../assets/index";
import {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useRef,
  SetStateAction,
} from "react";
import Button from "../../components/button";
import SearchResultView from "./search-result-view";
import { useDispatch } from "react-redux";
import UiSlice, {
  hideLoader,
  hideSideMenu,
  setFilteredSearchVisible,
  setLargeHeader,
  setScreen,
  setShareSocialVisible,
  setUndentifiedVinSearchVisible,
  showLoader,
  showSideMenu,
} from "../../redux/ui/uiSlice";
import {
  VarientsFetcher,
  getAllVehicles,
  groupsFetcher,
  manualSearch,
  modelClass,
  modelClassFetcher,
  modelsFetcher,
  parameterSearch,
} from "../../api/mapo";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AllVehicleParam } from "../../interfaces/allVehicleParam";
import { ParamSearch } from "../../interfaces/paramSearch";
import { Vehicle } from "../../interfaces/vehicle";
import moment, { max, min } from "moment";
import ReactSlider from "react-slider";
import {
  arrowBack,
  checkbox,
  chevronBack,
  chevronBackCircleSharp,
  chevronBackOutline,
  closeOutline,
  filterCircle,
  handLeft,
  iceCreamSharp,
  map,
  menuOutline,
  thumbsUp,
  volumeHighOutline,
} from "ionicons/icons";
import { parse } from "querystring";
import { count } from "console";
import {
  sessionLogout,
  setSelectedOem,
} from "../../redux/session/sessionSlice";
import { current } from "@reduxjs/toolkit";
import Heade from "../../components/heade";
import { AppScreens } from "../../enums";
import Header from "../../components/header";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { ModelClass } from "../../interfaces/modelClass";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import { brotliDecompress } from "zlib";
import { Buffer } from "buffer";
import { Config } from "../../Config";
import { useSwipeable } from "react-swipeable";

export enum FilteredSearchViewType {
  SearchResult,
  SwapOut,
}

interface FilteredSearchProps {
  isVisible: boolean;
  onClosed: () => void;
  viewType: FilteredSearchViewType;
}

interface group {
  //defining structure of group objects
  g_id: number;
  g_desc: string;
}

interface model {
  //defining structure of models objects
  m_id: number;
  m_desc: string;
}

interface varients {
  //defining structure of variants objects
  v_id: number;
  v_desc: string;
}
interface modelClasses {
  //defining structure of modelclass objects
  modelclass: string;
  description: string;
}
const FilteredSearch: React.FC<FilteredSearchProps> = ({
  isVisible,
  onClosed,
  viewType,
}: FilteredSearchProps) => {
  const sessionState = useSelector((state: RootState) => state.session);
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState<Array<AllVehicleParam>>();
  const isLoaded = useRef(false);
  const [searchResults, setSearchResults] = useState<Array<Vehicle>>();
  const [selectedGroup, setSelectedGroup] = useState(String);
  const [filter, setFilter] = useState<ParamSearch>({});
  const yearsArray = Array.from({ length: 2025 - 1990 }, (_, index) =>
    (1990 + index).toString()
  );
  yearsArray.reverse();
  const [searchLoad, setSearchLoad] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState(Number);
  const [selectedModel, setSelectedModel] = useState("");
  const modelString = selectedModel ? selectedModel.toString() : "";
  const [selectedStyle, setSelectedStyle] = useState(String);
  const [selectedModelPlaceholder, setSelectedModelPlaceholder] =
    useState("MODELS ");
  const [selectedVarPlaceholder, setSelectedVarPlaceholder] =
    useState("VARIANTS ");
  const [transPlaceholder, setTransPlaceholder] = useState("TRANSMISSION ");
  const [fuelPlaceholder, setFuellaceholder] = useState("FUEL TYPE ");
  const [yearPlaceholder, setYearPlaceholder] = useState("YEAR");
  const [doorsPlaceholder, setDoorsPlaceholder] = useState("DOORS");
  const [selectedTransType, setSelectedTransType] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [enteredVarID, setEnteredVarID] = useState("");
  const [enteredStyle, setEnteredStyle] = useState("");
  const [selectedDriveType, setSelectedDriveType] = useState("");
  const [selectedFuelType, setSelectedFuelType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDoors, setSelectedDoors] = useState(Number);
  const [selectedModelClass, setselectedModelClass] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [mikeOn, setMikeOn] = useState(false);
  const MINSLIDER = 0;
  const MAXSLIDER = 1500000;
  const MINKW = 0;
  const MAXKW = 1000;
  const MINNM = 0;
  const MAXNM = 1000;
  const MINDIS = 0;
  const MAXDIS = 5000;
  const [sliderValues, setSliderValues] = useState([MINSLIDER, MAXSLIDER]);
  const [selectedKw, setSelectedKW] = useState([MINKW, MAXKW]);
  const [selectedNm, setSelectedNm] = useState([MINNM, MAXNM]);
  const [selectedDisplacement, setSelectedDisplacement] = useState(0);
  const [fuelTypeArr, setFuelTypeArr] = useState([
    "DIESEL",
    "PETROL",
    "ELECTRIC",
    "HYBRID DIESEL",
    "HYBRID PETROL",
  ]);
  const [driveTypeArr, setTransType] = useState(["4x4", "4x2", "4x2"]);
  const [transArr, setTransArr] = useState(["MANUAL", "AUTOMATIC"]);
  const [typedGroup, setTypedGroup] = useState(String);
  const [typedModel, setTypedModel] = useState("");
  const [typedStyle, setTypedStyle] = useState("");
  const [typedVariant, setTypedVariant] = useState("");
  const [finderUpdate, setFinderUpdate] = useState(" ");
  const [specificVariantsByModel, setSpecificVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  const [specificVariantsId, setSpecificVariantsId] = useState([]);
  const userName = JSON.stringify(sessionState.user?.username);
  const gCode = Number(sessionState.selectedOem?.g_id);
  const [selectedCheckbox, setSelectedCheckbox] = useState(String);
  const [selectedModelClassCheckbox, setSelectedModelClassCheckbox] =
    useState(String);
  const [selectedVariantCheckbox, setSelectedVariantCheckbox] =
    useState(String);
  const [selectedStyleCheckbox, setSelectedStyleCheckbox] = useState(String);
  const [selectedModelCheckbox, setSelectedModelCheckbox] = useState(String);
  const [selectedTransCheckbox, setSelectedTransCheckbox] = useState(String);
  const [selectedDriveCheckbox, setSelectedDriveCheckbox] = useState(String);
  const [selectedFuelCheckbox, setSelectedFuelCheckbox] = useState(String);
  const [selectedStatusCheckbox, setSelectedStatusCheckbox] = useState(String);
  const [selectedYearCheckbox, setSelectedYearCheckbox] = useState(String);
  const [statusArr, setStatusArr] = useState(["CURRENT", "DISCONTINUED"]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [addModelsByGroup, setAddModelsByGroup] = useState<
    Array<String | Number> | []
  >();

  const [addVariantsByModel, setAddVariantsByModel] = useState<
    Array<String | Number> | []
  >();
  const [showVideos, setShowVideos] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [groupDropDown, setGroupDropDown] = useState<group[]>([]);
  const [modelsDropDown, setModelsDropDown] = useState<model[]>([]);
  const [modelClassDropDown, setModelClassDropDown] = useState<modelClasses[]>(
    []
  );

  const [addStylesAvail, setAddStylesAvail] = useState<Array<String> | []>();
  const [varientsDropDown, setVarientsDropDown] = useState<varients[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null);
  const [accordDisabled, setAccordDisabled] = useState(true);
  const [accordModelDisabled, setAccordModelDisabled] = useState(true);
  const [accordVarDisabled, setAccordVarDisabled] = useState(true);
  const [accordID, setAccordID] = useState("outlineAccord");
  const pageTilte = "FILTER SEARCH";
  const history = useHistory();
  const [parameterSelectionCount, setParameterSelectionCount] = useState(0);
  const [showSelectionCountErr, setShowSelectionCountErr] = useState(false);
  const [count, setCount] = useState(0);
  const maxProgress = 3;

  useEffect(() => {
    const countSelectedParameters = () => {
      let newCount = 0; // Use a local variable to accumulate selection count
      newCount += selectedGroup ? 1 : 0;
      newCount += selectedModel ? 1 : 0;
      newCount += selectedVariant ? 1 : 0;
      newCount += selectedStyle ? 1 : 0;
      newCount += selectedFuelType ? 1 : 0;
      newCount += selectedTransType ? 1 : 0;
      newCount += selectedDriveType ? 1 : 0;

      setCount(newCount); // Update count state using setCount
    };

    countSelectedParameters(); // Call initially to set the count

    // Dependency array includes all selection state variables
  }, [
    selectedGroup,
    selectedModel,
    selectedVariant,
    selectedStyle,
    selectedFuelType,
    selectedTransType,
    selectedDriveType,
  ]);

  useEffect(() => {
    setParameterSelectionCount(Math.min(count, maxProgress));
  }, [count]);

  const handleVideoEnd = () => {
    setShowVideo(!showVideo);
  };

  //load all vehicles in MAPO
  const loadVehicles = useCallback(() => {
    if ((sessionState.user?.username ?? "") !== "") {
      getAllVehicles(uiState?.isDesktop ?? false)
        .then((vehicleResponse) => {
          dispatch(hideLoader());
          if (vehicleResponse.status === 200) {
            setVehicles(vehicleResponse.data);
            if (viewType === FilteredSearchViewType.SearchResult) {
              setFilter({
                ...filter,
                group: sessionState.selectedOem?.g_desc.toString(),
              });
            }
          } else {
            alert(vehicleResponse.statusText);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [dispatch, sessionState, uiState, filter, viewType]);

  //calls the load vehicles if vehicles is undefined
  useEffect(() => {
    if (isVisible && vehicles === undefined && !isLoaded.current) {
      isLoaded.current = true;
      loadVehicles();
    }
  }, [vehicles, isVisible, loadVehicles]);

  //resets all filter parameters
  const onResetFilters = useCallback(() => {
    setAccordModelDisabled(true);
    setAccordVarDisabled(true);
    setAccordDisabled(true);
    SpeechRecognition.stopListening();
    setTypedGroup("");
    setTypedStyle("");
    setTypedVariant("");
    setTypedModel("");
    setSelectedStatus("");
    setSelectedCheckbox("");
    setSelectedModelClassCheckbox("");
    setSelectedModelCheckbox("");
    setselectedModelClass("");
    setSelectedVariantCheckbox("");
    setSelectedYearCheckbox("");
    setSelectedStyleCheckbox("");
    setSpecificVariantsByModel([]);
    setEnteredVarID("");
    setSelectedStyle("");
    setSelectedSeats(0);
    setSelectedNm([MINNM, MAXNM]);
    setSelectedKW([MINKW, MAXKW]);
    setSelectedDisplacement(0);
    setSliderValues([]);
    selectedKw[0] = MINKW;
    selectedKw[1] = MAXKW;
    selectedNm[0] = MINNM;
    selectedNm[1] = MAXNM;
    sliderValues[0] = MINSLIDER;
    sliderValues[1] = MAXSLIDER;
    setSelectedDriveType("");
    setSelectedModel("");
    setSelectedTransType("");
    setAddStylesAvail(undefined);
    setFilter({});
    setSelectedYear("");
    setShowVideo(false);
    setSelectedGroup("");
    setSelectedVariant("");
    setSelectedFuelType("");
    setSearchResults(undefined);
    setSliderValues([MINSLIDER, MAXSLIDER]);
    resetTranscript();
    stopListening();
    setParameterSelectionCount(0);
  }, []);

  //brings back vehicles based on filter criteria
  const onSearch = useCallback(() => {
    SpeechRecognition.stopListening();
    if (
      (filter.GMV ?? "" === "") &&
      (filter.drive ?? "" === "") &&
      (filter.fuelType ?? "" === "") &&
      (filter.group ?? "" === "") &&
      (filter.listType ?? "" === "") &&
      (filter.maxKw ?? "" === "") &&
      (filter.maxNm ?? "" === "") &&
      (filter.maxPrice ?? "" === "") &&
      (filter.minDisplacement ?? "" === "") &&
      (filter.minDoors ?? "" === "") &&
      (filter.minKw ?? "" === "") &&
      (filter.minNm ?? "" === "") &&
      (filter.minPrice ?? "" === "") &&
      (filter.minSeats ?? "" === "") &&
      (filter.model ?? "" === "") &&
      (filter.modelClass ?? "" === "") &&
      (filter.modelYear ?? "" === "") &&
      (filter.transType ?? "" === "") &&
      (filter.variant ?? "" === "") &&
      (filter.variantId ?? "" === "") &&
      (filter.style ?? "" === "") &&
      viewType === FilteredSearchViewType.SearchResult
    ) {
      dispatch(showLoader());

      parameterSearch({
        group: encodeURIComponent(selectedGroup),
        modelClass: selectedModelClass,
        model: encodeURIComponent(selectedModel),
        variant: encodeURIComponent(selectedVariant),
        style: selectedStyle,
        transType: selectedTransType,
        fuelType: selectedFuelType,
        drive: selectedDriveType,
        minPrice: undefined,
        maxPrice: undefined,
        minKw: undefined,
        maxKw: undefined,
        maxNm: undefined,
        minNm: undefined,
        modelYear: selectedYear,
        minDoors: undefined,
        minSeats: selectedSeats,
        minDisplacement: undefined,
        GMV: enteredVarID,
        listType: selectedStatus,
      })
        .then((paramSearchResponse) => {
          dispatch(hideLoader());
          if (paramSearchResponse.status === 200) {
            stopListening();
            setSearchResults(paramSearchResponse.data);
          }
        })
        .catch(() => {
          dispatch(hideLoader());
        });
    }
  }, [
    dispatch,
    filter,
    selectedGroup,
    selectedModel,
    selectedDriveType,
    selectedFuelType,
    selectedStyle,
    selectedTransType,
    selectedVariant,
    selectedYear,
    enteredVarID,
    selectedDoors,
    selectedDisplacement,
    selectedStatus,
    selectedSeats,
    selectedStyle,
    selectedModelClass,
  ]);

  //Logic to poulate dropdowns starts here
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsFetcher();
        if (response.status === 200) {
          setGroupDropDown(response.data);
        } else {
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  const addGroups: Array<string> = [];
  const getAddGroups = () => {
    groupDropDown?.map((v) => {
      if (addGroups.indexOf(v.g_desc) === -1) {
        addGroups.push(v.g_desc);
        addGroups.sort();
      }
      return "";
    });
  };
  getAddGroups();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await modelsFetcher(selectedGroupId?.toString() ?? "");
        if (response.status === 200) {
          setModelsDropDown(response.data);
        } else {
        }
      } catch (error) {}
    };
    fetchData();
  }, [selectedGroupId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await modelClassFetcher(
          selectedGroupId?.toString() ?? ""
        );
        if (response.status === 200) {
          setModelClassDropDown(response.data);
        } else {
        }
      } catch (error) {}
    };
    fetchData();
  }, [selectedGroupId, setModelClassDropDown]);

  let addModels: Array<string> = [];

  const getModelsGroup = () => {
    modelsDropDown?.map((m) => {
      if (addModels.indexOf(m.m_desc) === -1) {
        addModels.push(m.m_desc);
        addModels.sort();
      }
      return "";
    });
  };
  getModelsGroup();

  const addModelClasses: Array<string> = [];

  const getModelClassByGroup = () => {
    modelClassDropDown?.map((m) => {
      if (addModelClasses.indexOf(m.description) === -1) {
        addModelClasses.push(m.description);
        addModelClasses.sort();
      }
      return "";
    });
  };
  getModelClassByGroup();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await VarientsFetcher(
          Number(selectedGroupId),
          Number(selectedModelId)
        );
        if (response.status === 200) {
          setVarientsDropDown(response.data);
        } else {
        }
      } catch (error) {}
    };
    fetchData();
  }, [selectedGroupId, selectedModelId]);

  const addVariants: Array<string> = [];
  const getVariantsGroup = () => {
    varientsDropDown?.map((v) => {
      if (addVariants.indexOf(v.v_desc) === -1) {
        addVariants.push(v.v_desc);
        addVariants.sort();
      }
      return "";
    });
  };
  getVariantsGroup();

  const stylesAvail: Array<string> = [];
  const handleClickedOEM = (v: String) => {
    vehicles?.forEach((value) => {
      if (value.g_desc == v) {
        if (
          stylesAvail.indexOf(value.style) == -1 &&
          value.style != undefined &&
          value.style != null &&
          value.g_desc == selectedGroup
        ) {
          stylesAvail.push(value.style);
          stylesAvail.sort();
          setAddStylesAvail(stylesAvail);
        }
      }

      return stylesAvail;
    });
  };

  //For filtering with the
  const handleClickedModel = (v: string | number) => {
    vehicles?.forEach((value) => {
      if (value.m_desc == v) {
        if (stylesAvail.indexOf(value.style) == -1) {
          stylesAvail.push(value.style);
          stylesAvail.sort();
          setAddStylesAvail(stylesAvail);
        }
      }

      return stylesAvail;
    });
  };

  //   filteredStyles.sort();
  //   setAddStylesAvail(filteredStyles);

  const addModelClass: Array<string> = [];
  const getModelClass = () => {
    vehicles?.map((v) => {
      if (
        // vehicle.g_id === sessionState.selectedOem?.g_id &&
        addModelClass.indexOf(v.modelclass) === -1
      ) {
        addModelClass.push(v.modelclass);
        addModelClass.sort();
      }
      return "";
    });
  };
  getModelClass();

  //this method gets specific vaariants based on your selected model
  const specificVariants: Array<string> = [];
  const VariantsId: Array<number> = [];
  const variantYear: Array<Date> = [];
  const handleClikedModels = (checkedModel: string) => {
    vehicles?.forEach((value) => {
      if (value.m_desc == checkedModel) {
        specificVariants.push(value.v_desc);
        specificVariants.sort();
        setSpecificVariantsByModel(specificVariants);
      }

      return specificVariants && specificVariantsId && variantYear;
    });
  };

  //accordian close and open logic
  const accordGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  const toggleModelsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "modelsAccord") {
      natEl.value = undefined;
      //add here the call
    } else {
      natEl.value = "modelsAccord";
    }
  };

  const toggleModelClassAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "modelClassAccord") {
      natEl.value = undefined;
      //add here the call
    } else {
      natEl.value = "modelClassAccord";
    }
  };

  const toggleStyleAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "styleAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "styleAccord";
    }
  };

  const modelClassAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "ModelClassAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "ModelClassAccord";
    }
  };

  const toggleFuelAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "fuelAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "fuelAccord";
    }
  };

  const toggleTransAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "transAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "transAccord";
    }
  };

  const toggleYearAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "yearAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "yearAccord";
    }
  };

  const toggleSeatsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "seatsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "seatsAccord";
    }
  };

  const toggleDoorsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "doorsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "doorsAccord";
    }
  };

  const toggleDriveAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "driveAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "driveAccord";
    }
  };
  const toggleStatusAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
  };

  const toggleVariantsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "varAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "varAccord";
    }
  };

  const handleModalDismiss = () => {
    onClosed();
    onResetFilters();
  };

  const toggleGroupsAccordian = () => {
    if (!accordGroup.current) {
      return;
    }
    const natEl = accordGroup.current;

    if (natEl.value === "groupsAccord") {
      natEl.value = undefined;
    } else {
      natEl.value = "groupsAccord";
    }
  };
  //checkbox handlers
  const handleActiveCheckBox = (key: string) => {
    setSelectedCheckbox(key);
  };

  const handleActiveModelCassCheckBox = (key: string) => {
    setSelectedModelClassCheckbox(key);
  };

  const handleActiveVariantCheckBox = (key: string) => {
    setSelectedVariantCheckbox(key);
  };

  const handleActiveStyleCheckBox = (key: string) => {
    setSelectedStyleCheckbox(key);
  };

  const handleActiveModelCheckBox = (key: string) => {
    setSelectedModelCheckbox(key);
  };

  const handleActiveFuelCheckbox = (key: string) => {
    setSelectedFuelCheckbox(key);
  };
  const handleActiveYearCheckBox = (key: string) => {
    setSelectedYearCheckbox(key);
  };

  const handleActiveTransCheckBox = (key: string) => {
    setSelectedTransCheckbox(key);
  };
  const handleActiveDriveCheckBox = (key: string) => {
    setSelectedDriveCheckbox(key);
  };

  const handleActiveStatusCheckBox = (key: string) => {
    setSelectedStatusCheckbox(key);
  };

  //VOICE FEATURE

  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [listeningStatus, setListeningStatus] = useState(false);

  const startListening = () => {
    setListeningStatus(true);
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    setListeningStatus(false);

    resetTranscript();
    SpeechRecognition.stopListening();
    console.log("listening stopped successfully");
  };

  //VOICE FILTERING LOGIC
  const [prevTranscript, setPrevTranscript] = useState("");
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for single word match
        const singleWordMatchIndex = addGroups.findIndex(
          (label) => label === words[i]
        );

        if (singleWordMatchIndex !== -1) {
          setSelectedGroup(addGroups[singleWordMatchIndex]);
          setSelectedCheckbox(singleWordMatchIndex.toString());
          handleActiveCheckBox(singleWordMatchIndex.toString());
          handleClickedOEM(addGroups[singleWordMatchIndex]);
          const selectedGroup = groupDropDown.find(
            (group) => group.g_desc === addGroups[singleWordMatchIndex]
          );

          if (selectedGroup) {
            setSelectedGroupId(selectedGroup.g_id);
          }

          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatchIndex = addGroups.findIndex(
            (label) => label === twoWords
          );

          if (twoWordsMatchIndex !== -1) {
            setSelectedGroup(addGroups[twoWordsMatchIndex]);
            setSelectedCheckbox(twoWordsMatchIndex.toString());
            handleActiveCheckBox(twoWordsMatchIndex.toString());
            handleClickedOEM(addGroups[twoWordsMatchIndex]);
            const selectedGroup = groupDropDown.find(
              (group) => group.g_desc === addGroups[twoWordsMatchIndex]
            );

            if (selectedGroup) {
              setSelectedGroupId(selectedGroup.g_id);
            }

            return;
          }
        }
      }

      words.forEach((word) => {
        // Check for "manual" and avoid setting the state
        if (word === "MAN") {
          return;
        }

        const indexInGroups = addGroups.findIndex((label) => label === word);
        const indexInYears = yearsArray.findIndex((label) => label === word);
        const indexInModels = addModels.findIndex((label) => label === word);
        const indexInVariants = addVariants.findIndex(
          (label) => label === word
        );
        const indexInStyles = addStylesAvail?.findIndex(
          (label) => label === word
        );

        if (indexInGroups !== -1) {
          setSelectedGroup(addGroups[indexInGroups]);

          setSelectedCheckbox(indexInGroups.toString());
          handleActiveCheckBox(indexInGroups.toString());
          handleClickedOEM(addGroups[indexInGroups]);
          const selectedGroup = groupDropDown.find(
            (group) => group.g_desc === addGroups[indexInGroups]
          );

          if (selectedGroup) {
            setSelectedGroupId(selectedGroup.g_id);
          }
        }
      });
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addGroups,
    setSelectedCheckbox,
    handleActiveCheckBox,
    handleClickedOEM,
    setSelectedGroup,
    handleActiveVariantCheckBox,
    groupDropDown,
    stopListening,
    setFinderUpdate,
  ]);

  //The models voice recognition
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        const checkMatch = (label: string) => {
          const matchIndex = addModels.findIndex((model) => model === label);
          return matchIndex !== -1 ? addModels[matchIndex] : null;
        };

        // Check for single word match
        const singleWordMatch = checkMatch(words[i]);
        if (singleWordMatch) {
          handleMatch(singleWordMatch);
          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatch = checkMatch(twoWords);
          if (twoWordsMatch) {
            handleMatch(twoWordsMatch);
            return;
          }
        }
      }
    };

    const handleMatch = (matchedModel: SetStateAction<string>) => {
      setSelectedModel(matchedModel);
      handleActiveModelCheckBox(matchedModel.toString());

      const modelString = modelsDropDown.find(
        (model) => model.m_desc === matchedModel
      );
      if (modelString) {
        setSelectedModelId(modelString.m_id);
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addModels,
    setSelectedModelId,
    handleActiveModelCheckBox,
    setSelectedModel,
    modelsDropDown,
    stopListening,
  ]);

  //styles voice recognition
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for single word match
        const singleWordMatchIndex = stylesAvail?.findIndex(
          (label) => label === words[i]
        );
        if (singleWordMatchIndex !== -1) {
          setSelectedStyle(stylesAvail[singleWordMatchIndex]);
          handleActiveStyleCheckBox(stylesAvail[singleWordMatchIndex]);
          const modelString = vehicles?.find(
            (model) => model.style === stylesAvail[singleWordMatchIndex]
          );

          return;
        }

        // Check for two words match
        if (i < words.length - 1) {
          const twoWords = words[i] + " " + words[i + 1];
          const twoWordsMatchIndex = stylesAvail.findIndex(
            (label) => label === twoWords
          );
          if (twoWordsMatchIndex !== -1) {
            setSelectedStyle(stylesAvail[singleWordMatchIndex]);
            handleActiveStyleCheckBox(stylesAvail[singleWordMatchIndex]);
            const modelString = vehicles?.find(
              (model) => model.style === stylesAvail[singleWordMatchIndex]
            );

            return;
          }
        }
      }
    };
    // Check if there's a new transcript and its different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    stylesAvail,
    setSelectedStyle,
    handleActiveStyleCheckBox,
    setSelectedStyle,
    stopListening,
  ]);

  useEffect(() => {
    const handleSpeechRecognition = () => {
      const transcriptUpperCase = transcript.toUpperCase();

      // Check for phrases indicating variant selection
      const variantPhrases = ["SELECT VARIANT", "VARIANT IS", "CHOOSE VARIANT"];
      const hasVariantPhrase = variantPhrases.some((phrase) =>
        transcriptUpperCase.includes(phrase)
      );

      if (hasVariantPhrase) {
        // Extract the variant part of the transcript
        const variantIndex = variantPhrases.findIndex((phrase) =>
          transcriptUpperCase.includes(phrase)
        );
        const variantStartIndex =
          transcriptUpperCase.indexOf(variantPhrases[variantIndex]) +
          variantPhrases[variantIndex].length;
        const selectedVariant = transcript.slice(variantStartIndex).trim();

        // Find the corresponding variant in the list
        const variantMatchIndex = addVariants?.findIndex((label) =>
          label.includes(selectedVariant)
        );

        if (variantMatchIndex !== -1) {
          // Introduce a delay before making the final decision
          setTimeout(() => {
            setSelectedVariant(addVariants[variantMatchIndex]);
            handleActiveVariantCheckBox(addVariants[variantMatchIndex]);
            const modelString = vehicles?.find(
              (model) => model.v_desc === addVariants[variantMatchIndex]
            );
          }, 3000); // 3000 milliseconds (3 seconds) delay
        }
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    addVariants,
    setSelectedVariant,
    handleActiveVariantCheckBox,
    toggleVariantsAccordian,
    stopListening,
  ]);

  //status speech search drive type
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for drive type matches
        const driveTypeMatch = getDriveTypeMatch(words, i);

        if (driveTypeMatch) {
          setSelectedDriveType(driveTypeMatch);
          handleActiveDriveCheckBox(driveTypeMatch);
          return;
        }
      }
    };

    const getDriveTypeMatch = (words: any[], index: number) => {
      const currentWord = words[index];
      // Check for specific drive types
      switch (currentWord) {
        case "FOUR":
          return "4x4";
        case "FRONT":
          return "4x2";
        case "REAR":
          return "4x2 ";
        case "ALL":
          return "4x4";
        default:
          // Check for multi-word matches
          const currentPhrase = words.slice(index, index + 2).join(" ");
          const multiWordMatch = driveTypeArr.find(
            (label) => label === currentPhrase
          );
          return multiWordMatch || null;
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    driveTypeArr,
    setSelectedDriveType,
    handleActiveDriveCheckBox,
    stopListening,
  ]);

  //speech rec fuel
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for "diesel" match
        if (words[i] === "DIESEL") {
          setSelectedFuelType(words[i][0]);
          handleActiveFuelCheckbox(words[i][0]);
          return;
        }

        // Check for "petrol" match
        if (words[i] === "PETROL") {
          setSelectedFuelType(words[i][0]);
          handleActiveFuelCheckbox(words[i][0]);
          return;
        }
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    setSelectedFuelType,
    handleActiveFuelCheckbox,
    stopListening,
  ]);

  //speech rec fuel
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for "diesel" match
        if (words[i] === "AUTOMATIC") {
          setSelectedTransType(words[i][0]);
          handleActiveTransCheckBox(words[i][0]);
          return;
        }

        // Check for "petrol" match
        if (words[i] === "MANUAL") {
          setSelectedTransType(words[i][0]);
          handleActiveTransCheckBox(words[i][0]);
          return;
        }
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    setSelectedTransType,
    handleActiveTransCheckBox,
    stopListening,
  ]);

  /* useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");
      for (let i = 0; i < words.length; i++) {
        // Check for "diesel" match
        if (words[i] === "RESET") {
          onResetFilters();
          return;
        }
        if (words[i] === "STOP") {
          SpeechRecognition.stopListening();
          return;
        }
        // Check for "petrol" match
        if (words[i] === "SEARCH") {
          onSearch();
          return;
        }
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [transcript, SpeechRecognition]);*/

  const processName = (name: any) => {
    switch (true) {
      case name.includes("CARS - PICKUP & TRUCKS DC"):
        return "PICKUP DOUBLE AB";
      case name.includes("CARS - PICKUP & TRUCKS SC"):
        return "PICKUP SINGLE CAB";

      case name.includes("COMM - UTILITY VAN SMALL-SIZE"):
        return "UTILITY VAN SMALL-SIZE";
      case name.includes("CARS - COUPE"):
        return "COUPE";
      case name == "CARS - SPORT UTILITY VEHICLE":
        return "SUV";
      case name.includes("CARS - FAMILY SEDAN"):
        return "FAMILY SEDAN";
      case name.includes("COMM - LIGHT PASSENGER"):
        return "LIGHT PASSENGER";
      case name.includes("CARS - MULTY PURPOSE VEHICLE"):
        return "MULTY PURPOSE VEHICLE";
      case name.includes("CARS - HATCHBACK"):
        return "HATCHBACK";
      case name == "CARS - SPORT WAGEN":
        return "SPORT WAGEN";

      case name.includes("CARS - COUPE-CABRIOLET"):
        return "CABRIOLET";
      case name.includes("CARS - CROSSOVER"):
        return "CROSSOVER";
      case name.includes("CARS - SMALL ECONOMY HATCH"):
        return "SMALL ECONOMY HATCH";
      case name.includes("CARS - SMALL ECONOMY SEDAN"):
        return "SMALL ECONOMY SEDAN";
      case name.includes("CARS - LUXURY"):
        return "LUXURY";
      case name.includes("CARS - PERFORMANCE HATCH"):
        return "PERFORMANCE HATCH";
      case name.includes("CARS - PICKUP & TRUCKS EC"):
        return "PICKUP EXTENDED CAB";
      case name == "CARS - SPORT":
        return "SPORT";
      // case name.includes("CARS-SPORT CONVERTIBLE"):
      //  return "SPORT CONVERTIBLE";
      case name.includes("BIKES - FOURWHEELERS & ATV"):
        return "FOURWHEELERS & ATV";
      case name.includes("BIKES - MOTORCROSS COMPETITION"):
        return "MOTORCROSS COMPETITION";
      case name.includes("BIKES - OFF ROAD & AGRI"):
        return "OFF ROAD & AGRICULTURAL";
      case name.includes("BIKES - SCOOTERS & COMMUTER"):
        return "SCOOTERS & COMMUTER";
      case name.includes("BIKES - SUPER BIKES"):
        return "SUPER BIKES";
      case name.includes("BIKES - TOURING & ADVENTURE"):
        return "TOURING & ADVENTURE";
      case name.includes("MARINE & WATER - FREIGHT CARRIERS"):
        return "FREIGHT CARRIERS";
      case name.includes("FARM/AGRI - TRACTOR"):
        return "TRACTOR";

      default:
        return name;
    }
  };

  //start of speech recognition functionality
  useEffect(() => {
    groupDropDown?.forEach((value, ind) => {
      if (value.g_desc === typedGroup) {
        setSelectedGroup(value.g_desc);
        setSelectedGroupId(value.g_id);
        setSelectedCheckbox(ind.toString());
        handleActiveCheckBox(ind.toString());
      }
    });

    addGroups?.forEach((v, i) => {
      if (v === typedGroup) {
        setSelectedCheckbox(i.toString());
        handleActiveCheckBox(i.toString());
        handleClickedOEM(v);
      }
    });
  }, [addGroups, typedGroup, handleClickedOEM, groupDropDown]);

  useEffect(() => {
    modelsDropDown?.forEach((value, ind) => {
      if (value.m_desc === typedModel) {
        setSelectedModelId(value.m_id);
        setSelectedModel(value.m_desc);
        setSelectedModelCheckbox(ind.toString());
        handleActiveModelCheckBox(ind.toString());
      }
    });

    addModels?.forEach((v, i) => {
      if (v === typedGroup) {
        handleClickedModel(v);
      }
    });
  }, [
    addModels,
    typedModel,
    handleActiveModelCheckBox,
    setSelectedModelCheckbox,
    setSelectedModelId,
    setSelectedModel,
    modelsDropDown,
  ]);

  useEffect(() => {
    vehicles?.forEach((value, ind) => {
      if (value.style === typedStyle) {
        setSelectedStyle(value.style);
        setSelectedModelCheckbox(ind.toString());
        handleActiveStyleCheckBox(ind.toString());
      }
    });
  }, [
    addStylesAvail,
    vehicles,
    typedStyle,
    setSelectedStyle,
    handleActiveStyleCheckBox,
  ]);

  //status speech search drive type
  useEffect(() => {
    const handleSpeechRecognition = () => {
      const words = transcript.toUpperCase().split(" ");

      for (let i = 0; i < words.length; i++) {
        // Check for drive type matches
        const driveTypeMatch = getDriveTypeMatch(words, i);

        if (driveTypeMatch) {
          setSelectedDriveType(driveTypeMatch);
          handleActiveDriveCheckBox(driveTypeMatch);
          return;
        }
      }
    };

    const getDriveTypeMatch = (words: any[], index: number) => {
      const currentWord = words[index];

      // Check for specific drive types
      switch (currentWord) {
        case "FRONT":
          return "4x2";
        case "REAR":
          return "4x4";
        case "ALL":
          return "4x4";
        default:
          // Check for multi-word matches
          const currentPhrase = words.slice(index, index + 2).join(" ");
          const multiWordMatch = driveTypeArr.find(
            (label) => label === currentPhrase
          );
          return multiWordMatch || null;
      }
    };

    // Check if there's a new transcript and it's different from the previous one
    if (transcript.trim() !== "" && transcript !== prevTranscript) {
      handleSpeechRecognition();
      setPrevTranscript(transcript);
    }
  }, [
    transcript,
    driveTypeArr,
    setSelectedDriveType,
    handleActiveDriveCheckBox,
    stopListening,
  ]);

  //method to determine which accordians should be enables based on selections
  useEffect(() => {
    const handleModelAccordVisibility = () => {
      if (selectedGroup) {
        setAccordModelDisabled(false);
      }
    };

    handleModelAccordVisibility();

    const handleVarAccordVisibility = () => {
      if (selectedGroup && selectedModel) {
        setAccordVarDisabled(false);
      }
    };

    handleVarAccordVisibility();

    const handleAllAccordVisibility = () => {
      if (selectedGroup && selectedModel && selectedVariant) {
        setAccordDisabled(false);
      }

      if (
        selectedGroup &&
        selectedFuelType &&
        selectedDriveType &&
        selectedTransType
      ) {
        setAccordDisabled(false);
        setAccordVarDisabled(false);
      }
    };

    handleAllAccordVisibility();
  }, [
    accordVarDisabled,
    accordDisabled,
    accordModelDisabled,
    selectedGroup,
    selectedModel,
    selectedVariant,
    selectedFuelType,
    selectedTransType,
    selectedDriveType,
  ]);

  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);

  console.log(selectedFuelType);

  const placeholders = ["MAKE", "MODEL", "VARIANT"];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 4000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [placeholders.length]);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleImageClick = (index: any) => {
    setActiveIndex(index);
  };
  const vehicleImages = Object.values(VehicleTypes);
  const [currentSlide, setCurrentSlide] = useState(0);
  const imagesPerSlide = 4;
  const totalSlides = Math.ceil(vehicleImages.length / imagesPerSlide);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const previousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => previousSlide(),
    trackMouse: true,
  });

  const startIndex = currentSlide * imagesPerSlide;
  const endIndex = startIndex + imagesPerSlide;
  const translateX = -startIndex * (5 / imagesPerSlide);

  return (
    <IonModal onDidDismiss={handleModalDismiss} isOpen={isVisible}>
      <GlobalGrid
        currentPageTitle={searchResults ? "SEARCH RESULTS" : "FILTERED SEARCH"}
        activateVoice={() => {
          startListening();
        }}
        closeCurrentPage={() => {
          searchResults
            ? onResetFilters()
            : dispatch(setFilteredSearchVisible(false));
          resetTranscript();
          stopListening();
        }}
        closeVideo={() => {
          handleVideoEnd();
        }}
        startListeningFilterSearch={() => {
          SpeechRecognition.startListening({ continuous: true });
        }}
      >
        {searchResults ? (
          <SearchResultView
            viewType={viewType}
            searchResults={searchResults}
          ></SearchResultView>
        ) : (
          <>
            <IonRow className="gridFilterVoiceRow">
              <IonCol
                id="cards"
                size="12"
                sizeXs="12"
                sizeMd="12"
                sizeLg="10"
                sizeXl="12"
              >
                <div className="vehicle-container" {...handlers}>
                  <div className="vehicle-type">
                    <IonIcon
                      src={chevronBack}
                      className="carousel-btn prev"
                      onClick={previousSlide}
                    />
                    <div className="top">
                      <div className="image-row">
                        {vehicleImages
                          .slice(startIndex, endIndex)
                          .map((image, index) => {
                            const globalIndex = startIndex + index;
                            return (
                              <img
                                key={index}
                                src={image}
                                className={`image-r ${
                                  globalIndex === activeIndex ? "active" : ""
                                }`}
                                alt="Vehicle"
                                onClick={() => handleImageClick(globalIndex)}
                              />
                            );
                          })}
                      </div>
                    </div>

                    <IonIcon
                      src={chevronForward}
                      className="carousel-btn next"
                      onClick={previousSlide}
                    />
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow id="filterSearchContent">
              {count < 3 ? (
                <IonCol
                  size="12"
                  sizeXs="12"
                  sizeMd="12"
                  sizeLg="12"
                  sizeXl="12"
                  className="minSelectionCounter"
                >
                  <p>Please Select a minimum of 3 options before proceeding:</p>
                  Counter {count} /3
                </IonCol>
              ) : (
                <></>
              )}
              {/* <div className="filter-search-bar-container">
                <input
                  type="text"
                  className="search-bar"
                  placeholder={`QUICK SEARCH ${placeholders[index]}`}
                ></input>
              </div> */}
              <IonCol
                size="12"
                sizeXs="12"
                sizeMd="12"
                sizeLg="6"
                sizeXl="5"
                id="filterSearchContentRow"
              >
                <IonAccordionGroup
                  id="gridAccordGroup"
                  ref={accordGroup}
                  onClick={() => SpeechRecognition.stopListening()}
                >
                  <IonAccordion
                    id="gridAccord"
                    value="groupsAccord"
                    onClick={() => SpeechRecognition.stopListening()}
                  >
                    <IonItem slot="header" id="gridAccordTitle">
                      {selectedGroup && (
                        <IonIcon
                          src={checkbox}
                          id="selectionConfirmationBox"
                        ></IonIcon>
                      )}
                      GROUP
                      <span className="selectionDisplay">
                        {"  "}
                        {selectedGroup}
                      </span>
                    </IonItem>
                    <IonList slot="content" id="GroupAccordianList">
                      <div className="tile-container">
                        {addGroups.map((e, index) => {
                          const matchedGroup = groupDropDown.find(
                            (group) => group.g_desc === e
                          );

                          return (
                            matchedGroup && (
                              <>
                                <div
                                  key={index}
                                  className={`tile ${
                                    selectedCheckbox === index.toString()
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    setSelectedCheckbox(index.toString());
                                    setSelectedGroup(matchedGroup.g_desc);
                                    setSelectedGroupId(matchedGroup.g_id); // Update group ID
                                    handleActiveCheckBox(index.toString());
                                    handleClickedOEM(matchedGroup.g_desc);
                                    toggleGroupsAccordian();
                                  }}
                                >
                                  <img
                                    className="oemLogo"
                                    src={`${
                                      Config.API_SERVER
                                    }/api/image/getlogo?gCode=${Number(
                                      matchedGroup.g_id
                                    )}`}
                                    alt={matchedGroup.g_desc}
                                  />
                                  <h2>{matchedGroup.g_desc}</h2>
                                </div>
                              </>
                            )
                          );
                        })}
                      </div>
                    </IonList>
                  </IonAccordion>

                  <IonAccordion
                    id="gridAccord"
                    value="modelsAccord"
                    disabled={accordModelDisabled}
                    onClick={() => SpeechRecognition.stopListening()}
                  >
                    <IonItem slot="header" id="gridAccordTitle">
                      {selectedModel && (
                        <IonIcon
                          src={checkbox}
                          id="selectionConfirmationBox"
                        ></IonIcon>
                      )}
                      MODEL
                      <span className="selectionDisplay">
                        {"  "}
                        {selectedModel}
                      </span>
                    </IonItem>
                    <IonList slot="content" id="gridAccordItemsContainer">
                      {addModels.map((e, index) => (
                        <IonItem
                          title="variant"
                          key={index}
                          id="gridAccordItems"
                        >
                          <IonLabel
                            id="gridAccordItemsLabel"
                            onClick={(event) => {
                              toggleModelsAccordian();

                              // Prevent the click event from firing twice when clicking directly on checkbox
                              if (
                                (event.target as HTMLElement).tagName !==
                                "INPUT"
                              ) {
                                const modelString = modelsDropDown.find(
                                  (model) => model.m_desc === e
                                );
                                if (modelString) {
                                  setSelectedModel(e.toString());
                                  setSelectedModelId(modelString.m_id);
                                  handleActiveModelCheckBox(index.toString());
                                  handleClickedModel(e.toString());
                                }
                              }
                            }}
                          >
                            <input
                              className="radio"
                              id={index.toString()}
                              checked={
                                selectedModelCheckbox === index.toString()
                              }
                              type="checkbox"
                              value={e != null ? e.toString() : ""}
                              onChange={(event) => {
                                // Keep this for actual checkbox state control
                                const modelString = modelsDropDown.find(
                                  (model) => model.m_desc === e
                                );
                                if (modelString) {
                                  setSelectedModel(event.target.value);
                                  setSelectedModelId(modelString.m_id);
                                  handleActiveModelCheckBox(index.toString());
                                  handleClickedModel(e.toString());
                                }
                              }}
                            />
                            {e}
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonAccordion>

                  <IonAccordion
                    id="gridAccord"
                    value="varAccord"
                    onClick={() => SpeechRecognition.stopListening()}
                    disabled={accordVarDisabled}
                  >
                    <IonItem slot="header" id="gridAccordTitle">
                      {selectedVariant && (
                        <IonIcon
                          src={checkbox}
                          id="selectionConfirmationBox"
                        ></IonIcon>
                      )}
                      VARIANT
                      <span className="selectionDisplay">
                        {"  "}
                        {selectedVariant}
                      </span>
                    </IonItem>
                    <IonList slot="content" id="gridAccordItemsContainer">
                      {addVariants
                        .filter((variant) => variant.includes(selectedStyle))
                        .map((e, index) => {
                          return (
                            <IonItem
                              title="variant"
                              key={index}
                              id="gridAccordItems"
                            >
                              <IonLabel
                                id="gridAccordItemsLabel"
                                onClick={(event) => {
                                  toggleVariantsAccordian();

                                  // Prevent the click event from firing twice when clicking directly on checkbox
                                  if (
                                    (event.target as HTMLElement).tagName !==
                                    "INPUT"
                                  ) {
                                    setSelectedVariant(e.toString());
                                    handleActiveVariantCheckBox(
                                      index.toString()
                                    );
                                  }
                                }}
                              >
                                <input
                                  className="radio"
                                  id={index.toString()}
                                  checked={
                                    selectedVariantCheckbox === index.toString()
                                  }
                                  type="checkbox"
                                  value={e.toString()}
                                  onChange={(event) => {
                                    setSelectedVariant(event.target.value);
                                    handleActiveVariantCheckBox(
                                      index.toString()
                                    );
                                  }}
                                />
                                {e}
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                    </IonList>
                  </IonAccordion>

                  <IonAccordion
                    id="gridAccord"
                    value="styleAccord"
                    onClick={() => SpeechRecognition.stopListening()}
                    disabled={accordDisabled}
                  >
                    <IonItem slot="header" id="gridAccordTitle">
                      {selectedStyle && (
                        <IonIcon
                          src={checkbox}
                          id="selectionConfirmationBox"
                        ></IonIcon>
                      )}
                      STYLE
                      <span className="selectionDisplay">
                        {"  "}
                        {selectedStyle}
                      </span>
                    </IonItem>
                    <IonList slot="content" id="gridAccordItemsContainer">
                      {addStylesAvail?.map((e, index) => (
                        <IonItem
                          title="variant"
                          key={index}
                          id="gridAccordItems"
                        >
                          <IonLabel id="gridAccordItemsLabel">
                            <input
                              className="radio"
                              value={JSON.stringify(e).replace(/"/g, "")}
                              id={index.toString()}
                              checked={
                                selectedStyleCheckbox === index.toString()
                              }
                              type="checkbox"
                              onClick={() => toggleStyleAccordian()}
                              onChange={(e) => {
                                handleActiveStyleCheckBox(index.toString());
                                setSelectedStyle(e.target.value);
                              }}
                            />
                            {e === undefined ? "No Styles Available" : e}
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonAccordion>

                  <IonAccordion
                    id="gridAccord"
                    value="fuelAccord"
                    onClick={() => SpeechRecognition.stopListening()}
                  >
                    <IonItem slot="header" id="gridAccordTitle">
                      {selectedFuelType && (
                        <IonIcon
                          src={checkbox}
                          id="selectionConfirmationBox"
                        ></IonIcon>
                      )}
                      FUEL TYPE
                      <span className="selectionDisplay">
                        {"  "}
                        {selectedFuelType}
                      </span>
                    </IonItem>
                    <IonList slot="content" id="gridAccordItemsContainer">
                      {fuelTypeArr?.map((e, index) => (
                        <IonItem
                          title="variant"
                          key={index}
                          id="gridAccordItems"
                        >
                          <IonLabel id="gridAccordItemsLabel">
                            <input
                              className="radio"
                              id={index.toString()}
                              checked={selectedFuelType ? true : false}
                              type="checkbox"
                              value={
                                e === "HYBRID DIESEL"
                                  ? "HD"
                                  : e === "HYBRID PETROL"
                                  ? "HP"
                                  : e.charAt(0).toString()
                              }
                              onClick={() => toggleFuelAccordian()}
                              onChange={(event) => {
                                setSelectedFuelType(event.target.value);
                                handleActiveFuelCheckbox(index.toString());
                              }}
                            />
                            {e}
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonAccordion>

                  <IonAccordion
                    id="gridAccord"
                    value="transAccord"
                    onClick={() => SpeechRecognition.stopListening()}
                  >
                    <IonItem slot="header" id="gridAccordTitle">
                      {selectedTransType && (
                        <IonIcon
                          src={checkbox}
                          id="selectionConfirmationBox"
                        ></IonIcon>
                      )}
                      TRANSMISSION
                      <span className="selectionDisplay">
                        {"  "}
                        {selectedTransType}
                      </span>
                    </IonItem>
                    <IonList slot="content" id="gridAccordItemsContainer">
                      <IonItem title="variant" id="gridAccordItems">
                        <IonLabel id="gridAccordItemsLabel">
                          <input
                            className="radio"
                            type="checkbox"
                            checked={selectedTransType === "Automatic"}
                            onClick={toggleTransAccordian}
                            onChange={(event) => {
                              setSelectedTransType(event.target.value);
                            }}
                            value={"Automatic"}
                          />
                          AUTOMATIC
                        </IonLabel>
                      </IonItem>
                      <IonItem title="variant" id="gridAccordItems">
                        <IonLabel id="gridAccordItemsLabel">
                          <input
                            className="radio"
                            type="checkbox"
                            checked={selectedTransType === "Manual"}
                            onClick={toggleTransAccordian}
                            onChange={(event) => {
                              setSelectedTransType(event.target.value);
                            }}
                            value={"Manual"}
                          />
                          MANUAL
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonAccordion>

                  <IonAccordion
                    id="gridAccord"
                    value="driveAccord"
                    onClick={() => SpeechRecognition.stopListening()}
                  >
                    <IonItem slot="header" id="gridAccordTitle">
                      {selectedDriveType && (
                        <IonIcon
                          src={checkbox}
                          id="selectionConfirmationBox"
                        ></IonIcon>
                      )}
                      DRIVE TYPE
                      <span className="selectionDisplay">
                        {"  "}
                        {selectedDriveType}
                      </span>
                    </IonItem>
                    <IonList slot="content" id="gridAccordItemsContainer">
                      {driveTypeArr?.map((e, index) => (
                        <IonItem
                          title="variant"
                          key={index}
                          id="gridAccordItems"
                        >
                          <IonLabel id="gridAccordItemsLabel">
                            <input
                              className="radio"
                              id={index.toString()}
                              checked={selectedDriveType ? true : false}
                              type="checkbox"
                              value={e}
                              onClick={() => toggleDriveAccordian()}
                              onChange={(event) => {
                                setSelectedDriveType(event.target.value);
                                handleActiveDriveCheckBox(index.toString());
                              }}
                            />
                            {index === 0
                              ? "ALL"
                              : index === 1
                              ? "FRONT"
                              : "REAR"}
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonAccordion>
                </IonAccordionGroup>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="12"
                sizeXs="12"
                sizeMd="12"
                sizeLg="12"
                sizeXl="12"
                className="gridFilterBtnCont"
              >
                <button
                  className="gridFilterBtns"
                  onClick={onResetFilters}
                  style={{ background: "#b61010" }}
                >
                  RESET FILTER
                </button>

                {parameterSelectionCount < 3 ? (
                  <></>
                ) : (
                  <button
                    style={{ background: "green" }}
                    className="gridFilterBtns "
                    onClick={() => {
                      onSearch();
                      setShowVideo(false);
                    }}
                  >
                    SEARCH
                  </button>
                )}
              </IonCol>
            </IonRow>
          </>
        )}
      </GlobalGrid>
    </IonModal>
  );
};
export default FilteredSearch;
